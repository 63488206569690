import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  List,
  ListItem,
  Link,
  IconButton, Tooltip
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CloseIcon from "@mui/icons-material/Close";
import PropertyDetailsDialog from "./PropertyDetailsDialog";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import DescriptionIcon from "@mui/icons-material/Description"; // Default document icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Show All Icon
import InventoryIcon from "@mui/icons-material/Inventory";
import InventoryPage from "./InventoryPage"
import {
  AiFillFileImage,
  AiFillFileExcel,
  AiFillFilePdf,
  AiFillFile,
} from "react-icons/ai";
const MAX_VISIBLE_DOCS = 10;


const ProjectDetails = ({ projectId, onClose }) => {
  const [project, setProject] = useState(null);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loadingProject, setLoadingProject] = useState(true);
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("success");
  const [selectedInventory, setSelectedInventory] = useState(null);
  

  // Function to close dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  // Ensure project exists and has documents

  useEffect(() => {
    fetchProjectDetails();
    fetchProperties();
  }, [projectId]);
  // Ensure project exists and has documents
  const documents = project?.documents?.length ? project.documents : [];

  const fetchProjectDetails = async () => {
    try {
      const response = await fetch(
        `https://test.datso.io/projects_m/${projectId}`
      );
      if (!response.ok) throw new Error("Failed to fetch project details.");
      const data = await response.json();
      setProject(data.project || {});
      setEditedProject(data.project || {});

      // Extract documents separately
      setProject((prev) => ({
        ...prev,
        documents:
          data.documents?.map((doc) => doc.url || "").filter(Boolean) || [], // Remove invalid values
      }));
    } catch (error) {
      console.error("Error fetching project details:", error);
    } finally {
      setLoadingProject(false);
    }
  };

  const getFileIcon = (fileUrl) => {
  const extension = fileUrl.split(".").pop().toLowerCase();
  const iconSize = 22; // 👈 Small icon size

  if (["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension))
    return <AiFillFileImage size={iconSize} color="#2196F3" />;
  if (["xls", "xlsx", "csv"].includes(extension))
    return <AiFillFileExcel size={iconSize} color="#4CAF50" />;
  if (["pdf"].includes(extension))
    return <AiFillFilePdf size={iconSize} color="#F44336" />;
  return <AiFillFile size={iconSize} color="#757575" />;
};

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const getFilenameFromUrl = (url) => {
    if (!url || typeof url !== "string") return "Unknown Document";
    return url.split("/").pop(); // Extracts the last segment after the last "/"
  };

  // Function to determine file type
  const getFileType = (url) => {
    if (!url) return "unknown";
    const extension = url.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "bmp"].includes(extension))
      return "image";
    if (extension === "pdf") return "pdf";
    if (["xls", "xlsx", "csv"].includes(extension)) return "excel";
    if (["doc", "docx"].includes(extension)) return "word";
    return "other"; // For unknown file types
  };

  // Function to get the appropriate file icon
  // const getFileIcon = (fileType) => {
  //   switch (fileType) {
  //     case "pdf":
  //       return <PictureAsPdfIcon sx={{ color: "red", marginRight: 1 }} />;
  //     case "image":
  //       return <ImageIcon sx={{ color: "green", marginRight: 1 }} />;
  //     case "excel":
  //       return <InsertDriveFileIcon sx={{ color: "green", marginRight: 1 }} />;
  //     case "word":
  //       return <InsertDriveFileIcon sx={{ color: "blue", marginRight: 1 }} />;
  //     default:
  //       return <DescriptionIcon sx={{ color: "gray", marginRight: 1 }} />;
  //   }
  // };

  const DocumentList = ({ documents }) => (
    <Box marginBottom={3}>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
        Documents:
      </Typography>
      {documents.length === 0 ? (
  <Typography sx={{ fontStyle: "italic", color: "gray" }}>
    No documents available.
  </Typography>
) : (
  <>
    <Grid container spacing={2}>
      {documents.slice(0, 10).map((doc, index) => (
        <Grid item xs={2.4} key={index}>
          <Box
            onClick={() => window.open(doc, "_blank")}
            sx={{
              cursor: "pointer",
              textAlign: "center",
              p: 1,
              borderRadius: 2,
              backgroundColor: "#f5f5f5",
              transition: "0.3s",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                transform: "scale(1.03)",
              },
            }}
          >
            {getFileIcon(doc)}
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                display: "block",
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getFilenameFromUrl(doc).slice(0, 8)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>

    {documents.length > 10 && (
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Show All Documents
        </Button>
      </Box>
    )}
  </>
)}
    </Box>
  );

  const renderDynamicContent = () => {
    if (selectedProperty) {
      return (
        <Box sx={{ padding: 3, border: "1px solid #ccc", borderRadius: "10px", backgroundColor: "#f9f9f9" }}>
          <Button 
            onClick={() => setSelectedProperty(null)}
            variant="contained"
            color="secondary"
            sx={{ marginBottom: 2 }}
          >
            Back to Properties
          </Button>
  
          {/* Render Property Details Directly Instead of a Dialog */}
          <PropertyDetailsDialog property={selectedProperty} />
        </Box>
      );
    }
  
    return null;
  };
  
  const fetchProperties = async () => {
    try {
      const response = await fetch(
        `https://test.datso.io/projects_m/${projectId}/properties`
      );
      if (!response.ok) throw new Error("Failed to fetch properties.");
      const data = await response.json();
      setProperties(data.properties || []);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const handleEditSave = async () => {
    try {
      const response = await fetch(
        `https://test.datso.io/projects_m/${projectId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editedProject),
        }
      );

      if (!response.ok) throw new Error("Failed to update project.");
      setProject(await response.json());
      setEditDialogOpen(false);
      handleOpenDialog("Project updated successfully!", "success");
    } catch (error) {
      console.error("Error updating project:", error);
      handleOpenDialog("Failed to update project. Please try again.", "error");
    }
  };

  const handleOpenDialog = (message, type) => {
    setDialogMessage(message);
    setDialogType(type);
    setDialogOpen(true);
  };
  const handlePropertyView = (property) => {
    setSelectedProperty(property); // Updates the selected property dynamically
  };
  
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
      handleOpenDialog("Only Excel files (.xls, .xlsx) are allowed.", "error");
      return;
    }
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("projectId", projectId);
      const response = await fetch("https://test.datso.io/properties/upload", {
        method: "POST",
        body: formData,
      });
      
      // Check for errors and parse detailed message from the API response
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.detail || "Failed to upload properties. Please try again.";
        throw new Error(errorMessage);
      }
      
      handleOpenDialog("Properties uploaded successfully!", "success");
      fetchProperties();
    } catch (error) {
      handleOpenDialog(error.message, "error");
    } finally {
      setUploading(false);
    }
  };
  

  if (loadingProject) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

    // Conditionally render the InventoryPage if a property was selected for inventory
    if (selectedInventory) {
      return (
        <InventoryPage
          propertyId={selectedInventory.propertyid}
          onClose={() => setSelectedInventory(null)}
        />
      );
    }
  

  return (
    <Box padding={4} 
    sx={{
      padding: "16px",
      marginLeft: "16px",
      backgroundColor:"white",
      marginBottom: 3,
      borderRadius: "10px",
      height:"auto",
      mt : -2



    }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
      >
        <Typography variant="h4" fontWeight="500" color="Black">
          {project.project_name} Details
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button startIcon={<RefreshIcon />} onClick={fetchProjectDetails} />
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            color="primary"
            onClick={() => setEditDialogOpen(true)}
            sx={{ marginRight: "8px",backgroundColor: "#2a3663",
              color: "white", }} // Adds space between Edit and Close
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            onClick={onClose}
            sx={{ borderColor: "red", color: "red" }}
          >
            Close
          </Button>
        </Box>
      </Box>
      <Paper
        elevation={3}
        sx={{
          padding: "16px",
          borderRadius: "10px",
          backgroundColor: "#f7f9fc",
          marginBottom: "16px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Project ID:</Typography>
            <Typography variant="body2">{project.project_id}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Type:</Typography>
            <Typography variant="body2">{project.project_type}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Location:</Typography>
            <Typography variant="body2">
              {project.project_location_city}, {project.project_location_state}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>GPS:</Typography>
            <Typography variant="body2">
              {project.project_location_gps || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Start Date:</Typography>
            <Typography variant="body2">{project.start_date}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>
              Completion Date:
            </Typography>
            <Typography variant="body2">
              {project.expected_completion_date || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
            <Chip
              label={(project.project_status).toUpperCase()}
              sx={{
                marginTop: "4px",
                padding: "4px",
                borderRadius: "4px",
                fontWeight: "bold",
                backgroundColor:
                  project.project_status === "Completed"
                    ? "#c1f5c1"
                    : project.project_status === "In Progress"
                    ? "#f5c69d"
                    : project.project_status === "Planning"
                    ? "#ccccff"
                    : "#9a9c9a",
                color:
                  project.project_status === "Completed"
                    ? "#4caf50"
                    : project.project_status === "In Progress"
                    ? "#f0882e"
                    : project.project_status === "Planning"
                    ? "#0000cc"
                    : "#646664",
              }}
            />
          </Grid>
          <Grid item xs={12}>
      <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>Documents:</Typography>

      {documents.length === 0 ? (
  <Typography sx={{ fontStyle: "italic", color: "gray" }}>
    No documents available.
  </Typography>
) : (
  <>
    <Grid container spacing={2}>
      {documents.slice(0, 10).map((doc, index) => (
        <Grid item xs={1.0} key={index}>
          <Box
            onClick={() => window.open(doc, "_blank")}
            sx={{
              cursor: "pointer",
              textAlign: "center",
              p: 1,
              borderRadius: 2,
              
              transition: "0.3s",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
          >
            {getFileIcon(doc)}
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                display: "block",
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getFilenameFromUrl(doc).slice(0, 8)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>

    {documents.length > 10 && (
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Show All Documents
        </Button>
      </Box>
    )}
  </>
)}

      {/* Show All Documents Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>All Documents</DialogTitle>
        <DialogContent>
          <List>
            {documents.map((doc, index) => (
              <ListItem key={index}>
                <Link href={doc} target="_blank" rel="noopener noreferrer" sx={{ color: "blue", textDecoration: "none" }}>
                  {getFilenameFromUrl(doc)}
                </Link>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Grid>
        </Grid>
      </Paper>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          Properties
        </Typography>
        <Button
          variant="contained"
          component="label"
          color="primary"
          disabled={uploading}
          sx = {{backgroundColor: "#2a3663",
            color: "white",}}
        >
          {uploading ? "Uploading..." : "Upload Properties"}
          <input
            type="file"
            accept=".xls,.xlsx"
            hidden
            onChange={handleFileUpload}
          />
        </Button>
      </Box>

      {properties.length > 0 ? (
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table>
            {/* Table Head with Updated Styles */}
            <TableHead sx={{ backgroundColor: "#2A3663" }}>
              <TableRow>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                  Property ID
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                  Type
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                  Size
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {properties.map((property, index) => (
                <TableRow
                  key={property.propertyid}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                  }}
                >
<TableCell>
  <Link
    component="button"
    onClick={() => handlePropertyView(property)}
    sx={{ textDecoration: "", cursor: "pointer", color: "#1565C0", fontWeight: "" }}
  >
    {property.propertyid || "n/a"}
  </Link>
</TableCell>


                  <TableCell>{property.name || "n/a"}</TableCell>
                  <TableCell>{property.type}</TableCell>
                  <TableCell>{property.dimensions} sqft</TableCell>
                  <TableCell>
                    <Chip
                      label={(property.status).toUpperCase()}
                      sx={{
                        marginTop: "4px",
                        padding: "4px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        backgroundColor:
                          property.status === "Completed"
                            ? "#c1f5c1"
                            : property.status === "In Progress"
                            ? "#f5c69d"
                            : property.status === "planning"
                            ? "#ccccff"
                            : property.status === "Not Started"
                            ? "#fff3cd" // Light yellow shade
                            : "#e0e0e0", // Default grey shade
                        color:
                          property.status === "Completed"
                            ? "#4caf50"
                            : property.status === "In Progress"
                            ? "#f0882e"
                            : property.status === "planning"
                            ? "#0000cc"
                            : property.status === "Not Started"
                            ? "#856404" // Darker yellow-brown for readability
                            : "#6b6b6b", // Default grey text color
                      }}
                      
                    />
                  </TableCell>

                  <TableCell align="center">
                  {/* <Button
  variant="outlined"
  size="small"
  startIcon={<EditIcon />}
  onClick={() => handlePropertyView(property)}
>
  View
</Button> */}
 <Button
   variant="outlined"
   size="small"
   startIcon={<InventoryIcon />}
   onClick={() => setSelectedInventory(property)}
   sx={{ marginLeft: 1 }}
 >
   Inventory
 </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No properties available</Typography>
      )}

      <Dialog
        open={open}
        onClose={onClose}
        fullScreen // Makes the dialog take up the full screen
        sx={{
          "& .MuiPaper-root": {
            // Targets the Dialog's Paper component
            width: "100%",
            height: "100%",
            margin: 0,
            borderRadius: 0, // Removes rounded corners
          },
        }}
      >
        <DialogTitle
          sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Property Details
        </DialogTitle>
        <DialogContent sx={{ height: "calc(100% - 60px)", overflowY: "auto" }}>
          {/* Your content here */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={Boolean(selectedProperty)}
  onClose={() => setSelectedProperty(null)}
  fullWidth
  maxWidth="md"
>
  <DialogTitle>Property Details</DialogTitle>
  <DialogContent>
    {selectedProperty ? (
      <PropertyDetailsDialog
        open={Boolean(selectedProperty)}
        property={selectedProperty}
        onClose={() => setSelectedProperty(null)}
      />
    ) : (
      <Typography>Loading property details...</Typography>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setSelectedProperty(null)} color="error">
      Close
    </Button>
  </DialogActions>
</Dialog>



      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "20px",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle>Edit Project Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Project Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Project Name"
                placeholder="Enter project name"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.project_name || ""}
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    project_name: e.target.value,
                  }))
                }
              />
            </Grid>

            {/* Project Type */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Project Type"
                placeholder="Enter project type"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.project_type || ""}
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    project_type: e.target.value,
                  }))
                }
              />
            </Grid>

            {/* Location */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                placeholder="Enter city"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.project_location_city || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    project_location_city: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                placeholder="Enter state"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.project_location_state || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    project_location_state: e.target.value,
                  }))
                }
              />
            </Grid>

            {/* GPS */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="GPS Coordinates"
                placeholder="Enter GPS coordinates"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.project_location_gps || ""}
                helperText="e.g., 37.7749° N, 122.4194° W"
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    project_location_gps: e.target.value,
                  }))
                }
              />
            </Grid>

            {/* Dates */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.start_date || ""}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    start_date: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Expected Completion Date"
                type="date"
                fullWidth
                margin="dense"
                variant="outlined"
                value={editedProject.expected_completion_date || ""}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setEditedProject((prev) => ({
                    ...prev,
                    expected_completion_date: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditDialogClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogType === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectDetails;