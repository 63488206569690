import React from 'react';
import { Box, Typography } from '@mui/material';
import BillsList from './BillsList';
// import InvoicePdf from './InvoicePdf';
import DashboardPage from './DashBoardPage';
import InventoryList from './InventoryList';
import ProjectForm from "./ProjectForm";
import ProjectsList from './ProjectsList';
import VendorDetails from './VendorDetails';
import Onboarding from './Onboarding';
import ProjectOnboarding from './ProjectOnboarding-sp';
import Manpower from './ManPower-sp';

export default function FormTypeRenderer({ formType }) {
  return (
    <Box sx={{ padding: 0 }}>
      
       {formType === 'Create New Land Deal' && <ProjectOnboarding />}
       {formType === 'View ManPower' && <Manpower />}
      {/* {formType === 'Create New Land Deal' && (
        <Typography variant="h4">Create New Land Deals Coming Soon...</Typography>
      )} */}
      {formType === 'Inventory Management' && (
        <Typography variant="h4">Inventory Management Coming Soon...</Typography>
      )}
      {formType === 'View Bills' && <BillsList />}
      {/* {formType === 'HiTl' && <InvoicePdf />} */}
      {formType === 'View Inventory' && <InventoryList />}
      {formType === 'View Properties' && <ProjectsList />}
      {formType === 'View Vendors' && <VendorDetails />}
      {formType === 'View OnBoarding' && <Onboarding />}

      {!formType && (
        <Typography variant="h4"> Coming Soon...</Typography>
      )}
      
    </Box>
  );
}