import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import HistoryIcon from "@mui/icons-material/History";

import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import OverRideInvoice from "./OverRideInvoice";
import ReviewedHistoryTable from "./ReviewedHistoryTable";
import ItemDropdown from "./ItemDropdown";
import ItemTypeDropDown from "./ItemTypeDropDown";

import NewItemForm from "./NewItemForm";
import UploadTaxInvoice from "./UploadTaxInvoice";

const ReadyForReviewForm = ({ invoiceData, onClose }) => {
  const data = invoiceData;

  const [editableData, setEditableData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [items, setItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [expandedRows, setExpandedRows] = useState({});
  const [showOverrideForm, setShowOverrideForm] = useState(false);
  const [showUploadTaxForm, setShowUploadTaxForm] = useState(false);

  const [showReviewedHistoryTableForm, setShowReviewedHistoryTableForm] =
    useState(false);
  const [showCreateItem, setShowCreateItem] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState("");
  const invoiceType = editableData?.invoice_type;

  const isProformaInvoice = invoiceType === "Tax Invoice";

  const handleItemTypeChange = (itemId, newItemType) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, category: newItemType } : item
      )
    );
  };
  const handleCellEdit = (field, value, id) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        const updatedItem = { ...item, [field]: value };
        updatedItem.total_value = calculateItemTotal(updatedItem);
        return updatedItem;
      }
      return item;
    });
    setItems(updatedItems);
    calculateTotals(updatedItems);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const overdue = grandTotal - (editableData.advancePaid || 0);
    setEditableData((prevData) => ({
      ...prevData,
      overdueAmount: overdue >= 0 ? overdue.toFixed(2) : "0.00",
    }));
  }, [grandTotal, editableData.advancePaid]);
  useEffect(() => {
    if (data) {
      setEditableData(data);
      setOriginalData({ ...data, items: data.items || [] }); // Ensure items array exists
      const updatedItems = (data.items || []).map((item, index) => ({
        id: item.id || index + 1, // Use an index-based fallback for IDs
        code: item.code || "",
        description: item.description || "",
        category: item.category || "0",

        quantity: item.quantity || "0",
        rate: item.unit_price || "0.00",
        tax_percentage: item.taxes_gst_percentage || "0",
        igst_percentage: item.taxes_igst_percentage || "0",
        cgst_percentage: item.taxes_cgst_percentage || "0",
        total_value: item.total_value || "0.00",
      }));
      setItems(updatedItems);
      calculateTotals(updatedItems);
    }
  }, [data]);

  useEffect(() => {
    calculateTotals(items);
  }, [items]);

  useEffect(() => {
    const initialExpandedRows = items.reduce((acc, item) => {
      if (!item.deleted) acc[item.id] = true; // Expand all non-deleted rows
      return acc;
    }, {});
    setExpandedRows(initialExpandedRows);
  }, [items]);

  useEffect(() => {
    const overdue = grandTotal - (editableData.advancePaid || 0);
    setEditableData((prevData) => ({
      ...prevData,
      overdueAmount: overdue >= 0 ? overdue.toFixed(2) : "0.00",
    }));
  }, [grandTotal, editableData.advancePaid]);

  // const handleRowToggle = (id) => {
  //   setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  // };

  // Track field changes
  const handleInputChange = (field, value) => {
    setEditableData({ ...editableData, [field]: value });
  };

  const preparePayload = (products) => {
    // Filter updated items
    const userEmail = localStorage.getItem("email");
    const updatedItems = products.filter(
      (product) => !product.new && !product.deleted
    );

    // Filter new items
    const newItems = products.filter(
      (product) => product.new && !product.deleted
    );

    // Map deleted items to their IDs
    const deletedItems = products
      .filter((product) => product.deleted)
      .map((product) => product.id);

    // Calculate taxes for IGST
    const taxes_igst = updatedItems.reduce((total, item) => {
      return (
        total +
        (parseFloat(item.total_value || 0) *
          parseFloat(item.igst_percentage || 0)) /
          100
      );
    }, 0);

    // Calculate taxes for CGST
    const taxes_cgst = updatedItems.reduce((total, item) => {
      return (
        total +
        (parseFloat(item.total_value || 0) *
          parseFloat(item.cgst_percentage || 0)) /
          100
      );
    }, 0);

    // Calculate taxes for SGST
    const taxes_sgst = updatedItems.reduce((total, item) => {
      return (
        total +
        (parseFloat(item.total_value || 0) *
          parseFloat(item.tax_percentage || 0)) /
          100
      );
    }, 0);

    return {
      avenue_created_invoice_id: editableData.avenue_created_invoice_id,
      bill_status: editableData.bill_status || "REVIEWED",

      // Prepare updated items
      updated_items: updatedItems.map((item) => ({
        id: item.id,
        description: item.description,
        code: item.code,
        // Ensure item_type is correctly mapped
        item_type: item.item_type || item.category || "Uncategorized",
        quantity: parseFloat(item.quantity) || 0,
        unit_price: parseFloat(item.rate) || 0,
        taxable_value: parseFloat(item.total_value) || 0,
        total_value: parseFloat(item.total_value) || 0,
        taxes_igst_percentage: parseFloat(item.igst_percentage) || 0,
        taxes_cgst_percentage: parseFloat(item.cgst_percentage) || 0,
        taxes_gst_percentage: parseFloat(item.tax_percentage) || 0,
      })),

      // Prepare new items
      new_items: newItems.map((item) => ({
        description: item.description,
        code: item.code,
        category: item.category, // Include category for new items
        item_type: item.item_type || item.category || "Uncategorized",
        quantity: parseFloat(item.quantity) || 0,
        unit_price: parseFloat(item.rate) || 0,
        taxable_value: parseFloat(item.total_value) || 0,
        total_value: parseFloat(item.total_value) || 0,
        taxes_igst_percentage: parseFloat(item.igst_percentage) || 0,
        taxes_cgst_percentage: parseFloat(item.cgst_percentage) || 0,
        taxes_gst_percentage: parseFloat(item.tax_percentage) || 0,
      })),

      // Deleted items
      deleted_items: sanitizeDeletedItems(deletedItems),

      // Totals and financial fields
      grandTotal: parseFloat(grandTotal) || 0,
      amount_to_pay: parseFloat(grandTotal).toFixed(2),
      paid_amount: parseFloat(editableData.advancePaid || 0).toFixed(2),
      overdue_amount: parseFloat(editableData.overdueAmount || 0).toFixed(2),

      // Taxes
      taxes_igst: taxes_igst.toFixed(2),
      taxes_cgst: taxes_cgst.toFixed(2),
      taxes_sgst: taxes_sgst.toFixed(2),
      email: userEmail || "unknown@example.com",
    };
  };

  const handleRowToggle = (id) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the expanded state of this row
    }));
  };
  const sanitizeDeletedItems = (deletedItems) => {
    return deletedItems
      .filter((id) => typeof id === "number" || !isNaN(parseInt(id, 10)))
      .map((id) => parseInt(id, 10));
  };

  const calculateTotals = (itemsList) => {
    console.log("Calculating totals for items:", itemsList);

    // Exclude deleted items
    const activeItems = itemsList.filter((item) => !item.deleted);

    // Calculate Total Value (sum of all total_value fields)
    const totalValue = activeItems.reduce((sum, item) => {
      return sum + parseFloat(item.total_value || 0);
    }, 0);

    const igstValue = activeItems.reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.igst_percentage || 0) *
          parseFloat(item.quantity || 0) *
          parseFloat(item.rate || 0)) /
          100,
      0
    );

    const cgstValue = activeItems.reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.cgst_percentage || 0) *
          parseFloat(item.quantity || 0) *
          parseFloat(item.rate || 0)) /
          100,
      0
    );
    const sgstValue = activeItems.reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.tax_percentage || 0) *
          parseFloat(item.quantity || 0) *
          parseFloat(item.rate || 0)) /
          100,
      0
    );

    const totalTax = igstValue + cgstValue + sgstValue;

    // Calculate Subtotal
    const subtotalValue = totalValue - totalTax;

    // Update State
    setSubtotal(subtotalValue.toFixed(2));
    setTotalTax(totalTax.toFixed(2));
    setGrandTotal(totalValue.toFixed(2));

    // Update Tax State
    setEditableData((prevData) => ({
      ...prevData,
      taxes_igst: igstValue.toFixed(2),
      taxes_cgst: cgstValue.toFixed(2),
      taxes_sgst: sgstValue.toFixed(2),
    }));
  };

  const handleStatusChange = async (status) => {
    setLoading(true); // Show loading spinner
    try {
      setEditableData((prevData) => ({ ...prevData, bill_status: status }));
      setItems((prevItems) =>
        prevItems.map((item) => ({ ...item, bill_status: status }))
      );

      const payload = preparePayload(items);

      console.log("Sending Payload:", JSON.stringify(payload, null, 2));

      const response = await axios.post(
        // "https://test.datso.io/update-invoice-status",
        "https://test.datso.io/update-invoice-status",

        { ...payload, bill_status: status },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        if (status === "REJECTED") {
          showDialog("Invoice Has Been Rejected.", "error");
        } else {
          showDialog(`Status updated to '${status}' successfully!`, "success");
        }
      } else {
        showDialog(
          `Failed to update status. Status code: ${response.status}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating status:", error);
      showDialog(`Failed to update status: ${error.message}`, "error");
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleAddNewProduct = () => {
    const newProduct = {
      id: uuidv4(), // Generate a temporary unique ID
      code: "",
      description: "",
      quantity: "0",
      category: "",
      rate: "0.00",
      igst_percentage: "0.00",
      cgst_percentage: "0.00",
      tax_percentage: "0.00",
      total_value: "0.00",
      new: true, // Mark as a new item
      deleted: false, // Ensure not marked as deleted
    };

    setItems((prevItems) => [...prevItems, newProduct]);
    calculateTotals([...items, newProduct]); // Recalculate totals
    console.log("Added new item:", newProduct);
  };

  const deleteItem = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, deleted: true } : item
      )
    );
    console.log(`Marked item with id ${id} as deleted.`);
    calculateTotals(items.filter((item) => !item.deleted)); // Recalculate totals
  };

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity || "info");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    onClose();
  };

  const calculateItemTotal = (item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const rate = parseFloat(item.rate) || 0;
    const taxPercentage = parseFloat(item.tax_percentage) || 0;
    const igstPercentage = parseFloat(item.igst_percentage) || 0;
    const cgstPercentage = parseFloat(item.cgst_percentage) || 0;

    const baseValue = quantity * rate;
    const totalTax =
      (baseValue * taxPercentage) / 100 +
      (baseValue * igstPercentage) / 100 +
      (baseValue * cgstPercentage) / 100;

    return (baseValue + totalTax).toFixed(2); // Round to 2 decimal places
  };

  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: 2,
        padding: 2,
        backgroundColor: "#F9F9F9",
        boxShadow: 3,
        maxHeight: "90%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          position: "sticky",
          height: 72,
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          color: "#FFFFFF",
          backgroundColor: "#2A3663",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 3,
        }}
      >
        Invoice Details #{editableData.json_invoice_number || "N/A"}
        <IconButton color="white">
          <HistoryIcon
            sx={{ color: "white", marginLeft: "0px" }}
            onClick={() => setShowReviewedHistoryTableForm(true)}
          />
        </IconButton>
        <Box>
          {/* <Button
            variant="contained"
            color="success"
            sx={{ mr: 2 }}
            onClick={() => setShowUploadTaxForm(true)}
            startIcon={<UploadFileIcon />}
            disabled={isProformaInvoice} // Disable if it's a Proforma Invoice

          >
            Upload Tax Invoice
          </Button> */}
          <Button
            variant="contained"
            color="success"
            sx={{ mr: 2 }}
            onClick={() => handleStatusChange("REVIEWED")}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ mr: 4 }}
            onClick={() => handleStatusChange("REJECTED")}
          >
            Reject
          </Button>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              width: "32px",
              height: "32px",
              color: "#555",
              backgroundColor: "#f0f0f0",
              border: "1px solid #ccc",
              borderRadius: "20%",
              transition: "all 0.3s ease",
              "&:hover": {
                color: "#fff",
                backgroundColor: "#d32f2f",
                borderColor: "#d32f2f",
                transform: "scale(1.1)",
              },
              "&:active": {
                transform: "scale(0.95)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Typography>

      <CardContent
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: 2,
        }}
      >
        {loading && (
          <CircularProgress sx={{ display: "block", margin: "0 auto 16px" }} />
        )}
        {/* <Box sx={{ display: "flex", gap: 2,overflowY: "auto", }}> */}
        {/* Left Side - PDF Viewer */}
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Box>
              <iframe
                src={data.file_url}
                width="100%"
                height="700px"
                style={{ border: "none" }}
                title="Invoice PDF"
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            {/* Right Side - Form Fields and Items Table */}
            <Box
              sx={{
                minHeight: "100%", // Set the minimum height
                maxHeight: "600px", // Optional: Set a maximum height
                overflowY: "auto",
                border: "1px solid #ccc", // Add a border for visual distinction
                borderRadius: "8px", // Optional: Add rounded corners
                padding: 2,
              }}
            >
              <Paper sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Invoice Details
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      {editableData.invoice_type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Invoice Number"
                      value={editableData.json_invoice_number || ""}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Invoice Date"
                      value={editableData.invoice_date || ""}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      label="Supplier Name"
                      value={editableData.supplier_name || ""}
                      fullWidth
                      disabled // Make the field non-editable
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Vendor Bank Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Bank Name"
                      value={editableData.bank_name || ""}
                      onChange={(e) =>
                        handleInputChange("bank_name", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Bank Account Number"
                      value={editableData.bank_account_no || ""}
                      onChange={(e) =>
                        handleInputChange("bank_account_no", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Bank IFSC"
                      value={editableData.bank_ifsc || ""}
                      onChange={(e) =>
                        handleInputChange("bank_ifsc", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{ padding: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Item Details
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "6px",
                            height: "40px",
                            fontSize: "18px",
                            backgroundColor: "#2A3663",
                            color: "white",
                          }}
                        >
                          {" "}
                          Description{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "6px",
                            height: "40px",
                            fontSize: "18px",
                            textAlign: "center",
                            backgroundColor: "#2A3663",
                            color: "white",
                          }}
                        >
                          {" "}
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "6px",
                            height: "40px",
                            fontSize: "18px",
                            textAlign: "center",
                            backgroundColor: "#2A3663",
                            color: "white",
                          }}
                        >
                          {" "}
                          Description Type
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "6px",
                            height: "40px",
                            fontSize: "18px",
                            backgroundColor: "#2A3663",
                            color: "white",
                            textAlign: "right",
                          }}
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map(
                          (item) =>
                            !item.deleted && (
                              <React.Fragment key={item.id}>
                                <TableRow>
                                  <TableCell
                                    colSpan={6}
                                    sx={{
                                      padding: 0,
                                      border: "2px solid grey",
                                      borderRadius: "14px",
                                    }}
                                  >
                                    <div>
                                      {/* Parent Row */}
                                      <Table
                                        sx={{
                                          width: "100%",
                                          tableLayout: "fixed",
                                        }}
                                      >
                                        <TableBody>
                                          <TableRow>
                                            <Tooltip
                                              title={item.description}
                                              placement="auto"
                                              arrow
                                            >
                                              <TableCell
                                                sx={{
                                                  padding: "6px",
                                                  fontWeight: "bold",
                                                }}
                                                colSpan={3}
                                              >
                                                <div
                                                  style={{
                                                    maxWidth: "150px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <TextField
                                                    sx={{
                                                      cursor: "text",
                                                      color: "white",
                                                    }}
                                                    InputProps={{
                                                      disableUnderline: true,
                                                      style: {
                                                        maxWidth: "150px",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        cursor: "pointer",
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    value={item.description}
                                                    onChange={(e) =>
                                                      handleCellEdit(
                                                        "description",
                                                        e.target.value,
                                                        item.id
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell
                                              sx={{
                                                padding: "6px",
                                                fontWeight: "bold",
                                              }}
                                              colSpan={2}
                                            >
                                              <div key={item.id}>
                                                <h3>{item.name}</h3>
                                                <ItemTypeDropDown
                                                  value={item.category} // Pass the selected category for each item
                                                  onSelect={(newItemType) =>
                                                    handleItemTypeChange(
                                                      item.id,
                                                      newItemType
                                                    )
                                                  } // Pass item id to correctly update the category
                                                />
                                              </div>
                                            </TableCell>

                                            <TableCell
                                              sx={{
                                                padding: "6px",
                                                fontWeight: "bold",
                                              }}
                                              colSpan={2}
                                            >
                                              <ItemDropdown
                                                onCreateItem={() =>
                                                  setShowCreateItem(true)
                                                }
                                                onSelect={(selectedItem) => {
                                                  handleCellEdit(
                                                    "selectedVendor",
                                                    selectedItem,
                                                    item.id
                                                  );
                                                }}
                                                value={
                                                  item.selectedVendor || null
                                                } // Use individual item's state properly
                                              />
                                            </TableCell>

                                            <TableCell
                                              sx={{
                                                padding: "6px",
                                                fontWeight: "bold",
                                                textAlign: "right",
                                              }}
                                              colSpan={2}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  handleRowToggle(item.id)
                                                }
                                              >
                                                {expandedRows[item.id] ? (
                                                  <ExpandLessIcon />
                                                ) : (
                                                  <ExpandMoreIcon />
                                                )}
                                              </IconButton>
                                              <IconButton
                                                color="darkgrey"
                                                onClick={() =>
                                                  deleteItem(item.id)
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>

                                          {/* Expanded Row */}
                                          {expandedRows[item.id] && (
                                            <TableRow>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              />
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              />
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>HSN Code</TableHead>
                                                <TextField
                                                  variant="standard"
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  type="text"
                                                  sx={{ width: "70px" }}
                                                  value={item.code}
                                                  onChange={(e) =>
                                                    handleCellEdit(
                                                      "code",
                                                      e.target.value,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>Quantity</TableHead>
                                                <TextField
                                                  variant="standard"
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  type="number"
                                                  sx={{ width: "70px" }}
                                                  value={item.quantity}
                                                  onChange={(e) =>
                                                    handleCellEdit(
                                                      "quantity",
                                                      e.target.value,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>Rate</TableHead>
                                                <TextField
                                                  variant="standard"
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  sx={{ width: "80px" }}
                                                  type="number"
                                                  value={item.rate}
                                                  onChange={(e) =>
                                                    handleCellEdit(
                                                      "rate",
                                                      e.target.value,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>IGST</TableHead>
                                                <TextField
                                                  variant="standard"
                                                  sx={{ width: "80px" }}
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  type="number"
                                                  value={item.igst_percentage}
                                                  onChange={(e) =>
                                                    handleCellEdit(
                                                      "igst_percentage",
                                                      e.target.value,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>CGST</TableHead>
                                                <TextField
                                                  variant="standard"
                                                  sx={{ width: "80px" }}
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  type="number"
                                                  value={item.cgst_percentage}
                                                  onChange={(e) =>
                                                    handleCellEdit(
                                                      "cgst_percentage",
                                                      e.target.value,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>SGST</TableHead>
                                                <TextField
                                                  variant="standard"
                                                  sx={{ width: "80px" }}
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  type="number"
                                                  value={item.tax_percentage}
                                                  onChange={(e) =>
                                                    handleCellEdit(
                                                      "tax_percentage",
                                                      e.target.value,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell
                                                sx={{ padding: "6px" }}
                                              >
                                                <TableHead>
                                                  Total Value
                                                </TableHead>
                                                <TextField
                                                  variant="standard"
                                                  sx={{ width: "80px" }}
                                                  InputProps={{
                                                    disableUnderline: false,
                                                  }}
                                                  type="number"
                                                  value={item.total_value}
                                                  onChange={(e) => {
                                                    const newValue =
                                                      e.target.value;
                                                    setItems((prevItems) =>
                                                      prevItems.map(
                                                        (prevItem) =>
                                                          prevItem.id ===
                                                          item.id
                                                            ? {
                                                                ...prevItem,
                                                                total_value:
                                                                  newValue,
                                                              }
                                                            : prevItem
                                                      )
                                                    );
                                                  }}
                                                />
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            )
                        )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[15, 20, 30]}
                          count={items.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewProduct}
                  sx={{ marginTop: 2 }}
                >
                  {" "}
                  + Add Items{" "}
                </Button>
              </Paper>
              <Grid container spacing={2}>
                {/* Tax Details */}
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      padding: 3,
                      marginTop: 4,
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 3,
                        color: "#333",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Tax Details
                    </Typography>
                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="IGST"
                      value={editableData.taxes_igst || "0.00"}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          taxes_igst: parseFloat(e.target.value) || 0, // Update IGST value
                        })
                      }
                      fullWidth
                    />

                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="CGST"
                      value={editableData.taxes_cgst || "0.00"}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          taxes_cgst: parseFloat(e.target.value) || 0, // Update CGST value
                        })
                      }
                      fullWidth
                    />

                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="SGST"
                      value={editableData.taxes_sgst || "0.00"}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          taxes_sgst: parseFloat(e.target.value) || 0, // Update SGST value
                        })
                      }
                      fullWidth
                    />
                  </Paper>
                </Grid>

                {/* Invoice Summary */}
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      padding: 3,
                      marginTop: 4,
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 3,
                        color: "#333",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Invoice Summary
                    </Typography>
                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="Subtotal"
                      value={subtotal || "0.00"}
                      onChange={(e) => {
                        // Update the subtotal value based on user input
                        setSubtotal(parseFloat(e.target.value) || 0); // Ensure value is a valid number
                      }}
                      fullWidth
                    />

                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="Tax"
                      value={totalTax || "0.00"}
                      onChange={(e) => {
                        // Update the totalTax value based on user input
                        setTotalTax(parseFloat(e.target.value) || 0); // Ensure value is a valid number
                      }}
                      fullWidth
                    />

                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="Grand Total"
                      value={grandTotal || "0.00"}
                      onChange={(e) => {
                        // Update the grandTotal value based on user input
                        setGrandTotal(parseFloat(e.target.value) || 0); // Ensure value is a valid number
                      }}
                      fullWidth
                    />
                  </Paper>
                </Grid>

                {/* Payment Details */}
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      padding: 3,
                      marginTop: 4,
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 3,
                        color: "#333",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Payment Details
                    </Typography>
                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="Advance Paid Amount"
                      value={editableData.advancePaid || ""}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          advancePaid: parseFloat(e.target.value) || 0,
                        })
                      }
                      fullWidth
                    />
                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="Total Amount"
                      value={grandTotal || "0.00"}
                      onChange={(e) =>
                        setGrandTotal(parseFloat(e.target.value) || 0)
                      }
                      fullWidth
                    />

                    <TextField
                      sx={{
                        mb: 3,
                        "& .MuiInputLabel-root": { color: "#555" },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                      }}
                      label="Overdue Amount"
                      value={(
                        grandTotal - (editableData.advancePaid || 0) || 0
                      ).toFixed(2)}
                      onChange={(e) => {
                        // You can update `grandTotal` or `advancePaid` here depending on what the user changes
                        const newOverdueAmount =
                          parseFloat(e.target.value) || 0;
                        const newAdvancePaid = grandTotal - newOverdueAmount;

                        setEditableData({
                          ...editableData,
                          advancePaid: newAdvancePaid,
                        });
                      }}
                      fullWidth
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      {showUploadTaxForm && (
        <Box
          sx={{
            position: "fixed",
            top: "3%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height: "100%",
            maxWidth: "1840px",
            maxHeight: "84vh",
            overflowY: "auto",
            backgroundColor: "transparent",
            borderRadius: "8px",
            zIndex: 1300,
            padding: 2,
            marginTop: "26px",
          }}
        >
          <UploadTaxInvoice
            onClose={() => setShowUploadTaxForm(false)}
            invoiceData={data}
          />
        </Box>
      )}
      {showReviewedHistoryTableForm && (
        <Box
          sx={{
            position: "fixed",
            top: "3%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height: "100%",
            maxWidth: "1840px",
            maxHeight: "84vh",
            overflowY: "auto",
            backgroundColor: "transparent",
            borderRadius: "8px",
            zIndex: 1300,
            padding: 2,
            marginTop: "26px",
          }}
        >
          <ReviewedHistoryTable
            onClose={() => setShowReviewedHistoryTableForm(false)}
            invoiceData={data}
          />
        </Box>
      )}

      {showCreateItem && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1300,
          }}
        >
          <NewItemForm onClose={() => setShowCreateItem(false)} />
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        PaperProps={{
          sx: {
            borderRadius: 4,
            boxShadow: 6,
            backgroundColor:
              dialogSeverity === "success" ? "#e1fcd4" : "#f5b7b5", // Light red for error
            color: dialogSeverity === "success" ? "#2E7D32" : "#d41f19", // White text for error
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: dialogSeverity === "success" ? "#388E3C" : "#d41f19", // White for error title
            fontWeight: "bold",
          }}
        >
          {dialogSeverity === "success" ? (
            <CheckCircleIcon fontSize="large" sx={{ color: "#388E3C" }} />
          ) : (
            <ErrorIcon fontSize="large" sx={{ color: "#d41f19" }} />
          )}
          {dialogSeverity === "success" ? "Success" : "Rejected"}
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "16px",
              color: dialogSeverity === "success" ? "#2E7D32" : "#d41f19", // White text for error
            }}
          >
            {" "}
            {dialogMessage}{" "}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: 2 }}>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            sx={{
              backgroundColor:
                dialogSeverity === "success" ? "#388E3C" : "#D32F2F",
              "&:hover": {
                backgroundColor:
                  dialogSeverity === "success" ? "#2E7D32" : "#B71C1C",
              },
              color: "black",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            {" "}
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ReadyForReviewForm;