import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, CircularProgress, Grid, Button, Divider, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EditVendorForm from "./EditVendorForm";

const VendorDetailsComponent = ({ vendorId, onClose }) => {
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    fetchVendor();
  }, [vendorId]);

  const fetchVendor = async () => {
    try {
      const response = await fetch(`https://test.datso.io/get-vendor?vendor_id=${vendorId}`);
      if (!response.ok) throw new Error(`API Error: ${response.status}`);
      const data = await response.json();
      setVendor(data.vendor);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!vendor) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography variant="h6" color="error">No vendor details found</Typography>
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Paper elevation={3} sx={{ padding: "20px", borderRadius: "12px", backgroundColor: "#f9fafb" }}>
        {/* ✅ Header Section (Title + Buttons) */}
        <Box display="flex" justifyContent="space-between" alignItems="center" pb={2} borderBottom="2px solid #ddd">
          <Typography variant="h4" fontWeight="bold">Vendor Details</Typography>
          <Box display="flex" gap={1}>
            <Button variant="contained" startIcon={<EditIcon />} onClick={() => setIsEditOpen(true)} sx={{ backgroundColor: "#2a3663", color: "white" }}>
              Edit
            </Button>
            <Button
              onClick={onClose}
              startIcon={<CloseIcon />}
              variant="outlined"
              color="error"
            >
              Close
            </Button>
          </Box>
        </Box>

        {/* ✅ Vendor Details Sections */}
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Vendor ID:</strong> {vendor.vendor_id || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Name:</strong> {vendor.vendor_name || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Display Name:</strong> {vendor.vendor_display_name || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Email:</strong> {vendor.vendor_email_address || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Work Phone:</strong> {vendor.vendor_work_phone || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Personal Phone:</strong> {vendor.vendor_personal_phone || "N/A"}</Typography></Grid>

          <Divider sx={{ width: "100%", marginTop: 2, marginBottom: 2 }} />

          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Bank Name:</strong> {vendor.vendor_bank_name || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Account Number:</strong> {vendor.vendor_bank_account_number || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>IFSC Code:</strong> {vendor.vendor_bank_ifsc_code || "N/A"}</Typography></Grid>
          <Grid item xs={12}><Typography variant="body1"><strong>Bank Address:</strong> {vendor.vendor_bank_branch_address || "N/A"}</Typography></Grid>

          <Divider sx={{ width: "100%", marginTop: 2, marginBottom: 2 }} />

          <Grid item xs={12} md={6}><Typography variant="body1"><strong>GST Number:</strong> {vendor.vendor_gst_number || "N/A"}</Typography></Grid>
          <Grid item xs={12} md={6}><Typography variant="body1"><strong>Source of Supply:</strong> {vendor.source_of_supply || "N/A"}</Typography></Grid>
          <Grid item xs={12}><Typography variant="body1"><strong>Taxes Applicable:</strong> {vendor.taxes_applicable || "N/A"}</Typography></Grid>
          <Grid item xs={12}><Typography variant="body1"><strong>Other Details:</strong> {vendor.other_details || "N/A"}</Typography></Grid>
        </Grid>
      </Paper>

      {/* ✅ Edit Form Pop-up */}
      <Dialog open={isEditOpen} onClose={() => setIsEditOpen(false)} maxWidth="md" fullWidth>
        <EditVendorForm 
          vendorData={vendor} 
          onClose={() => setIsEditOpen(false)} 
          onVendorCreated={fetchVendor} 
        />
      </Dialog>
    </Box>
  );
};

export default VendorDetailsComponent;