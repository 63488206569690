import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";

const SchedulePIP = ({ open, onClose, property }) => {
  const [startDate, setStartDate] = useState("");
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);

  // State for inventory file attachment
  const [inventoryFile, setInventoryFile] = useState(null);
  const [inventoryFileName, setInventoryFileName] = useState("");

  // Ref for hidden schedule file input
  const scheduleFileInputRef = useRef();

  // State for notification dialog
  const [notificationDialog, setNotificationDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  // Function to fetch schedule types from the backend
  const fetchScheduleTypes = async () => {
    try {
      const response = await axios.get("https://test.datso.io/schedule-types");
      if (response.data.schedule_types && response.data.schedule_types.length > 0) {
        setScheduleTypes(response.data.schedule_types);
        if (!selectedType) {
          setSelectedType(response.data.schedule_types[0]);
        }
      } else {
        setScheduleTypes([]);
      }
    } catch (error) {
      console.error("Error fetching schedule types:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchScheduleTypes();
    }
  }, [open]);

  // Prepare options for the Autocomplete dropdown including the special option
  const scheduleOptions = scheduleTypes.map((type) => ({
    value: type,
    label: type,
  }));
  scheduleOptions.push({ value: "UPLOAD_NEW", label: "Upload New Schedule..." });

  // Handler for inventory file selection
  const handleInventoryFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setInventoryFile(file);
      setInventoryFileName(file.name);
    }
  };

  // Handler for schedule file selection triggered from the hidden input
  const handleScheduleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setLoading(true);
    const scheduleFormData = new FormData();
    scheduleFormData.append("file", file);
    try {
      const scheduleUploadResponse = await axios.post(
        `https://test.datso.io/schedule/upload/${property.propertyid}`,
        scheduleFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("Schedule upload response:", scheduleUploadResponse.data);
      setNotificationDialog({
        open: true,
        title: "Success",
        message: "Schedule uploaded successfully!",
      });
      // Refresh the dropdown after upload
      await fetchScheduleTypes();
    } catch (error) {
      console.error("Error uploading schedule file:", error.response?.data || error.message);
      setNotificationDialog({
        open: true,
        title: "Error",
        message: "Failed to upload schedule.",
      });
    }
    setLoading(false);
  };

  // Handler for changes in the Autocomplete dropdown
  const handleScheduleTypeChange = (event, newValue) => {
    if (newValue && newValue.value === "UPLOAD_NEW") {
      setSelectedType(null);
      scheduleFileInputRef.current.click();
    } else {
      setSelectedType(newValue ? newValue.value : "");
    }
  };

  // Main function to create schedule and optionally upload inventory file
  const handleSchedule = async () => {
    if (!selectedType || !startDate) {
      alert("Please select a schedule type and start date.");
      return;
    }

    if (selectedType === "UPLOAD_NEW") {
      return; // Already handled by file input trigger.
    }

    const payload = {
      property_id: property.propertyid,
      schedule_type: selectedType,
      start_date: startDate,
    };

    setLoading(true);
    try {
      // Create the schedule via API call
      const scheduleResponse = await axios.post(
        "https://test.datso.io/create-schedule",
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      setNotificationDialog({
        open: true,
        title: "Success",
        message: scheduleResponse.data.message,
      });

      // If an inventory file is attached, upload it
      if (inventoryFile) {
        const inventoryFormData = new FormData();
        inventoryFormData.append("file", inventoryFile);
        inventoryFormData.append("propertyId", property.propertyid);
        const inventoryUploadResponse = await axios.post(
          "https://test.datso.io/inventory/property/upload",
          inventoryFormData
        );
        console.log("Inventory upload response:", inventoryUploadResponse.data);
        setNotificationDialog({
          open: true,
          title: "Success",
          message: "Inventory uploaded successfully!",
        });
      }

      window.dispatchEvent(new Event("scheduleUpdated"));
      onClose();
    } catch (error) {
      console.error("Error during schedule creation:", error.response?.data || error.message);
      setNotificationDialog({
        open: true,
        title: "Error",
        message: "Failed to create schedule or upload files.",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Schedule & Upload for Property: {property.name}</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            margin="dense"
          />
          <Autocomplete
            options={scheduleOptions}
            value={
              typeof selectedType === "string"
                ? { value: selectedType, label: selectedType }
                : selectedType
            }
            onChange={handleScheduleTypeChange}
            renderInput={(params) => (
              <TextField {...params} label="Select a Schedule Type" margin="dense" />
            )}
            ListboxProps={{ style: { maxHeight: 200, overflow: "auto" } }}
            fullWidth
          />
          {/* Hidden schedule file input */}
          <input
            type="file"
            accept=".xls,.xlsx,.csv,.json"
            style={{ display: "none" }}
            ref={scheduleFileInputRef}
            onChange={handleScheduleFileSelect}
          />
          {/* Inventory File Attachment Section */}
          <Box mt={2}>
            <Button
              variant="contained"
              component="label"
              size="small"
              startIcon={<AttachFileIcon />}
              sx={{
                backgroundColor: "#2A3663",
                "&:hover": { backgroundColor: "#1E2A48" },
              }}
            >
              Inventory Excel
              <input
                type="file"
                accept=".xls,.xlsx"
                hidden
                onChange={handleInventoryFileSelect}
              />
            </Button>
            {inventoryFileName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected Inventory File: {inventoryFileName}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSchedule}
            variant="contained"
            color="primary"
            disabled={loading || !selectedType || !startDate}
          >
            {loading ? <CircularProgress size={24} /> : "Schedule Now"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Notification Dialog */}
      <Dialog
        open={notificationDialog.open}
        onClose={() =>
          setNotificationDialog({ ...notificationDialog, open: false })
        }
      >
        <DialogTitle>{notificationDialog.title}</DialogTitle>
        <DialogContent>
          <Typography>{notificationDialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setNotificationDialog({ ...notificationDialog, open: false })
            }
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SchedulePIP;
