import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  TextField,
  InputAdornment,

} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProjectDetails from "./ProjectDetails"; // Import the ProjectDetails component
import ProjectOnBoardingForm from "./ProjectOnboardingForm"; // Import the ProjectOnBoardingForm component
import SearchIcon from "@mui/icons-material/Search";
import 'react-big-calendar/lib/css/react-big-calendar.css';



const ProjectOnboarding = () => {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedProjectId, setSelectedProjectId] = useState(null); // ID of the selected project
  const [isPipOpen, setIsPipOpen] = useState(false); // State for PIP window
  const [isFormOpen, setIsFormOpen] = useState(false); // State for form dialog
  const [searchQuery, setSearchQuery] = useState("");
 const [sortField, setSortField] = useState("created_at"); // Sorting field
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch("https://test.datso.io/projects_m");
        const data = await response.json();
        setProjects(data.projects || []);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  const handleOpenPip = (projectId) => {
    setSelectedProjectId(projectId);
    setIsPipOpen(true);
  };

  
  const handleClosePip = () => {
    setIsPipOpen(false);
    setSelectedProjectId(null);
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };
  const handleSortChange = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const sortedProjects = [...projects].sort((a, b) => {
    if (!sortField) return 0;
    const valA = a[sortField] ? a[sortField].toString().toLowerCase() : "";
    const valB = b[sortField] ? b[sortField].toString().toLowerCase() : "";
    return sortOrder === "asc" ? valA.localeCompare(valB) : valB.localeCompare(valA);
  });
  const filteredProjects = sortedProjects.filter(
    (project) =>
      project.project_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.project_manager?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.project_type?.toLowerCase().includes(searchQuery.toLowerCase())||
      project.project_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.start_date?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.project_location_city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.project_status?.toLowerCase().includes(searchQuery.toLowerCase()) 
  );
  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "#c1f5c1"; // Green
      case "In Progress":
        return "#f5c69d"; // Orange
      case "Pending":
      return "#f44336"; // Red
        case "Planning":
     return "#ccccff"; // Red
      default:
        return "#9a9c9a"; // Grey
    }
  };
  const getTextColor = (status) => {
    switch (status) {
      case "Completed":
        return "#4caf50"; // Green
      case "In Progress":
        return "#f0882e"; // Orange
      case "Pending":
        return "#f44336"; // Red
        case "Planning":
          return "#0000cc"; // Red
      default:
        return "#646664"; // Grey
    }
  };
  return (
    <Box sx={{ padding: 0, margin: "0", backgroundColor: "#F9F9F9", borderRadius: "8px", marginTop: "-4%" }}>
       <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
       <Typography variant="h4" sx={{ fontWeight: "bold", color: "black" }}>
       </Typography>
       <Box sx={{marginTop:"1.1%",marginLeft:"2%",marginRight:"2%"}}>

       <TextField
          variant="outlined"
          placeholder="Search Projects"
          size="small"
          sx={{ marginRight: 2, 
            backgroundColor: "#fffff",
            width:300,
            borderRadius: "25px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "25px",
              height: "36px", // Set a reduced height
              padding: "0", // Remove extra padding
              "& input": {
                padding: "8px 12px", // Control the inner padding
              },
            },            }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Button
          variant="contained"
          style={{
            backgroundColor: "#2a3663",
            color: "white",
            
          }}
          onClick={handleOpenForm} // Open the form dialog
        >
          Add Project
        </Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: "2px", overflowX: "" }}>
            <Table sx={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead sx={{ backgroundColor: "#2A3663" }}>
            <TableRow>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("project_id")}>
                ID{" "}
                {sortField === "project_id" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("project_name")}>
                Name{" "}
                {sortField === "project_name" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("project_location_city")}>
                City{" "}
                {sortField === "project_location_city" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("project_type")}>
                Type{" "}
                {sortField === "project_type" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("project_manager")}>
                Manager{" "}
                {sortField === "project_manager" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("start_date")}>
                Start Date{" "}
                {sortField === "start_date" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("start_date")}>
                Created Date{" "}
                {sortField === "created_at" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSortChange("project_status")}>
                Status{" "}
                {sortField === "project_status" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredProjects.slice(
              currentPage * itemsPerPage,
              currentPage * itemsPerPage + itemsPerPage
            ).map((project) => (
              <TableRow key={project.project_id}>
                <TableCell
                  sx={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    color: "#1565C0",
                    textDecoration: "underline",
                  }}
                  onClick={() => handleOpenPip(project.project_id)} >
                  {project.project_id}
                </TableCell>
                <TableCell>{project.project_name}</TableCell>
                <TableCell>{project.project_location_city}</TableCell>

                <TableCell>{project.project_type}</TableCell>
                <TableCell>{project.project_manager}</TableCell>
               
                <TableCell>{project.start_date}</TableCell>
     <TableCell>
                    {project.created_at ? new Date(project.created_at).toLocaleDateString() : "N/A"}
                  </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: getStatusColor(project.project_status),
                      color: getTextColor(project.project_status),
                      padding: "4px",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      width: "165px",
                      height: "30px",
                      textAlign: "center",
                      border:`1px solid ${getTextColor(project.project_status)}`,
                    }}
                  >
                    {(project.project_status).toUpperCase()}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredProjects.length}
          page={currentPage}
          onPageChange={(e, newPage) => setCurrentPage(newPage)}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
        />
      </TableContainer>

      {/* PIP Dialog */}
      <Dialog
  open={isPipOpen}
  onClose={handleClosePip}
  fullWidth
  maxWidth={false} // Keeps full width
  PaperProps={{
    sx: {
      height:"90vh",
      marginTop: "90px", // Adds top margin
      marginX: "10px", // Light margin on sides
      width: "calc(100% - 30px)", // Adjust width to maintain margins
      // height: "calc(100% - 80px)", // Adjust height to keep top margin
      borderRadius: 3, // Slight rounded corners for smooth UI
    },
  }}
>
  {selectedProjectId && (
    <ProjectDetails projectId={selectedProjectId} onClose={handleClosePip} />
  )}
</Dialog>

      {/* Form Dialog */}
      <Dialog open={isFormOpen} onClose={handleCloseForm} fullWidth maxWidth="md">
        <ProjectOnBoardingForm onClose={handleCloseForm} />
      </Dialog>
    </Box>
  );
};

export default ProjectOnboarding;