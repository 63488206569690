import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Chip,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  DialogActions,
  Checkbox,
  ListItemText,
  Autocomplete,
  Grid,
  Menu,
} from "@mui/material";
import debounce from "lodash.debounce";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LaborOnboardingForm from "./LaborOnboardingForm";
import ContractorOnboardingForm from "./ContractorOnboardingForm";
import ManpowerDetail from "./ManpowerDetails";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";

const Manpower = () => {
  const [manpower, setManpower] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [formType, setFormType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [lastEmployeeId, setLastEmployeeId] = useState({
    labor: 0,
    contractor: 0,
  });
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  // Assignment dialog state
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [projectProperties, setProjectProperties] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [selectedLabors, setSelectedLabors] = useState([]);
  const [contractorSearch, setContractorSearch] = useState("");
  const [laborSearch, setLaborSearch] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [propertySearch, setPropertySearch] = useState("");

  const flattenedManpower = useMemo(() => {
    if (!Array.isArray(manpower)) return [];
    return manpower
      .flatMap((person) => {
        if (!person) return [];
        if (person.type === "CONTRACTOR" && Array.isArray(person.labors)) {
          return [
            { ...person, isParent: true },
            ...person.labors
              .map((labor) => ({ ...labor, parentId: person.id }))
              .filter(Boolean),
          ];
        }
        return person;
      })
      .filter(Boolean);
  }, [manpower]);

  const contractors = useMemo(
    () => flattenedManpower.filter((person) => person?.type === "CONTRACTOR"),
    [flattenedManpower]
  );

  const labors = useMemo(
    () => flattenedManpower.filter((person) => person?.type === "LABOR"),
    [flattenedManpower]
  );

  const filteredContractors = useMemo(() => {
    const searchTerm = contractorSearch.trim().toLowerCase();
    if (!searchTerm) return contractors;
    return contractors.filter((contractor) => {
      if (!contractor) return false;
      return (
        contractor.name?.toLowerCase().includes(searchTerm) ||
        contractor.employee_id?.toLowerCase().includes(searchTerm)
      );
    });
  }, [contractors, contractorSearch]);

  const filteredLabors = useMemo(() => {
    const searchTerm = laborSearch.trim().toLowerCase();
    if (!searchTerm) return labors;
    return labors.filter((labor) => {
      if (!labor) return false;
      return (
        labor.name?.toLowerCase().includes(searchTerm) ||
        labor.employee_id?.toLowerCase().includes(searchTerm)
      );
    });
  }, [labors, laborSearch]);

  const filteredProjects = useMemo(() => {
    const searchTerm = projectSearch.trim().toLowerCase();
    if (!searchTerm) return projects;
    return projects.filter((project) => {
      if (!project) return false;
      return (
        project.project_name?.toLowerCase().includes(searchTerm) ||
        project.project_id?.toString().toLowerCase().includes(searchTerm)
      );
    });
  }, [projects, projectSearch]);

  const filteredProperties = useMemo(() => {
    const searchTerm = propertySearch.trim().toLowerCase();
    if (!searchTerm) return projectProperties;
    return projectProperties.filter((property) => {
      if (!property) return false;
      return (
        property.name?.toLowerCase().includes(searchTerm) ||
        property.propertyid?.toString().toLowerCase().includes(searchTerm) ||
        property.type?.toLowerCase().includes(searchTerm)
      );
    });
  }, [projectProperties, propertySearch]);

  const filteredManpower = useMemo(() => {
    const searchTerm = searchQuery.trim().toLowerCase();
    if (!searchTerm) return flattenedManpower;
    return flattenedManpower.filter((person) => {
      if (!person) return false;
      const fieldsToSearch = [
        person.name?.toLowerCase(),
        person.number?.toString().toLowerCase() || "",
        person.work_type?.toLowerCase() || "",
        person.status?.toLowerCase() || "",
        person.type?.toLowerCase() || "",
        person.employee_id?.toLowerCase() || "",
      ];
      return fieldsToSearch.some((field) => field.includes(searchTerm));
    });
  }, [searchQuery, flattenedManpower]);

  const sortedManpower = useMemo(() => {
    if (!sortConfig.key) return filteredManpower;
    return [...filteredManpower].sort((a, b) => {
      const aValue = a?.[sortConfig.key] ?? "";
      const bValue = b?.[sortConfig.key] ?? "";
      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }
      return sortConfig.direction === "asc"
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  }, [filteredManpower, sortConfig]);
  const parentRows = useMemo(() => {
    return flattenedManpower.filter(person => 
      person.type === "CONTRACTOR" || (person.type === "LABOR" && !person.parentId)
    );
  }, [flattenedManpower]);
  
  const paginatedManpower = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return parentRows.slice(start, end);
  }, [parentRows, currentPage, itemsPerPage]);
  

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  useEffect(() => {
    const fetchManpower = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://test.datso.io/labors-contractors"
        );
        if (!response.ok) throw new Error("Failed to fetch manpower data");
        const data = await response.json();
        const processedData = (Array.isArray(data) ? data : [])
          .map((person) => {
            if (!person) return null;
            if (person.type === "CONTRACTOR") {
              const contractorId = String(person.id).padStart(4, "");
              const laborsWithIds = (person.labors || []).map((labor) => ({
                ...labor,
                employee_id: String(labor.id).padStart(4, ""),
              }));
              return {
                ...person,
                employee_id: contractorId,
                labors: laborsWithIds,
              };
            } else {
              return {
                ...person,
                employee_id: String(person.id).padStart(4, ""),
              };
            }
          })
          .filter(Boolean);
        setManpower(processedData);
        const laborIds = processedData
          .filter((p) => p?.type === "LABOR")
          .map((p) => parseInt(p.id))
          .filter(Number.isInteger);
        const contractorIds = processedData
          .filter((p) => p?.type === "CONTRACTOR")
          .map((p) => parseInt(p.id))
          .filter(Number.isInteger);
        setLastEmployeeId({
          labor: laborIds.length > 0 ? Math.max(...laborIds) : 0,
          contractor: contractorIds.length > 0 ? Math.max(...contractorIds) : 0,
        });
      } catch (err) {
        setError(err.message || "An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };
    fetchManpower();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch("https://test.datso.io/projects_m");
        if (!response.ok) throw new Error("Failed to fetch projects data");
        const data = await response.json();
        let projectList = [];
        if (Array.isArray(data)) {
          projectList = data
            .map((project) => ({
              project_id: project?.project_id,
              project_name: project?.project_name,
            }))
            .filter((p) => p.project_id && p.project_name);
        } else if (data && Array.isArray(data.projects)) {
          projectList = data.projects
            .map((project) => ({
              project_id: project?.project_id,
              project_name: project?.project_name,
            }))
            .filter((p) => p.project_id && p.project_name);
        }
        setProjects(projectList);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError(err.message || "An unexpected error occurred");
      }
    };
    fetchProjects();
  }, []);

  const handleToggleExpand = (id) => {
    if (!id) return;
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handlePersonClick = async (person) => {
    if (!person?.id) {
      console.error("Invalid person data, cannot open modal.");
      return;
    }
    try {
      const apiUrl =
        person.type === "CONTRACTOR"
          ? `https://test.datso.io/contractors/${person.id}`
          : `https://test.datso.io/labors/${person.id}`;
      const response = await fetch(apiUrl);
      if (!response.ok)
        throw new Error(`Failed to fetch ${person.type.toLowerCase()} details`);
      const data = await response.json();
      setSelectedPerson({ ...person, ...data });
      setOpenModal(true);
    } catch (err) {
      console.error("Fetch Error:", err);
      setError(err.message || "An error occurred");
    }
  };

  const handleOpenDialog = (type) => {
    setFormType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFormType(null);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(Math.max(0, newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(Math.max(1, parseInt(event.target.value, 10)));
    setCurrentPage(0);
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleOpenAssignDialog = () => {
    setOpenAssignDialog(true);
    setSelectedProject("");
    setSelectedProperty("");
    setSelectedContractors([]);
    setSelectedLabors([]);
    setProjectProperties([]);
    setContractorSearch("");
    setLaborSearch("");
    setProjectSearch("");
    setPropertySearch("");
  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };

  const handleProjectChange = async (event, value) => {
    const project = value;
    if (!project) {
      setSelectedProject("");
      setSelectedProperty("");
      setProjectProperties([]);
      return;
    }

    setSelectedProject(project.project_id);
    setSelectedProperty("");
    setProjectProperties([]);
    try {
      const response = await fetch(
        `https://test.datso.io/projects_m/${project.project_id}/properties`
      );
      if (!response.ok) throw new Error("Failed to fetch project properties");
      const data = await response.json();
      const properties = Array.isArray(data.properties) ? data.properties : [];
      setProjectProperties(properties);
    } catch (err) {
      console.error("Error fetching project properties:", err);
      setError(err.message || "An error occurred while fetching properties");
    }
  };

  const handlePropertyChange = (event, value) => {
    const property = value;
    setSelectedProperty(property ? property.propertyid : "");
  };

  const handleContractorChange = (event, value) => {
    setSelectedContractors(value.filter((item) => item?.id && item?.name));
  };

  const handleLaborChange = (event, value) => {
    setSelectedLabors(value.filter((item) => item?.id && item?.name));
  };

  const handleAssignSubmit = async () => {
    // Ensure both project and property are selected.
    if (!selectedProject || !selectedProperty) {
      alert("Please select both project and property");
      return;
    }
  
    // Prepare the payload using only the necessary fields.
    // (Assuming that each contractor and labor object has an "id" or "employee_id" field.)
    const payload = {
      projectId: selectedProject, // already a project id from your project list
      propertyId: selectedProperty, // already a property id from your properties list
      contractorIds: selectedContractors.map((item) => item.employee_id || item.id),
      laborIds: selectedLabors.map((item) => item.employee_id || item.id),
    };
  
    try {
      const response = await fetch("https://test.datso.io/manpower/assignments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to create assignments.");
      }
  
      const data = await response.json();
      console.log("Assignments created", data);
      alert("Assignments created successfully!");
      // Optionally update your UI, e.g., by refreshing the assignments list.
      handleCloseAssignDialog();
    } catch (error) {
      console.error("Error creating assignments:", error);
      alert("Error creating assignments: " + error.message);
    }
  };
  

  const handleIdClick = (event, person) => {
    event.stopPropagation();
    setSelectedId(person.id);
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      type: person.type,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const handleEditClick = () => {
    if (!selectedId) return;
    const person = flattenedManpower.find((p) => p.id === selectedId);
    if (!person) return;

    handleContextMenuClose();
    handlePersonClick(person);
  };

  return (
    <Box
      sx={{
        padding: 0,
        maxWidth: "100%",
        margin: "0",
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        marginTop: "-4%",
        ml: "1%",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "black", ml: "1%" }}
        >
        </Typography>
        <Box sx={{ marginTop: "1.1%", marginLeft: "2%", marginRight: "2%" }}>
          <TextField
            variant="outlined"
            placeholder="Search Labor & Contractor"
            size="small"
            sx={{
              marginRight: 2,
              backgroundColor: "#fffff",
              width: 300,
              borderRadius: "25px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px",
                height: "36px",
                padding: "0",
                "& input": {
                  padding: "8px 12px",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a3663",
              color: "white",
              marginRight: 2,
              minWidth: "120px",
            }}
            onClick={() => handleOpenDialog("labor")}
          >
            Add Labor
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a3663",
              color: "white",
              marginRight: 2,
              minWidth: "120px",
            }}
            onClick={() => handleOpenDialog("contractor")}
          >
            Add Contractor
          </Button>
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a3663",
              color: "white",
              minWidth: "120px",
            }}
            onClick={handleOpenAssignDialog}
          >
            Assign Projects
          </Button> */}
        </Box>
      </Box>
      {/* Dialog for Forms */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          {formType === "labor" ? (
            <LaborOnboardingForm
              onClose={handleCloseDialog}
              lastId={lastEmployeeId.labor}
            />
          ) : (
            <ContractorOnboardingForm
              onClose={handleCloseDialog}
              lastId={lastEmployeeId.contractor}
            />
          )}
        </DialogContent>
      </Dialog>
      {/* Assignment Dialog */}
      <Dialog
        open={openAssignDialog}
        onClose={handleCloseAssignDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            position: "relative",
            px: 3,
            py: 2,
            height: "60px",
          }}
        >
          {/* Centered Title */}
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              color: "#2a3663",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Assign Manpower To Project
          </Typography>

          {/* Close Button */}
          <IconButton
            onClick={handleCloseAssignDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#333",
              fontSize: "1.75rem", // increases icon size
            }}
          >
            <CloseIcon sx={{ fontSize: "1.75rem" }} />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Project */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={filteredProjects}
                  getOptionLabel={(option) => option.project_name || ""}
                  value={
                    projects.find((p) => p.project_id === selectedProject) ||
                    null
                  }
                  onChange={handleProjectChange}
                  onInputChange={(e, val) => setProjectSearch(val)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project"
                      placeholder="Search projects..."
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        padding: "12px 16px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // ✅ Left align text
                        borderBottom: "1px solid #f5f5f5",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "15px",
                          color: "#111",
                        }}
                      >
                        {option.project_name}
                      </span>
                      <span style={{ fontSize: "13px", color: "#666" }}>
                        {option.project_id}
                      </span>
                    </li>
                  )}
                  PaperComponent={(props) => (
                    <Paper {...props} sx={{ mt: 1, borderRadius: 2 }} />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Property */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={filteredProperties}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    projectProperties.find(
                      (p) => p.propertyid === selectedProperty
                    ) || null
                  }
                  onChange={handlePropertyChange}
                  onInputChange={(e, val) => setPropertySearch(val)}
                  disabled={!selectedProject || projectProperties.length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Property"
                      placeholder="Search properties..."
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        padding: "12px 16px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // ✅ Left align text
                        borderBottom: "1px solid #f5f5f5",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "15px",
                          color: "#111",
                        }}
                      >
                        {option.name}
                      </span>
                      <span style={{ fontSize: "13px", color: "#666" }}>
                        {option.propertyid}
                      </span>
                    </li>
                  )}
                  PaperComponent={(props) => (
                    <Paper {...props} sx={{ mt: 1, borderRadius: 2 }} />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Contractors */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={filteredContractors}
                  getOptionLabel={(option) => option.name || "Unknown"}
                  value={selectedContractors}
                  onChange={handleContractorChange}
                  onInputChange={(e, val) => setContractorSearch(val)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contractors"
                      placeholder="Search contractors..."
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 12,
                      }}
                    >
                      <Checkbox checked={selected} sx={{ mr: 1 }} />
                      <div
                        style={{
                          backgroundColor: "#ccc",
                          borderRadius: "50%",
                          width: 36,
                          height: 36,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          color: "#fff",
                          marginRight: 16,
                          fontSize: 14,
                        }}
                      >
                        {option.name?.[0]?.toUpperCase() || "C"}
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>{option.name}</div>
                        <div style={{ fontSize: 12, color: "#555" }}>
                          {option.employee_id || "N/A"}
                        </div>
                      </div>
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name || "Unknown"}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  PaperComponent={(props) => (
                    <Paper {...props} sx={{ mt: 1, borderRadius: 2 }} />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Labors */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={filteredLabors}
                  getOptionLabel={(option) => option.name || "Unknown"}
                  value={selectedLabors}
                  onChange={handleLaborChange}
                  onInputChange={(e, val) => setLaborSearch(val)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Labors"
                      placeholder="Search labors..."
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 12,
                      }}
                    >
                      <Checkbox checked={selected} sx={{ mr: 1 }} />
                      <div
                        style={{
                          backgroundColor: "#ccc",
                          borderRadius: "50%",
                          width: 36,
                          height: 36,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          color: "#fff",
                          marginRight: 16,
                          fontSize: 14,
                        }}
                      >
                        {option.name?.[0]?.toUpperCase() || "L"}
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>{option.name}</div>
                        <div style={{ fontSize: 12, color: "#555" }}>
                          {option.employee_id || "N/A"}
                        </div>
                      </div>
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name || "Unknown"}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  PaperComponent={(props) => (
                    <Paper {...props} sx={{ mt: 1, borderRadius: 2 }} />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            py: 2,
            justifyContent: "center", // Center buttons horizontally
            gap: 2, // Optional: adds spacing between buttons
          }}
        >
          <Button onClick={handleCloseAssignDialog} variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={handleAssignSubmit}
            variant="contained"
            disabled={
              !selectedProject ||
              !selectedProperty ||
              (selectedContractors.length === 0 && selectedLabors.length === 0)
            }
            color="primary"
          >
            Assign ({selectedContractors.length + selectedLabors.length}{" "}
            selected)
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Table */}
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "2px", overflowX: "auto" }}
      >
        <Table sx={{ tableLayout: "fixed", width: "100%" }}>
          <TableHead sx={{ backgroundColor: "#2A3663" }}>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("employee_id")}
              >
                Employee ID{" "}
                {sortConfig.key === "employee_id"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("name")}
              >
                Employee{" "}
                {sortConfig.key === "name"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("work_type")}
              >
                Work Type{" "}
                {sortConfig.key === "work_type"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("number")}
              >
                Phone{" "}
                {sortConfig.key === "number"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("type")}
              >
                Type{" "}
                {sortConfig.key === "type"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("verified")}
              >
                Verified{" "}
                {sortConfig.key === "verified"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white", cursor: "pointer" }}
                onClick={() => handleSort("status")}
              >
                Status{" "}
                {sortConfig.key === "status"
                  ? sortConfig.direction === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Error: {error}
                </TableCell>
              </TableRow>
            ) : paginatedManpower.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No results found
                </TableCell>
              </TableRow>
            ) : (
              paginatedManpower.map((person) => (
                <React.Fragment key={person?.id}>
                  {person?.type === "CONTRACTOR" && (
                    <>
                      <TableRow>
                       
                        <TableCell
                          sx={{
                            cursor: "pointer",
                            color: "#1565C0",
                            "&:hover": { color: "" },
                            textDecoration: "underline", cursor: "pointer", color: "#1565C0", fontWeight: "" 
                          }}
                          onClick={() => handlePersonClick(person)}
                        >
                          {person.labors?.length > 0 && (
                            <IconButton
                              sx={{ padding: 0, marginRight: 1 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleToggleExpand(person.id);
                              }}
                            >
                              {expandedRows[person.id] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          )}
                          {person.id}
                        </TableCell>
                        <TableCell
                          // sx={{ fontWeight: "", cursor: "pointer" }}
                          onClick={(e) => handleIdClick(e, person)}
                        >
                          {person.name}
                        </TableCell>
                        <TableCell>{person.work_type || "N/A"}</TableCell>
                        <TableCell>{person.number || "N/A"}</TableCell>
                        <TableCell>CONTRACTOR</TableCell>
                        <TableCell>{person.verified || "N/A"}</TableCell>
                        <TableCell>
                          <Chip
                            label={(person.status || "INACTIVE").toUpperCase()}
                            sx={{
                              backgroundColor: (() => {
                                const status = person.status?.toUpperCase();
                                if (status === "ACTIVE") return "#C8E6C9"; // Light green
                                if (status === "COMPLETED") return "#FFE0B2"; // Light orange
                                if (status === "PENDING") return "#FFF9C4"; // Light yellow
                                if (status === "INACTIVE") return "#FFCDD2"; // Light red
                                return "#E0E0E0"; // Default gray
                              })(),
                              color: (() => {
                                const status = person.status?.toUpperCase();
                                if (status === "ACTIVE") return "#2E7D32"; // Green text
                                if (status === "COMPLETED") return "#EF6C00"; // Orange text
                                if (status === "PENDING") return "#F9A825"; // Yellow text
                                if (status === "INACTIVE") return "#C62828"; // Red text
                                return "#555"; // Default gray text
                              })(),
                              border: (() => {
                                const status = person.status?.toUpperCase();
                                if (status === "ACTIVE")
                                  return "2px solid #2E7D32";
                                if (status === "COMPLETED")
                                  return "2px solid #EF6C00";
                                if (status === "PENDING")
                                  return "2px solid #F9A825";
                                if (status === "INACTIVE")
                                  return "2px solid #C62828";
                                return "2px solid #BDBDBD";
                              })(),
                              fontWeight: "bold",
                              borderRadius: "4px",
                              padding: "4px 12px",
                              width: "150px",
                              textAlign: "center",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      {expandedRows[person.id] &&
                        person.labors?.map((labor) => (
                          <TableRow key={labor?.id}>
                          
                            <TableCell
                              sx={{
                                cursor: "pointer",
                                color: "#1565C0",
                                "&:hover": { color: "" },
                                textDecoration: "underline", cursor: "pointer", color: "#1565C0", fontWeight: "" 
                              }}
                              onClick={() => handlePersonClick(labor)}
                            >
                              <SubdirectoryArrowRightIcon
                                sx={{ color: "gray", marginRight: 1 }}
                              />
                              {labor?.id}
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "", cursor: "" }}
                             
                            >
                              {labor?.name}
                            </TableCell>
                            <TableCell>{labor?.work_type || "-"}</TableCell>
                            <TableCell>{labor?.number || "-"}</TableCell>
                            <TableCell>LABOR</TableCell>
                            <TableCell>{labor?.verified || "N/A"}</TableCell>
                            <TableCell>
                              <Chip
                                label={(
                                  labor?.status || "INACTIVE"
                                ).toUpperCase()}
                                sx={{
                                  backgroundColor: (() => {
                                    const status = labor?.status?.toUpperCase();
                                    if (status === "ACTIVE") return "#C8E6C9"; // Light green
                                    if (status === "COMPLETED")
                                      return "#FFE0B2"; // Light orange
                                    if (status === "PENDING")
                                      return "#FFF9C4"; // Light yellow
                                    return "#FFCDD2"; // Light red (INACTIVE)
                                  })(),
                                  color: (() => {
                                    const status = labor?.status?.toUpperCase();
                                    if (status === "ACTIVE") return "#2E7D32"; // Green text
                                    if (status === "COMPLETED")
                                      return "#EF6C00"; // Orange text
                                    if (status === "PENDING")
                                      return "#F9A825"; // Yellow text
                                    return "#C62828"; // Red text
                                  })(),
                                  border: (() => {
                                    const status = labor?.status?.toUpperCase();
                                    if (status === "ACTIVE")
                                      return "2px solid #2E7D32";
                                    if (status === "COMPLETED")
                                      return "2px solid #EF6C00";
                                    if (status === "PENDING")
                                      return "2px solid #F9A825";
                                    return "2px solid #C62828";
                                  })(),
                                  fontWeight: "bold",
                                  borderRadius: "4px",
                                  padding: "4px 12px",
                                  width: "150px",
                                  textAlign: "center",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                  {person?.type === "LABOR" && !person?.parentId && (
                    <TableRow>
                    
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          color: "#1565C0",
                          "&:hover": { color: "" },
                          textDecoration: "underline", cursor: "pointer", color: "#1565C0", fontWeight: "" 
                        }}
                        onClick={() => handlePersonClick(person)}
                      >
                        {person.employee_id}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "", cursor: "" }}
                        
                      >
                        {person.name}
                      </TableCell>
                      <TableCell>{person.work_type || "-"}</TableCell>
                      <TableCell>{person.number || "-"}</TableCell>
                      <TableCell>LABOR</TableCell>
                      <TableCell>{person.verified || "N/A"}</TableCell>
                      <TableCell>
                        <Chip
                          label={(person.status || "INACTIVE").toUpperCase()}
                          sx={{
                            backgroundColor: (() => {
                              const status = person.status?.toUpperCase();
                              if (status === "ACTIVE") return "#C8E6C9"; // Light green
                              if (status === "COMPLETED") return "#FFE0B2"; // Light orange
                              if (status === "PENDING") return "#FFF9C4"; // Light yellow
                              return "#FFCDD2"; // Light red (INACTIVE)
                            })(),
                            color: (() => {
                              const status = person.status?.toUpperCase();
                              if (status === "ACTIVE") return "#2E7D32"; // Green text
                              if (status === "COMPLETED") return "#EF6C00"; // Orange text
                              if (status === "PENDING") return "#F9A825"; // Yellow text
                              return "#C62828"; // Red text
                            })(),
                            border: (() => {
                              const status = person.status?.toUpperCase();
                              if (status === "ACTIVE")
                                return "2px solid #2E7D32";
                              if (status === "COMPLETED")
                                return "2px solid #EF6C00";
                              if (status === "PENDING")
                                return "2px solid #F9A825";
                              return "2px solid #C62828";
                            })(),
                            fontWeight: "bold",
                            borderRadius: "4px",
                            padding: "4px 12px",
                            width: "150px",
                            textAlign: "center",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredManpower.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </TableContainer>

      {/* Context Menu */}
      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleEditClick}>
          Edit {contextMenu?.type?.toLowerCase()}
        </MenuItem>
      </Menu>

      {selectedPerson && (
        <ManpowerDetail
          open={openModal}
          onClose={() => setOpenModal(false)}
          id={selectedPerson.id}
          type={selectedPerson.type}
        />
      )}
    </Box>
  );
};

export default Manpower;
