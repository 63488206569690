import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  MenuItem,
  IconButton,
  CircularProgress,
  Stack,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
const statuses = ["Planning", "In Progress", "Completed"];
const projectTypes = ["Residential", "Commercial", "Industrial", "Mixed Use"];
const currencies = ["Rupee", "Dollar"];
const ProjectForm = ({ onCancel, onClose }) => {
  const initialFormData = {
    project_name: "",
    project_type: "",
    project_location_city: "",
    project_location_state: "",
    project_location_gps: "",
    start_date: "",
    project_status: "Planning",
    total_budget: "",
    current_currency: "Rupee",
    project_manager: "",
    dimensions: "",
    documents: [],
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const handleChange = (field, value) => {
    setFormData((prev) => {
      return { ...prev, [field]: value };
    });
  };
  const handleCancel = () => {
    setFormData({ ...initialFormData });
    setSelectedFiles([]);
    if (onCancel) {
      onCancel();
    } else if (onClose) {
      onClose();
    }
  };
  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "documents" && formData[key].length > 0) {
          formData[key].forEach((file) => formDataToSend.append("documents", file));
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });
  
      console.log("🔹 Sending data:", Object.fromEntries(formDataToSend));
  
      const response = await fetch("https://test.datso.io/projects_m", {
        method: "POST",
        body: formDataToSend,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to save project: ${response.statusText}`);
      }
  
      setDialogMessage("Project details saved successfully!");
      setDialogOpen(true);
    } catch (error) {
      console.error("Error saving project:", error);
      setDialogMessage("Failed to save project details. Please try again.");
      setDialogOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleDialogClose = () => {
    setDialogOpen(false);
    handleCancel(); // Now reset the form **after** dialog closes
  };
  
  return (
    <Container maxWidth="md">
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#555",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 2, marginTop: 5, fontWeight: "500" }}>
        Add Project
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />
      <Grid container spacing={3}>
        {[
          { label: "Project Name *", field: "project_name", type: "text" },
          { label: "City *", field: "project_location_city", type: "text" },
          { label: "State *", field: "project_location_state", type: "text" },
          { label: "GPS Location *", field: "project_location_gps", type: "text" },
          { label: "Total Budget *", field: "total_budget", type: "number" },
          { label: "Project Manager/Owner *", field: "project_manager", type: "text" },
          { label: "Dimensions of Project", field: "dimensions", type: "text" },
        ].map(({ label, field, type }) => (
          <Grid item xs={12} sm={4} key={field}>
            <TextField
              label={label}
              type={type}
              value={formData[field]}
              onChange={(e) => handleChange(field, e.target.value)}
              fullWidth
              required={label.includes("*")}
            />
          </Grid>
        ))}
        {/* Dropdowns */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Project Type *"
            select
            value={formData.project_type}
            onChange={(e) => handleChange("project_type", e.target.value)}
            fullWidth
            required
          >
            {projectTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Project Status *"
            select
            value={formData.project_status}
            onChange={(e) => handleChange("project_status", e.target.value)}
            fullWidth
            required
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Currency *"
            select
            value={formData.current_currency}
            onChange={(e) => handleChange("current_currency", e.target.value)}
            fullWidth
            required
          >
            {currencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* Start Date */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.start_date}
            onChange={(e) => handleChange("start_date", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
  <Button
    component="label"
    fullWidth
    sx={{ border: "1px solid black" }}
    startIcon={<UploadFileIcon />}
  >
    Upload Documents
    <input
      type="file"
      hidden
      multiple
      onChange={(e) => {
        const newFiles = Array.from(e.target.files); // Get newly selected files
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append to existing files
        setFormData((prev) => ({
          ...prev,
          documents: [...prev.documents, ...newFiles],
        }));
      }}
    />
  </Button>
  {/* Display selected file names with remove option */}
  {selectedFiles.length > 0 && (
    <Box sx={{ marginTop: 1 }}>
      {selectedFiles.map((file, index) => (
        <Box key={index} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">{file.name}</Typography>
          <IconButton
            onClick={() => {
              // Remove selected file without clearing all files
              setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
              setFormData((prev) => ({
                ...prev,
                documents: prev.documents.filter((_, i) => i !== index),
              }));
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  )}
</Grid>
        {/* Action Buttons */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center", marginBottom: 5 }}>
            <Button variant="outlined" color="secondary" onClick={handleCancel} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} /> : "Save Details"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {/* Custom Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Project Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default ProjectForm;