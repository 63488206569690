import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Paper,
  Alert,
  Link,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ContractorOnboardingForm = ({ existingData, onCancel, onClose }) => {
  const [contractorDetails, setContractorDetails] = useState({
    contractor_name: "",
    contractor_phone: "",
    contract_type: "",
    contract_assigned_project: "",
    contract_payment_type: "",
    aadhaar_file: null,
    pancard_file: null,
    contractor_bond_file: null,
    aadhaar_file_url: "",
    pancard_file_url: "",
    contractor_bond_file_url: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("success");
  // Prefill the form when editing
  useEffect(() => {
    if (existingData) {
      setContractorDetails((prev) => ({
        ...prev,
        contractor_name: existingData.contractor_name || existingData.name || "", // Fix missing name
        contractor_phone: existingData.contractor_phone || existingData.number || "",
        contract_type: existingData.contract_type || "",
        contract_assigned_project: existingData.contract_assigned_project || "",
        contract_payment_type: existingData.contract_payment_type || "",
        aadhaar_file_url: existingData.aadhaar_file_url || "",
        pancard_file_url: existingData.pancard_file_url || "",
        contractor_bond_file_url: existingData.contractor_bond_file_url || "",
        aadhaar_file: null,
        pancard_file: null,
        contractor_bond_file: null, // Reset files for fresh upload
      }));
    }
  }, [existingData]);

  const handleChange = (field, value) => {
    setContractorDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (field, file) => {
    setContractorDetails((prev) => ({
      ...prev,
      [field]: file,
    }));
  };

  const handleSubmit = async () => {
    if (!contractorDetails.contractor_name || !contractorDetails.contractor_phone) {
      setDialogMessage("Please fill in all required fields.");
      setDialogType("error");
      setDialogOpen(true); return;
    }

    try {
      const url = existingData
        ? `https://test.datso.io/contractors/${existingData.contractor_id}` // Update existing contractor
        : "https://test.datso.io/contractors"; // Create new contractor
      const method = existingData ? "PUT" : "POST";

      const formData = new FormData();
      Object.keys(contractorDetails).forEach((key) => {
        if (contractorDetails[key]) {
          formData.append(key, contractorDetails[key]);
        }
      });

      const response = await fetch(url, {
        method,
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to save contractor details.");
      }

      setDialogMessage("contractor details saved successfully.");
      setDialogType("success");
      setDialogOpen(true);
      if (onCancel) onCancel(); // Close form after saving
    } catch (error) {
      console.error("Error saving contractor:", error);
      setDialogMessage("Error saving contractor. Please try again.");
      setDialogType("error");
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (dialogType === "success") {
      if (onClose) onClose(); // Close the form if onClose is provided
      else if (onCancel) onCancel(); // Otherwise, close using onCancel
    }
  };
  

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 4,
        maxWidth: "800px",
        borderRadius: 3,
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#555",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ textAlign: "center", marginBottom: 3 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "400",
            color: "#34495e",
            marginBottom: "8px",
          }}
        >
          {existingData ? "Edit Contractor" : "Add Contractor"}
        </Typography>
        {/* <Typography variant="subtitle1" sx={{ color: "#7f8c8d" }}>
          {existingData ? "Update contractor details" : "Fill out the form to onboard a contractor."}
        </Typography> */}
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: 3 }}>
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Contractor Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contractor Name"
            value={contractorDetails.contractor_name}
            onChange={(e) => handleChange("contractor_name", e.target.value)}
            fullWidth
            required
            sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}
          />
        </Grid>

        {/* Contractor Phone */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contractor Phone"
            value={contractorDetails.contractor_phone}
            onChange={(e) => handleChange("contractor_phone", e.target.value)}
            fullWidth
            required
            sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}
          />
        </Grid>

        {/* Contract Type Dropdown */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Contract Type</InputLabel>
            <Select
              value={contractorDetails.contract_type || ""}
              onChange={(e) => handleChange("contract_type", e.target.value)}
              sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}
            >
              <MenuItem value="Electrical">Electrical</MenuItem>
              <MenuItem value="Plumbing">Plumbing</MenuItem>
              <MenuItem value="Masonry">Masonry</MenuItem>
              <MenuItem value="Painting">Painting</MenuItem>
              <MenuItem value="Carpentry">Carpentry</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Assigned Project */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Assigned Project (Optional)"
            value={contractorDetails.contract_assigned_project || ""}
            onChange={(e) => handleChange("contract_assigned_project", e.target.value)}
            fullWidth
            sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}
          />
        </Grid>

        {/* Payment Type Dropdown */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Payment Type</InputLabel>
            <Select
              value={contractorDetails.contract_payment_type || ""}
              onChange={(e) => handleChange("contract_payment_type", e.target.value)}
              sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}
            >
              <MenuItem value="Hourly">Hourly</MenuItem>
              <MenuItem value="Per Square Foot">Per Square Foot</MenuItem>
              <MenuItem value="Project-based">Project-based</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* File Uploads */}
        {["aadhaar_file", "pancard_file", "contractor_bond_file"].map((fileType, index) => (
  <Grid item xs={12} sm={6} key={index}>
    <Typography>
      {fileType.replace("_", " ").replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())}
    </Typography>
    {contractorDetails[`${fileType}_url`] && (
      <Link
        href={contractorDetails[`${fileType}_url`]}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: "block", marginTop: 1 }}
      >
        View File
      </Link>
    )}
    <Button
      variant="outlined"
      component="label"
      fullWidth
      sx={{
        marginTop: 1,
        borderColor: "#34495e",
        "&:hover": { backgroundColor: "#34495e", color: "#fff" },
      }}
    >
      Upload File (Optional)
      <input
        type="file"
        accept=".jpg,.jpeg,.png,.pdf"
        hidden
        onChange={(e) => handleFileChange(fileType, e.target.files[0])}
      />
    </Button>
    {contractorDetails[fileType] && (
      <Typography sx={{ marginTop: 1, fontSize: "0.875rem", color: "#34495e" }}>
        Selected File: {contractorDetails[fileType].name}
      </Typography>
    )}
  </Grid>
))}

      </Grid>

      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ width: "45%" }}>
          Save
        </Button>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{dialogType === "success" ? "Success" : "Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default ContractorOnboardingForm;