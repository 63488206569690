import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  CardActions
} from "@mui/material";
import {
  Search as SearchIcon,
  AttachFile,
  Close,
  Flag,
} from "@mui/icons-material";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Snackbar } from "@mui/material";



const statusColors = {
  "In Progress": "#007bff",
  "Completed": "#8BC34A",
  "Pending": "#ffc107",
  "Hold": "#6c757d",
};

const lightStatusColors = {
  "In Progress": "#cce5ff",
  "Completed": "#d4edda",
  "Pending": "#fff3cd",
  "Hold": "#e2e3e5",
};

const TaskManagerCombined = ({ propertyId, initialTask = null,refreshTasks }) => {

  // State for table view
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  
  // State for detailed view
  const [selectedTask, setSelectedTask] = useState(initialTask || null);

  const [status, setStatus] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [initialValues, setInitialValues] = useState({ startdate: "", enddate: "", status: "" });
  const [taskId, setTaskId] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [newUpdateText, setNewUpdateText] = useState("");
  const [newUpdateFiles, setNewUpdateFiles] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [activeTab, setActiveTab] = useState("edit");
  const [materials, setMaterials] = useState([]);

  const emp_name = localStorage.getItem("first_name") || "Unknown Engineer";
  const [dialogOpen, setDialogOpen] = useState(false);
const [dialogMessage, setDialogMessage] = useState("");
const [dialogSeverity, setDialogSeverity] = useState("success");

const [manualEndDate, setManualEndDate] = useState(false);


  // Fetch tasks for the table view
  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://test.datso.io/properties/${propertyId}/schedule`);
      const data = response.data.schedule;
      setTasks(Array.isArray(data) ? data : []);
    } catch (err) {
      console.error("Error fetching tasks:", err);
      setTasks([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchTasks();
    }
  }, [propertyId]);
  useEffect(() => {
    if (snackbar.open && snackbar.severity === "success") {
      fetchTasks(); // ✅ Refresh task list when update is successful
    }
  }, [snackbar.open]);
  

  // Filter tasks for table view
  const filteredTasks = tasks.filter((task) => {
    let statusMatch = filterStatus ? task.status === filterStatus : true;
    let searchMatch = searchQuery
      ? task.phasename.toLowerCase().includes(searchQuery.toLowerCase())
      : true;
    return statusMatch && searchMatch;
  });
  useEffect(() => {
    if (selectedTask?.scheduleid) {
      axios.get(`https://test.datso.io/get-task-id/${selectedTask.scheduleid}`)
        .then(response => setTaskId(response.data.task_id))
        .catch(error => console.error("Error fetching task_id:", error));
    }
  }, [selectedTask]);
  
  // Modify your start date onChange handler
const handleStartDateChange = (e) => {
  const newStartDate = e.target.value;
  // Calculate the difference (in milliseconds) between the previous start and end dates
  const prevStart = new Date(initialValues.startdate);
  const prevEnd = new Date(initialValues.enddate);
  const diff = prevEnd - prevStart; // difference in milliseconds
  
  // Compute the new end date based on the new start date plus the same difference
  const newEndDate = new Date(new Date(newStartDate).getTime() + diff)
                          .toISOString()
                          .split("T")[0];

  // Update state: set the new start date and, if the user hasn't manually modified the end date, update it automatically
  setStartdate(newStartDate);
  if (!manualEndDate) {
    setEnddate(newEndDate);
  }
};
// In the end date onChange handler, mark that the user has manually updated the end date.
const handleEndDateChange = (e) => {
  setEnddate(e.target.value);
  setManualEndDate(true);
};
  // When a task is selected from the table
  useEffect(() => {
    if (selectedTask && selectedTask.scheduleid) {
      console.log("Updating Task Details:", selectedTask); // Debugging
      const formattedStart = formatDate(selectedTask.startdate || "");
      const formattedEnd = formatDate(selectedTask.enddate || "");
      
      setStartdate(formattedStart);
      setEnddate(formattedEnd);
      setStatus(selectedTask.status || "Pending");
  
      setInitialValues({
        startdate: formattedStart,
        enddate: formattedEnd,
        status: selectedTask.status || "Pending",
      });
  
      // Fetch task ID if not available
      if (!selectedTask.task_id) {
        axios
          .get(`https://test.datso.io/get-task-id/${selectedTask.scheduleid}`)
          .then(response => setTaskId(response.data.task_id))
          .catch(error => console.error("Error fetching task_id:", error));
      } else {
        setTaskId(selectedTask.task_id);
      }
  
      fetchTaskUpdates(selectedTask.scheduleid);
    }
  }, [selectedTask]);
  

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const fetchTaskUpdates = async (scheduleid) => {
    try {
      const response = await axios.get(`https://test.datso.io/schedule_update/${scheduleid}`);
      setUpdates(response.data.updates || []);
    } catch (error) {
      console.error("Error fetching updates:", error);
    }
  };
  useEffect(() => {
    if (initialTask && (!selectedTask || selectedTask.scheduleid !== initialTask.scheduleid)) {
      console.log("Setting Selected Task from initialTask:", initialTask); // Debugging
      setSelectedTask(initialTask);
    }
  }, [initialTask]);
  
  // Fetch materials when taskId is available
  useEffect(() => {
    if (taskId) {
      axios
        .get(`https://test.datso.io/materials/${taskId}`)
        .then(response => {
          setMaterials(response.data.materials || []);
        })
        .catch(error => {
          console.error("Error fetching materials:", error);
        });
    }
  }, [taskId]);
  useEffect(() => {
    fetchTasks();
  }, [snackbar.open]); // Re-fetch when Snackbar appears
  
  const hasChanges = () => {
    return (
      startdate !== initialValues.startdate ||
      enddate !== initialValues.enddate ||
      status !== initialValues.status
    );
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = async () => {
    setConfirmOpen(false);
    if (!selectedTask || !selectedTask.scheduleid) {
        console.error("Missing schedule ID in selected task:", selectedTask);
        setDialogMessage("Error: Missing schedule ID!");
        setDialogSeverity("error");
        setDialogOpen(true);
        setTimeout(() => setDialogOpen(false), 3000);
        return;
    }

    let completionTime = null;
    if (status === "Completed") {
        const kolkataTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
        completionTime = new Date(kolkataTime).toISOString();
    }

    try {
        const payload = {
            startdate,
            enddate,
            status,
            completionTime,
            task_id: taskId,
            applyToAll, // ✅ Use checkbox state
        };

        console.log("Updating Task with payload:", payload);

        const response = await axios.put(
            `https://test.datso.io/update-schedule/${selectedTask.scheduleid}`,
            payload
        );

        if (response.data.success || response.status === 200) {
            setDialogMessage("Task updated successfully!");
            setDialogSeverity("success");
            setDialogOpen(true);
            setTimeout(() => setDialogOpen(false), 3000);

            fetchTasks();
            refreshTasks();

            setSelectedTask((prevTask) => ({
                ...prevTask,
                status,
                startdate,
                enddate,
            }));
        } else {
            throw new Error("Failed to update task");
        }
    } catch (error) {
        console.error("Error updating schedule:", error);
        setDialogMessage("Error updating schedule");
        setDialogSeverity("error");
        setDialogOpen(true);
        setTimeout(() => setDialogOpen(false), 3000);
    }
};





  const handleSendUpdate = async () => {
    if (!newUpdateText.trim() && newUpdateFiles.length === 0) return;
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("property_id", selectedTask.property_id || "Unknown");
    formData.append("schedule_id", selectedTask.scheduleid);
    formData.append("engineer_name", emp_name);
    formData.append("update_text", newUpdateText);

    for (let file of newUpdateFiles) {
      formData.append("update_files", file);
    }

    try {
      await axios.post("https://test.datso.io/task-updates", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      fetchTaskUpdates(selectedTask.scheduleid);
      setNewUpdateText("");
      setNewUpdateFiles([]);
    } catch (error) {
      console.error("Error sending update:", error);
    }
  };

  const handleCombinedUpdate = async () => {
    let updateMade = false;
    if (hasChanges()) {
        try {
            await handleSave(); // ✅ No parameters needed
            updateMade = true;
        } catch (error) {
            console.error("Error updating schedule:", error);
        }
    }
    if (newUpdateText.trim() || newUpdateFiles.length > 0) {
        try {
            await handleSendUpdate();
            updateMade = true;
        } catch (error) {
            console.error("Error sending task update:", error);
        }
    }
    if (!updateMade) {
        console.log("No changes to update.");
    }
};


  const handleFileChange = (event) => {
    setNewUpdateFiles([...newUpdateFiles, ...event.target.files]);
  };

  const removeFile = (index) => {
    setNewUpdateFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Render the table view if no task is selected
  if (!selectedTask) {
    return (
      <Box width="30%" display="flex" flexDirection="column" gap={2} overflow="auto">
      <Typography variant="h5" fontWeight="bold">Schedule</Typography>
      <TextField
        fullWidth
        placeholder="Search tasks..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
      <Select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)} displayEmpty>
        <MenuItem value="">All Statuses</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Hold">Hold</MenuItem>
      </Select>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box display="flex" flexDirection="column" gap={2} overflow="auto">
          {filteredTasks.map((task) => (
            <Card
              key={task.scheduleid}
              sx={{ cursor: "pointer", backgroundColor: lightStatusColors[task.status] || "#fff" }}
              onClick={() => setSelectedTask(task)}
            >
              <CardContent>
                <Typography variant="h6" fontWeight="bold">{task.phasename}</Typography>
                <Typography>Start: {task.startdate}</Typography>
                <Typography>End: {task.enddate}</Typography>
                <Typography>Status: {task.status}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
    
    );
  }

  // Detailed view
  return (
      <Box display="flex" height="100vh" padding={2}>
          <Box flex={1} paddingRight={2}>
      <IconButton onClick={() => setSelectedTask(null)} sx={{ marginBottom: 2 }}>
  <ArrowBackIcon />
</IconButton>

        <Paper sx={{ padding: 4, borderRadius: "12px", backgroundColor: "#fff" }}>
          <Typography variant="h4" fontWeight="bold">
            Task - {selectedTask.phasename}
          </Typography>
          {/* <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            // variant="fullWidth"
          >
            <Tab label="" value="edit" />
          </Tabs> */}
          {activeTab === "edit" && (
            <>
<TextField
  label="Start Date"
  type="date"
  fullWidth
  variant="outlined"
  value={startdate}
  onChange={handleStartDateChange}
  sx={{ marginBottom: 2, marginTop: 2 }}
/>
<TextField
  label="End Date"
  type="date"
  fullWidth
  variant="outlined"
  value={enddate}
  onChange={handleEndDateChange}
  sx={{ marginBottom: 2 }}
/>


<Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
  <input
    type="checkbox"
    checked={applyToAll}
    onChange={(e) => setApplyToAll(e.target.checked)}
  />
  <Typography sx={{ marginLeft: 1 }}>Adjust all subsequent tasks</Typography>
</Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: 2 }}>
                <Flag sx={{ color: "#F39C12" }} />
                <Typography>Status:</Typography>
                <Select
                  value={status}
                  onChange={handleStatusChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Hold">Hold</MenuItem>
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F7F9FC",
                  borderRadius: "25px",
                  padding: "5px 10px",
                  marginTop: 2,
                }}
              >
                <TextField
                  placeholder="Type an update..."
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={4}
                  variant="outlined"
                  value={newUpdateText}
                  onChange={(e) => setNewUpdateText(e.target.value)}
                  sx={{ flex: 1, padding: "8px" }}
                />
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-upload"
                />
                <label htmlFor="file-upload">
                  <IconButton component="span">
                    <AttachFile />
                  </IconButton>
                  <Button variant="contained" color="primary" onClick={handleCombinedUpdate} sx={{backgroundColor: "#2a3663",
            color: "white",}}>
                  Update
                </Button>
                </label>
              </Box>
              {newUpdateFiles.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    paddingBottom: "10px",
                  }}
                >
                  {Array.from(newUpdateFiles).map((file, index) => (
                    <Paper
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        padding: "5px 10px",
                        borderRadius: "15px",
                        backgroundColor: "#E3F2FD",
                      }}
                    >
                      <AttachFile fontSize="small" sx={{ color: "#1976D2" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {file.name} ({(file.size / 1024).toFixed(2)} KB)
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => removeFile(index)}
                        sx={{ color: "red" }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                      
                    </Paper>
                  ))}
                </Box>
              )}
              <Box sx={{ marginTop: 2, textAlign: "right" }}>
               
              </Box>
              <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)} maxWidth="xs" fullWidth>
                <DialogTitle>Apply Changes to All Phases?</DialogTitle>
                <DialogContent>
                  <Typography>
                    Do you want to update only this phase or all future phases?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleSave(false)} color="primary" variant="outlined">
                    Only This Phase
                  </Button>
                  <Button onClick={() => handleSave(true)} color="secondary" variant="contained">
                    Apply to All
                  </Button>
                </DialogActions>
              </Dialog>
              <Typography variant="h6" sx={{ marginTop: 3, fontWeight: "bold" }}>
                Task Updates
              </Typography>
              <Box
                sx={{
                  height: { xs: "400px", sm: "450px", md: "500px", lg: "500px" },
                  overflowY: "auto",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                }}
              >
                {updates.length > 0 ? (
                  updates.map((update) => (
                    <Box
                      key={update.update_id}
                      sx={{
                        display: "flex",
                        justifyContent:
                          update.engineer_name === emp_name ? "flex-end" : "flex-start",
                      }}
                    >
                      <Paper
                        sx={{
                          padding: "10px 15px",
                          borderRadius: "15px",
                          backgroundColor:
                            update.engineer_name === emp_name ? "#DCF8C6" : "#FFF",
                          maxWidth: "75%",
                        }}
                      >
                        <Typography variant="caption" color="textSecondary">
                          {update.engineer_name} •{" "}
                          {new Date(update.created_at).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: "5px" }}>
                          {update.update_text}
                        </Typography>
                        {update.files && update.files.length > 0 && (
                          <Box sx={{ marginTop: 1 }}>
                            <Typography variant="caption" fontWeight="bold">
                              Attachments:
                            </Typography>
                            {update.files.map((file) => (
                              <Box
                                key={file.file_id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <AttachFile fontSize="small" />
                                <Typography
                                  variant="body2"
                                  component="a"
                                  href={file.file_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{ textDecoration: "none", color: "blue" }}
                                >
                                  {file.file_name} ({(file.file_size / 1024).toFixed(2)} KB)
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Paper>
                    </Box>
                  ))
                ) : (
                  <Typography color="textSecondary" align="center">
                    No updates yet.
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Paper>
      </Box>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
  <DialogTitle>{dialogSeverity === "success" ? "Success" : "Error"}</DialogTitle>
  <DialogContent>
    <Typography>{dialogMessage}</Typography>
  </DialogContent>
</Dialog>


      <Snackbar
  open={snackbar.open}
  autoHideDuration={3000}
  onClose={() => setSnackbar({ ...snackbar, open: false })}
  message={snackbar.message}
  severity={snackbar.severity}
/>
      
    </Box>
  );
};

export default TaskManagerCombined;
