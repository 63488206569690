import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Avatar, TextField, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ChatUpdates = ({ requestUpdates, selectedRequest }) => {
    // const [messages, setMessages] = useState([]);
    const [messages, setMessages] = useState({ related: [], others: [] }); // ✅ Always an object


    useEffect(() => {
      if (requestUpdates && selectedRequest) {
        const sortedUpdates = [...requestUpdates].sort((a, b) => {
          const statusOrder = {
            requested: 1,
            issued: 2,
            rejected: 3, // Other statuses last
          };
    
          return (statusOrder[a.status] || 4) - (statusOrder[b.status] || 4);
        });
    
        // ✅ Separate updates related to selectedRequest
        const relatedUpdates = sortedUpdates.filter(
          (update) => update.request_id === selectedRequest.request_id || update.p_req_id === selectedRequest.p_req_id
        );
        
        const otherUpdates = sortedUpdates.filter(
          (update) => update.request_id !== selectedRequest.request_id && update.p_req_id !== selectedRequest.p_req_id
        );
    
        setMessages({ related: relatedUpdates, others: otherUpdates });
      }
    }, [requestUpdates, selectedRequest]);
    
  
  
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          maxHeight: "100vh"
        }}
      >
        {/* ✅ Chat Messages Container */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight:"40%",
            background:"white",
            boxShadow:"black",
            borderRadius: 2, boxShadow: 1 
          }}
        >

          
          {(!messages || !messages.related.length) && (!messages || !messages.others.length) ? (
            <Typography sx={{ textAlign: "center", color: "#555" }}>No updates found.</Typography>
          ) : (
            <>
            <Box sx={{ padding: "16px 16px" }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}> Requests</Typography>
      </Box>
              {/* ✅ Messages related to the selected request (Right) */}
              {messages.related?.map((update, index) => (
                <Box
                  key={update.request_id}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start", // ✅ Align to Right
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Paper
                    sx={{
                      padding: "12px 16px",
                      borderRadius: "12px",
                      boxShadow: 2,
                      backgroundColor: "#DCF8C6", // ✅ Light green for related updates
                    }}
                  >
                                        <Typography fontSize="14px" fontWeight="bold">
                                           Requested BY - {update.engineer_id}
                                          </Typography>

                    <Typography fontSize="14px" fontWeight="bold">

                      {update.warehouse} - {update.request_id}
                    </Typography>

                    <Typography fontSize="14px">{update.requested_quantity} units requested</Typography>
                    <Typography
  fontSize="14px"
  fontWeight="bold"
  textTransform="uppercase"
  sx={{
    padding: "4px 8px",
    borderRadius: "8px",
    color: "#fff",
    minWidth: "20px", // ✅ Set a minimum width
    maxWidth: "105px", // ✅ Prevent it from becoming too long
    textAlign: "center",
    backgroundColor:
      update.status === "requested"
        ? "#FFC107" // Yellow for Requested
        : update.status === "issued"
        ? "#4CAF50" // Green for Issued
        : update.status === "rejected"
        ? "#F44336" // Red for Rejected
        : update.status === "delayed"
        ? "#D32F2F" // Dark Red for Delayed
        : "#607D8B", // Grey for other statuses
  }}
>
  {update.status}
</Typography>

                  </Paper>
                </Box>
              ))}
    
              {/* ✅ Other messages (Left) */}
              {messages.others?.map((update, index) => (
                <Box
                  key={update.request_id}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start", // ✅ Align to Left
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Paper
                    sx={{
                      padding: "12px 16px",
                      borderRadius: "12px",
                      boxShadow: 2,
                      backgroundColor: "#F9F9F9", // ✅ Light grey for other updates
                    }}
                  >
                     <Typography fontSize="14px" fontWeight="bold">
                     Requested BY - {update.engineer_id}
                     </Typography>
                    <Typography fontSize="14px" fontWeight="bold">
                      {update.warehouse} - {update.request_id}
                    </Typography>
                    <Typography fontSize="14px">{update.requested_quantity} units requested</Typography>
                    <Typography
  fontSize="14px"
  fontWeight="bold"
  textTransform="uppercase"
  sx={{
    padding: "4px 8px",
    borderRadius: "8px",
    color: "#fff",
    minWidth: "20px", // ✅ Set a minimum width
    maxWidth: "105px", // ✅ Prevent it from becoming too long
    textAlign: "center",
    backgroundColor:
      update.status === "requested"
        ? "#FFC107" // Yellow for Requested
        : update.status === "issued"
        ? "#4CAF50" // Green for Issued
        : update.status === "rejected"
        ? "#F44336" // Red for Rejected
        : update.status === "delayed"
        ? "#D32F2F" // Dark Red for Delayed
        : "#607D8B", // Grey for other statuses
  }}
>
  {update.status}
</Typography>

                  </Paper>
                </Box>
              ))}
            </>
          )}
        </Box>
    
      </Box>
    );
    
    
    
};

export default ChatUpdates;
