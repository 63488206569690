import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VendorDropdown from "./VendorDropdown";
import CreateNewVendor from "./CreateVendorForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import debounce from "lodash.debounce";

const UploadInvoice = ({ onClose }) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [documentType, setDocumentType] = useState("Invoice"); // State for dropdown
  const [isDisabled, setIsDisabled] = useState(false);
  const [showCreateVendor, setShowCreateVendor] = useState(false);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [checkingDuplicate, setCheckingDuplicate] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const [categories, setCategories] = useState([
    "Cement",
    "Plastic",
    "Bricks",
    "Electric",
  ]); // Initial categories
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
    console.log("File selected:", event.target.files[0]);
  };

  const checkDuplicateInvoice = debounce(async (invoiceNumber) => {
    if (!invoiceNumber) return;

    setCheckingDuplicate(true);
    try {
      const response = await fetch(
        // `https://test.datso.io/check-invoice/${invoiceNumber}`
        `https://test.datso.io/check-invoice/${invoiceNumber}`
      );
      if (response.ok) {
        const data = await response.json();
        setIsDuplicate(data.is_duplicate || false);
      } else {
        console.error("Error checking invoice number:", await response.text());
      }
    } catch (error) {
      console.error("Error checking duplicate invoice:", error);
    } finally {
      setCheckingDuplicate(false);
    }
  }, 500);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };
  const handleAddCategory = () => {
    if (newCategory.trim() && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setNewCategory("");
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setFile(files[0]);
      console.log("File dropped:", files[0]);
    }
  };

  const handleInvoiceNumberChange = (e) => {
    const value = e.target.value;
    setInvoiceNumber(value);
    checkDuplicateInvoice(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userEmail = localStorage.getItem("email");
    console.log('useremail is', userEmail)

    if (!invoiceNumber || !selectedVendor || !file) {
      showDialog("Please fill in all required fields.", "error");
      return;
    }

    if (isDuplicate) {
      showDialog(
        "Invoice number is already in use. Please choose a different one.",
        "error"
      );
      return;
    }

    const metadata = JSON.stringify({
      invoice_number: invoiceNumber,
      vendor: selectedVendor,
      remarks: remarks,
      document_type: documentType, // Include document type
      user_email: userEmail,
      
    });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("metadata", metadata);

    try {
      setIsDisabled(true);
      // const response = await fetch("https://test.datso.io/upload-invoice", {
      const response = await fetch("https://test.datso.io/upload-invoice", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        showDialog(
          "Invoice uploaded successfully and sent to REVIEW.",
          "success"
        );
        setInvoiceNumber("");
        setSelectedVendor(null);
        setFile(null);
        setRemarks("");
        setDocumentType("Invoice"); // Reset document type
      } else {
        const errorData = await response.json();
        console.error("API Error Response:", errorData);

        if (
          errorData.detail?.error === "Duplicate Invoice Detected" &&
          errorData.detail.old_avenue_id
        ) {
          const errorMessage = `Duplicate Invoice Found: Invoice Number '${invoiceNumber}' is already linked to Avenue ID '${errorData.detail.old_avenue_id}'. Please change the Invoice Number.`;
          showDialog(errorMessage, "error");
        } else {
          const genericMessage =
            errorData.detail?.message ||
            "An unexpected error occurred. Please try again.";
          showDialog(genericMessage, "error");
        }
      }
    } catch (error) {
      console.error("Submission Error:", error);
      showDialog("Failed to upload invoice. Please try again later.", "error");
    } finally {
      setIsDisabled(false);
    }
  };

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity || "info");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (dialogSeverity === "success") {
      onClose();
    }
  };

  useEffect(() => {
    if (showCreateVendor) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showCreateVendor]);

  return (
    <Card
      sx={{
        boxShadow: 3,
        borderRadius: "4px",
        overflow: "hidden",
        backgroundColor: "#F9FAFC",
        maxWidth: "800px",
        margin: "auto",
        position: "relative",
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#555",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2A3663",
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          Upload Invoice
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Invoice Number"
                value={invoiceNumber}
                onChange={handleInvoiceNumberChange}
                fullWidth
                variant="outlined"
                helperText={
                  checkingDuplicate
                    ? "Checking for duplicates..."
                    : isDuplicate
                    ? "Invoice number already exists."
                    : "Invoice number is unique."
                }
                error={isDuplicate}
              />
            </Grid>
            <Grid item xs={12}>
              <VendorDropdown
                onCreateVendor={() => setShowCreateVendor(true)}
                onSelect={(vendor) => {
                  setSelectedVendor(vendor);
                  console.log("Selected Vendor:", vendor);
                }}
                value={selectedVendor}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12} sx={{ mb: 2 }}>
                {/* Document Type Dropdown */}
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="document-type-label"
                    sx={{ fontSize: "1rem", top: "-5px" }} // Adjusting top position to align properly
                  >
                    Document Type
                  </InputLabel>
                  <Select
                    labelId="document-type-label"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    label="Document Type"
                  >
                    <MenuItem value="Tax Invoice">Tax Invoice</MenuItem>
                    <MenuItem value="Proforma Invoice">
                      Proforma Invoice
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sx={{ mb: 2 }}>
               
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="categories-label"
                    sx={{ fontSize: "1rem", top: "-5px" }} 
                  >
                    Categories
                  </InputLabel>
                  <Select
                    labelId="categories-label"
                    multiple
                    value={selectedCategories}
                    onChange={(e) => setSelectedCategories(e.target.value)}
                    renderValue={(selected) => selected.join(", ")}
                    label="Categories"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        <Checkbox
                          checked={selectedCategories.includes(category)}
                        />
                        <ListItemText primary={category} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              {/* <Box mt={2} display="flex" alignItems="center" gap={1}>
                <TextField
                  label="Add New Category"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <Button variant="contained" onClick={handleAddCategory}>
                  Add
                </Button>
              </Box> */}
            </Grid>

            <Grid item xs={12}>
              <Typography>Upload New Invoice </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed",
                  borderColor: isDragging ? "#4caf50" : "#3f51b5",
                  backgroundColor: isDragging ? "#e8f5e9" : "transparent",
                  borderRadius: "8px",
                  padding: 2,
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.3s, border-color 0.3s",
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <Button
                  variant="text"
                  component="label"
                  sx={{ color: "#3f51b5" }}
                >
                  Drag & Drop File or Click to Upload
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/jpg"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>

              {file && <Typography>Uploaded File: {file.name}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isDisabled}
                sx={{
                  backgroundColor: isDisabled ? "#ccc" : "#3f51b5",
                }}
              >
                {isDisabled ? "Processing..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
        {showCreateVendor && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1300,
            }}
          >
            <CreateNewVendor onClose={() => setShowCreateVendor(false)} />
          </Box>
        )}
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
            boxShadow: 6,
            backgroundColor:
              dialogSeverity === "success" ? "#E8F5E9" : "#FFCDD2",
            color: dialogSeverity === "success" ? "#2E7D32" : "#D32F2F",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontWeight: "bold",
            color: dialogSeverity === "success" ? "#388E3C" : "#D32F2F",
          }}
        >
          {dialogSeverity === "success" ? (
            <CheckCircleIcon fontSize="large" sx={{ color: "#388E3C" }} />
          ) : (
            <ErrorIcon fontSize="large" sx={{ color: "#D32F2F" }} />
          )}
          {dialogSeverity === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{
              backgroundColor:
                dialogSeverity === "success" ? "#388E3C" : "#D32F2F",
              color: "#FFFFFF",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default UploadInvoice;