import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Grid,
} from "@mui/material";

const ItemDropDown = ({ onSelect, value }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch items from API
  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://test.datso.io/get-all-masteritems");
      if (response.ok) {
        const data = await response.json();
        console.log("Fetched items:", data);

        const apiItems = data.items.map((item) => ({
          id: item.id,
          name: item.item_name,
          quantity: item.quantity,
        }));

        setItems(apiItems);
      } else {
        console.error("Failed to fetch items:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (inputValue.trim() === "") {
      setFilteredItems(items);
    } else {
      const matches = items.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (!matches.some((item) => item.name.toLowerCase() === inputValue.toLowerCase())) {
        setFilteredItems([{ id: "create_new", name: `Create New Item "${inputValue}"` }, ...matches]);
      } else {
        setFilteredItems(matches);
      }
    }
  }, [items, inputValue]);

  return (
    <Autocomplete
      options={filteredItems}
      value={value || null} // ✅ Keep selection independent
      getOptionLabel={(option) => option.name || ""}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={(event, newValue) => {
        if (newValue && newValue.id === "create_new") {
          onSelect({ id: newValue.id, name: inputValue });
        } else if (newValue) {
          onSelect(newValue);
        }
      }}
      loading={loading}
      onOpen={fetchItems}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Items"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} sx={{ padding: 2 }}>
          {option.id === "create_new" ? (
            <Typography sx={{ color: "#2A3663", fontWeight: "bold" }}>
              + {option.name}
            </Typography>
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {option.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "gray" }}>
                  Quantity: {option.quantity}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    />
  );
};

export default ItemDropDown;