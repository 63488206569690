import React, { useState, useEffect, useRef,useMemo } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  DialogActions,
  TablePagination,
  IconButton,
  DialogTitle,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { Close as CloseIcon, Delete as DeleteIcon, Edit as EditIcon, AttachFile, Send as SendIcon } from "@mui/icons-material";
import axios from "axios";
import InventoryIcon from '@mui/icons-material/Inventory';
import InventoryRequestDialog from "./InventoryRequestDialog";
import ManageInventory from "./ManageInventory";

// ----- Inventory List Component -----
const InventoryList = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "item_name", direction: "desc" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://test.datso.io/all-inventory");
        if (!response.ok) {
          throw new Error(`Failed to fetch inventory. Status: ${response.status}`);
        }
        const data = await response.json();
        setInventory(data.inventory);
      } catch (err) {
        console.error("Error fetching inventory:", err);
        setError(err.message || "Unable to fetch inventory. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInventory();
  }, []);

  const filteredInventory = inventory.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      String(item.item_name || "").toLowerCase().includes(query) ||
      String(item.location || "").toLowerCase().includes(query) ||
      String(item.warehouse || "").toLowerCase().includes(query) ||
      String(item.quantity || "").toLowerCase().includes(query) ||
      String(item.project_name || "").toLowerCase().includes(query) ||
      String(item.property_name || "").toLowerCase().includes(query)
    );
  });

  const sortedInventory = [...filteredInventory].sort((a, b) => {
    const key = sortConfig.key;
    if (key === "updated_date_ist") {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      if (isNaN(dateA) || isNaN(dateB)) return 0;
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (a[key] < b[key]) return sortConfig.direction === "asc" ? -1 : 1;
    if (a[key] > b[key]) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleQrCodeClick = (itemName, qrCodePath) => {
    setQrCodeData({ itemName, qrCodePath });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setQrCodeData(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedInventory = sortedInventory.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ padding: 2, maxWidth: "100%", backgroundColor: "white", borderRadius: "8px" }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", color: "black", mt: "-99px", mb: "2%",ml:2}}>
        Inventory
      </Typography>
      
      {/* Align the search bar to the right */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: "-1.699%" }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search Inventory"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "350px",
            "& .MuiOutlinedInput-root": { borderRadius: "25px" },
            marginBottom: 2,
            "& input": {
                padding: "8px 12px",},
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Typography color="error" sx={{ marginTop: 2, textAlign: "center" }}>
          {error}
        </Typography>
      )}
      {!loading && !error && (
        <>
          <TableContainer component={Paper} sx={{ borderRadius: "2px", overflowX: "auto" }}>
            <Table sx={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead sx={{ backgroundColor: "#2A3663" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "white",
                      cursor: "pointer",
                      width: "250px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleSort("item_name")}
                  >
                    Item Name {sortConfig.key === "item_name" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      cursor: "pointer",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleSort("location")}
                  >
                    Location {sortConfig.key === "location" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      cursor: "pointer",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleSort("updated_date_ist")}
                  >
                    Updated On {sortConfig.key === "updated_date_ist" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      cursor: "pointer",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleSort("warehouse")}
                  >
                    Warehouse {sortConfig.key === "warehouse" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell sx={{ width: "120px", textAlign: "center", color: "white", fontWeight: "bold" }}
                    onClick={() => handleSort("quantity")}
                  >
                    Quantity {sortConfig.key === "quantity" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell sx={{ width: "150px", textAlign: "center", color: "white", fontWeight: "bold" }}>
                    QR Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInventory.length > 0 ? (
                  paginatedInventory.map((item) => (
                    <TableRow key={item.inv_id}>
                      <Tooltip title={item.item_name} placement="top">
                        <TableCell sx={{ width: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                          {item.item_name || "Unknown"}
                        </TableCell>
                      </Tooltip>
                      <TableCell sx={{ width: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {item.location || "Unknown"}
                      </TableCell>
                      <TableCell sx={{ width: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {item.updated_date_ist || "Unknown"}
                      </TableCell>
                      <TableCell sx={{ width: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {item.warehouse || "Unknown"}
                      </TableCell>
                      <TableCell sx={{ width: "120px", textAlign: "center" }}>
                        {item.quantity || 0}
                      </TableCell>
                      <TableCell sx={{ width: "150px", textAlign: "center" }}>
                        {item.qr_code_path ? (
                          <Button variant="outlined" color="primary" onClick={() => handleQrCodeClick(item.item_name, item.qr_code_path)}>
                            View QR Code
                          </Button>
                        ) : (
                          "No QR Code"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                      No items found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 40, 60, 80, 100]}
            component="div"
            count={sortedInventory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogContent sx={{ textAlign: "center" }}>
          {qrCodeData ? (
            <>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                {qrCodeData.itemName}
              </Typography>
              <img src={qrCodeData.qrCodePath} alt="QR Code" style={{ maxWidth: "100%", maxHeight: "400px", marginBottom: "16px" }} />
            </>
          ) : (
            <Typography>No QR Code Available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {qrCodeData && (
            <Button href={qrCodeData.qrCodePath} download={`${qrCodeData.itemName}-QRCode.png`} variant="contained" color="primary" startIcon={<DownloadIcon />}>
              Download
            </Button>
          )}
          <Button onClick={handleDialogClose} variant="outlined" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// ----- RequestedInventory Component -----
const RequestedInventory = ({ statusTab }) => {
  // States for requests and filtering
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [requests, setRequests] = useState([]);
  const [showRequestDetails, setShowRequestDetails] = useState(false);
const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  // States for update/chat functionality
  const [inventoryUpdates, setInventoryUpdates] = useState([]);
  const [newUpdateText, setNewUpdateText] = useState("");
  const [newUpdateFiles, setNewUpdateFiles] = useState([]);
  const [sendDisabled, setSendDisabled] = useState(false);

  // States for dialogs (update status & delete confirmation)
  const [editStatusDialog, setEditStatusDialog] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  // State for details modal
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  
  const empName = localStorage.getItem("first_name") || "Unknown Engineer";
  const scrollRef = useRef(null);
  const [filterStatus, setFilterStatus] = useState(
    statusTab === "stock" ? "raised" : "requested"
  );
  const inputRef = useRef(null);
  const [inventoryFilter, setInventoryFilter] = useState("");



  const [raiseDialogOpen, setRaiseDialogOpen] = useState(false);
const [raisedQuantity, setRaisedQuantity] = useState("");

// Define state for the raise inventory form data
const [raiseInventoryData, setRaiseInventoryData] = useState({
  item_name: "",
  warehouse: "",
  project_name: "",
  property_name: "",
  quantity: "",
});

// When a new request is selected, update the raiseInventoryData state
useEffect(() => {
  if (selectedRequest) {
    setRaiseInventoryData({
      item_name: selectedRequest.item_name,
      warehouse: selectedRequest.warehouse,
      project_name: selectedRequest.project_name,
      property_name: selectedRequest.property_name,
      quantity: selectedRequest.requested_quantity,
    });
  }
}, [selectedRequest]);


// You can place this function in the same file, or a separate utilities file:
const statusColors = {
  requested: "#F7BE6A",  // Light orange
  blocked:   "#ccccff",  // Orange
  raised:    "#E1BEE7",  // Purple
  issued:    "#ccffcc",  // Green
  rejected:  "#ffad99",  // Red
};

const statusTextColor = {
  requested: "#e67300", // Black text
  blocked:   "#0000cc", // White text
  raised:    "#6A1B9A",
  issued:    "#00cc00",
  rejected:  "#cc2900",
};

const statusBorderColor = {
  requested: "#E0A852",
  blocked:   "#0000cc",
  raised:    "#6A1B9A",
  issued:    "#00cc00",
  rejected:  "#cc2900",
};


// Handler for form field changes
const handleRaiseInventoryChange = (field, value) => {
  setRaiseInventoryData(prev => ({
    ...prev,
    [field]: value,
  }));
};

// Handler to submit the raised inventory details
const handleRaiseInventory = async () => {
  try {
    const payload = {
      item_name: raiseInventoryData.item_name.trim(),
      warehouse: raiseInventoryData.warehouse.trim(),
      project_name: raiseInventoryData.project_name.trim(),
      property_name: raiseInventoryData.property_name.trim(),
      requested_quantity: Number(raiseInventoryData.quantity),
      invoice_id: 0, // or a default value that makes sense in your context
      // Optionally, add engineer_id if required
    };
    

    console.log("Payload:", payload);

    await axios.post("https://test.datso.io/raise-inventory", payload);
    
    setRaiseDialogOpen(false);
    // Optionally, refresh your inventory data here

  } catch (error) {
    console.error("Error raising inventory:", error.response?.data || error);
  }
};





// Update raisedQuantity when a new request is selected
useEffect(() => {
  if (selectedRequest) {
    setRaisedQuantity(selectedRequest.requested_quantity);
  }
}, [selectedRequest]);

// Handler to open the raise dialog
const handleOpenRaiseDialog = () => {
  setRaiseDialogOpen(true);
};





  // --- FETCH ALL REQUESTS ---
  const fetchRequests = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://test.datso.io/all-requests");
      const filteredRequests = (response.data.requests || []).filter(request => request.p_req_id === null);
      setRequests(filteredRequests);
    } catch (error) {
      console.error("❌ Error fetching all requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  
  const statusOptions = [
    { value: "requested", label: "Requested" },
    { value: "raised", label: "Raised" },
    { value: "blocked", label: "Blocked" },
    { value: "issued", label: "Issued" },
    { value: "rejected", label: "Rejected" },
    { value: "", label: "All" } // Option to see all statuses
  ];
  
  

  // Filter requests based on search query and statusTab prop
  useEffect(() => {
    let filtered = requests;
  
    // Apply default filter only if no specific status filter is selected
    if (!filterStatus) {
      if (statusTab === "inventory") {
        filtered = filtered.filter((req) => req.status === "requested");
      } else if (statusTab === "stock") {
        filtered = filtered.filter((req) => req.status === "raised");
      }
    } else {
      filtered = filtered.filter((req) => req.status === filterStatus);
    }
  
    // Apply search query filtering if provided.
    if (searchQuery) {
      filtered = filtered.filter(
        (req) =>
          req.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          req.engineer_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
          req.property_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    
    setFilteredRequests(filtered);
  }, [searchQuery, requests, statusTab, filterStatus]);
  
    

  // --- Sorting Handler ---
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // --- Sorted Requests (memoized) ---
  const sortedRequests = useMemo(() => {
    if (!sortConfig.key) return filteredRequests;
    return [...filteredRequests].sort((a, b) => {
      let aVal = a[sortConfig.key] ?? "";
      let bVal = b[sortConfig.key] ?? "";
      // If numeric columns, convert to numbers
      if (["request_id", "engineer_id", "requested_quantity"].includes(sortConfig.key)) {
        aVal = Number(aVal);
        bVal = Number(bVal);
      } else {
        aVal = String(aVal).toLowerCase();
        bVal = String(bVal).toLowerCase();
      }
      if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredRequests, sortConfig]);

  // --- HANDLE REQUEST CLICK (Open Modal) ---
  const handleRequestClick = (request) => {
    setSelectedRequest(request);
    fetchInventoryUpdates(request.item_name);
    setOpenDetailsDialog(true);
  };

  // --- FETCH UPDATES FOR A SELECTED REQUEST ---
  const fetchInventoryUpdates = async (itemName) => {
    try {
      console.log(itemName)
      const response = await axios.get(`https://test.datso.io/inventory-update/${itemName}`);
      console.log(response)
      const sortedDetails = response.data.details.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      setInventoryUpdates(sortedDetails);
    } catch (error) {
      console.error("❌ Error fetching inventory updates:", error);
    }
  };
  

  // --- HANDLE FILE CHANGE & REMOVE ---
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setNewUpdateFiles(files);
  };

  const removeFile = (index) => {
    setNewUpdateFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // --- FORMAT TIME ---
  const formatISTTime = (utcTime) => {
    const date = new Date(utcTime);
    return date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
  };

  // --- HANDLE SEND UPDATE ---
  const handleSendUpdate = async () => {
    if (!newUpdateText.trim() && newUpdateFiles.length === 0) return;
    setSendDisabled(true);
    setTimeout(() => setSendDisabled(false), 3000);
    try {
      const formData = new FormData();
      formData.append("request_id", selectedRequest.request_id);
      formData.append("update_text", newUpdateText);
      formData.append("status", selectedRequest.status);
      formData.append("engineer_name", empName);
      newUpdateFiles.forEach((file) => formData.append("update_files", file));

      await axios.post("https://test.datso.io/inventory-updates", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      fetchInventoryUpdates(selectedRequest.item_name);
      setNewUpdateText("");
      setNewUpdateFiles([]);
    } catch (error) {
      console.error("❌ Error sending update:", error);
    }
  };

  const isButtonDisabled = sendDisabled || (!newUpdateText.trim() && newUpdateFiles.length === 0);

  // --- HANDLE STATUS UPDATE & DELETE ---
  const handleUpdateStatus = async () => {
    if (!selectedRequest) return;
    try {
      await axios.patch(`https://test.datso.io/update-request/${selectedRequest.request_id}`, { status: newStatus });
      fetchRequests();
      setEditStatusDialog(false);
    } catch (error) {
      console.error("❌ Error updating status:", error);
    }
  };

  const handleDeleteRequest = async () => {
    try {
      await axios.delete(`https://test.datso.io/delete-request/${selectedRequest.request_id}`);
      setRequests(requests.filter((req) => req.request_id !== selectedRequest.request_id));
      setConfirmDelete(false);
      setOpenDetailsDialog(false);
      setSelectedRequest(null);
    } catch (error) {
      console.error("❌ Error deleting request:", error);
    }
  };

  // Auto-scroll the update chat box when new updates arrive
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [inventoryUpdates]);
  useEffect(() => {
    if (selectedRequest) {
      setNewStatus(selectedRequest.status);
    }
  }, [selectedRequest]);
  
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", color: "black", mt: "-99px", mb: "2%" , ml:2}}>
        {statusTab === "inProgress" ? " Inventory" : " Inventory"}
      </Typography>
      <Box
  sx={{
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 2,
    mt: "-1.7%",
  }}
>
  <Select
    value={filterStatus}
    onChange={(e) => setFilterStatus(e.target.value)}
    displayEmpty
    size="small"
    sx={{ width: "180px", borderRadius: "25px",            marginBottom: 2,
    }}
  >
    {statusOptions.map((opt) => (
      <MenuItem key={opt.value} value={opt.value}>
        {opt.label}
      </MenuItem>
    ))}
  </Select>
  <TextField
      inputRef={inputRef}
      // label="Search Requests"
      variant="outlined"
      size="small"
      placeholder="Search Requests"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      sx={{
        width: "350px",
        marginBottom: 2,

        "& .MuiOutlinedInput-root": {
          borderRadius: "25px",
          flexDirection: "row-reverse",
          padding:"0", // Ensures icon is on the right
        },
        "& input": {
          textAlign: "start",
          padding: "8px 12px",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={() => inputRef.current.focus()}
            sx={{ cursor: "pointer",ml:1 }}
          >
            <SearchIcon sx={{ color: "#2C3E50" }} />
          </InputAdornment>
        ),
      }}
    />




</Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="requests table">
            <TableHead sx={{ backgroundColor: "#2A3663" }}>
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleSort("request_id")}
                >
                  Request Id {sortConfig.key === "request_id" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleSort("item_name")}
                >
                  Item Name {sortConfig.key === "item_name" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleSort("engineer_id")}
                >
                  Employee Id {sortConfig.key === "engineer_id" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleSort("project_name")}
                >
                  Project {sortConfig.key === "project_name" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleSort("property_name")}
                >
                  Property {sortConfig.key === "property_name" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  align="center"
                  onClick={() => handleSort("requested_quantity")}
                >
                  Qty {sortConfig.key === "requested_quantity" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  align="center"
                  onClick={() => handleSort("requested_quantity")}
                >
                  Expected Delivery {sortConfig.key === "requested_quantity" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => handleSort("status")}
                >
                  Status {sortConfig.key === "status" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                </TableCell>
                {/* <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                  Actions
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRequests.length > 0 ? (
                sortedRequests.map((req) => (
                  <TableRow key={req.request_id} hover onClick={() => handleRequestClick(req)} sx={{ cursor: "pointer"}}>
                    <TableCell sx={{color: "#1565C0",textDecoration: "underline"}}>{req.request_id }</TableCell>
                    <TableCell>{req.item_name}</TableCell>
                    <TableCell>{req.engineer_id}</TableCell>
                    <TableCell>{req.project_name}</TableCell>

                    <TableCell>{req.property_name}</TableCell>
                    <TableCell align="center">{req.requested_quantity}</TableCell>
                    
<TableCell align="center">
  {req.deli_date 
    ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(req.deli_date)) 
    : 'N/A'}
</TableCell>
                    <TableCell>
                    <Box

                      sx={{
                        textAlign: "center",
                        padding: "4px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        backgroundColor:
                          statusColors[req.status] || "#F0F0F0",
                        color: statusTextColor[req.status] || "#000000",
                        width: "200px",
                        height: "30px",
                        border: `1px solid ${
                          statusBorderColor[req.status] || "#D3D3D3"
                        
                        }`,
                        textTransform: "uppercase",
                      }}
                    >
                      {req.status}
                    </Box>
                  </TableCell>

           
                  
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No requests found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* Request Details & Updates Modal */}




{/* Request Details & Updates Modal */}
<InventoryRequestDialog
  open={openDetailsDialog}
  handleClose={() => setOpenDetailsDialog(false)}
  selectedRequest={selectedRequest}
  refreshRequests={fetchRequests} // pass the fetchRequests function as a prop
  fullScreen
/>





<Dialog open={raiseDialogOpen} onClose={() => setRaiseDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle
    sx={{
      backgroundColor: "#2A3663",
      color: "#fff",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      padding: "16px",
    }}
  >
    Raise Inventory
    <IconButton onClick={() => setRaiseDialogOpen(false)} sx={{ color: "#fff" }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent sx={{ padding: "24px" }}>
    {selectedRequest && (
      <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Item Name"
          value={raiseInventoryData.item_name}
          onChange={(e) => handleRaiseInventoryChange("item_name", e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Warehouse"
          value={raiseInventoryData.warehouse}
          onChange={(e) => handleRaiseInventoryChange("warehouse", e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Project"
          value={raiseInventoryData.project_name}
          onChange={(e) => handleRaiseInventoryChange("project_name", e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Property"
          value={raiseInventoryData.property_name}
          onChange={(e) => handleRaiseInventoryChange("property_name", e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Quantity"
          value={raiseInventoryData.quantity}
          onChange={(e) => handleRaiseInventoryChange("quantity", e.target.value)}
          variant="outlined"
          type="number"
        />
      </Box>
    )}
  </DialogContent>
  <DialogActions sx={{ padding: "16px" }}>
    <Button onClick={() => setRaiseDialogOpen(false)} variant="outlined" sx={{ textTransform: "none" }}>
      Cancel
    </Button>
    <Button onClick={handleRaiseInventory} variant="contained" color="primary" sx={{ textTransform: "none" }}>
      Submit
    </Button>
  </DialogActions>
</Dialog>




      {/* Edit Status Dialog */}
      <Dialog open={editStatusDialog} onClose={() => setEditStatusDialog(false)}>
        <DialogTitle>Edit Inventory Status</DialogTitle>
        <DialogContent>
          <Typography>Request Id: {selectedRequest?.request_id || "N/A"}</Typography>
          <br />
          <Select fullWidth value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
            <MenuItem value="requested">Requested</MenuItem>
            <MenuItem value="blocked">Blocked</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="issued">Issued</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditStatusDialog(false)}>Cancel</Button>
          <Button onClick={handleUpdateStatus} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Confirmation Dialog */}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Delete Inventory Request?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this request?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button onClick={handleDeleteRequest} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// ----- Inventory Tabs (Parent Component) -----
const InventoryTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      {/* Header Section */}
      <Box sx={{ padding: "16px 16px", backgroundColor: "#FFFFFF" }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}></Typography>
      </Box>
      {/* Tabs Row */}
      <Box sx={{ backgroundColor: "#FFFFFF", paddingLeft: "16px", paddingTop: "20px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="inventory tabs"
          textColor="primary"
          indicatorColor="primary"
          sx={{
            "& .MuiTab-root": {
              fontFamily: "Open Sans, Arial, sans-serif",
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: 400,
              letterSpacing: "0.5px",
              minWidth: "auto",
              paddingX: 2,
            },
          }}
        >
          <Tab label=" Inventory Requests" />
          <Tab label=" Stock Requests" />
          <Tab label="Manage Inventory" />
          <Tab label="Stock" />


        </Tabs>
      </Box>
      {/* Tab Content */}
      <Box sx={{ padding: 0 }}>
      {selectedTab === 0 && <RequestedInventory statusTab="inventory" />}
        {selectedTab === 1 && <RequestedInventory statusTab="stock" />}
        {selectedTab === 2 && <ManageInventory />}
        {selectedTab === 3 && <InventoryList />}

      </Box>
    </Paper>
  );
};

export default InventoryTabs;
