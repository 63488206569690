import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  Tooltip,
  TablePagination,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditVendorForm from "./EditVendorForm";
import CreateVendorForm from "./CreateVendorForm";
import VendorDetailsComponent from "./VendorDetailsComponent";

const VendorDetails = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isCreateVendorOpen, setIsCreateVendorOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "vendor_id", direction: "desc" });
  const [selectedVendorId, setSelectedVendorId] = useState(null);

  const fetchVendors = async () => {
    try {
      const response = await fetch("https://test.datso.io/get-all-vendors");
      if (response.ok) {
        const data = await response.json();
        setVendors(data.vendors || []);
      } else {
        setError("Failed to fetch vendor data.");
      }
    } catch (error) {
      setError("Error fetching vendors: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleSearch = (vendor) => {
    const query = searchQuery.toLowerCase();
    return (
      vendor.vendor_id?.toLowerCase().includes(query) ||
      vendor.vendor_name?.toLowerCase().includes(query) ||
      vendor.vendor_gst_number?.toLowerCase().includes(query) ||
      vendor.vendor_bank_account_number?.toLowerCase().includes(query) ||
      vendor.vendor_bank_name?.toLowerCase().includes(query) ||
      vendor.vendor_bank_ifsc_code?.toLowerCase().includes(query)
    );
  };

  const filteredVendors = vendors.filter(handleSearch);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedVendors = [...filteredVendors].sort((a, b) => {
    const key = sortConfig.key;
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    if (key === "updated_date_ist") {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return isNaN(dateA) || isNaN(dateB) ? 0 : direction * (dateA - dateB);
    }

    if (!isNaN(a[key]) && !isNaN(b[key])) {
      return direction * (a[key] - b[key]);
    }

    const valueA = (a[key] || "").toString().toLowerCase();
    const valueB = (b[key] || "").toString().toLowerCase();

    return valueA < valueB ? -1 * direction : valueA > valueB ? 1 * direction : 0;
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (vendor) => {
    setSelectedVendor(vendor);
  };

  const handleVendorUpdated = () => {
    setSelectedVendor(null);
    setIsCreateVendorOpen(false);
    fetchVendors();
  };

  return (
    <Box sx={{ padding: 0, maxWidth: "100%", margin: "0", backgroundColor: "#F9F9F9", borderRadius: "8px", marginTop: "-4%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "black" }}>
          {/* Your header if needed */}
        </Typography>
        <Box>
          <TextField
            label="Search Vendor"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              marginRight: 2,
              backgroundColor: "#fffff",
              width: 300,
              borderRadius: "25px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px",
                height: "36px",
                padding: "0",
                "& input": {
                  padding: "8px 12px",
                },
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsCreateVendorOpen(true)}
            style={{
              backgroundColor: "#2a3663",
              color: "white",
            }}
          >
            Add Vendor
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 5 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Paper>
          <TableContainer component={Paper} sx={{ borderRadius: "2px", overflowX: "auto" }}>
            <Table sx={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead sx={{ backgroundColor: "#2A3663" }}>
                <TableRow>
                  <TableCell
                    sx={{ color: "white", cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handleSort("vendor_id")}
                  >
                    Vendor ID{" "}
                    {sortConfig.key === "vendor_id" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handleSort("vendor_display_name")}
                  >
                    Vendor Display Name{" "}
                    {sortConfig.key === "vendor_display_name" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handleSort("vendor_gst_number")}
                  >
                    GST Number {sortConfig.key === "vendor_gst_number" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handleSort("vendor_bank_name")}
                  >
                    Bank Name {sortConfig.key === "vendor_bank_name" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handleSort("vendor_bank_account_number")}
                  >
                    Account Number{" "}
                    {sortConfig.key === "vendor_bank_account_number" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handleSort("vendor_bank_ifsc_code")}
                  >
                    IFSC Code {sortConfig.key === "vendor_bank_ifsc_code" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                  </TableCell>
                  {/* <TableCell sx={{ color: "white", fontWeight: "bold" }}>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vendor) => (
                  <TableRow key={vendor.vendor_id}>
<TableCell>
  <Button
    variant="text"
    color=""
    onClick={() => setSelectedVendorId(vendor.vendor_id)}
    sx={{ textTransform: "none", fontWeight: "bold", textDecoration: "underline",color: "#1565C0" }}
  >
    {vendor.vendor_id}
  </Button>
</TableCell>
                    <TableCell>{vendor.vendor_display_name}</TableCell>
                    <TableCell>{vendor.vendor_gst_number}</TableCell>
                    <TableCell>{vendor.vendor_bank_name}</TableCell>
                    <TableCell>{vendor.vendor_bank_account_number}</TableCell>
                    <TableCell>{vendor.vendor_bank_ifsc_code}</TableCell>
                    {/* <TableCell>
                      <Tooltip title="Edit Vendor">
                        <IconButton onClick={() => handleEditClick(vendor)} color="primary">
                          <BorderColorIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={vendors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          />
        </Paper>
      )}

      {selectedVendor && (
        <EditVendorForm
          vendorData={selectedVendor}
          onClose={() => setSelectedVendor(null)}
          onVendorCreated={handleVendorUpdated}
        />
      )}
      {isCreateVendorOpen && (
        <CreateVendorForm
          onClose={() => setIsCreateVendorOpen(false)}
          onVendorCreated={handleVendorUpdated}
        />
      )}

      {/* Dialog for Vendor Details */}
      <Dialog
        open={Boolean(selectedVendorId)}
        onClose={() => setSelectedVendorId(null)}
        fullWidth
        maxWidth="100%"
      >
        
        <DialogTitle></DialogTitle>
        <DialogContent>
          <VendorDetailsComponent vendorId={selectedVendorId} onClose={() => setSelectedVendorId(null)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VendorDetails;
