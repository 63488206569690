import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material";
import axios from "axios";

// Fixed list of departments in your construction company (including Construction)
const departmentsList = [
  "Architecture",
  "Civil",
  "Electrical",
  "Mechanical",
  "Project Management",
  "Procurement",
  "Quality Control",
  "Safety",
  "Administration",
  "HR",
  "Construction",
];

const AssignEmployeeDialog = ({ open, onClose, onEmployeeSelect }) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // Default department is "Construction"
  const [departmentFilter, setDepartmentFilter] = useState("Construction");
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  useEffect(() => {
    if (open) {
      fetchEmployees();
      setSelectedEmployees([]); // Clear previous selection when dialog opens
    }
  }, [open]);
  
  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://test.datso.io/employees");
      console.log("Employee data:", response.data); // Inspect engineer_id here
      setEmployees(response.data || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setEmployees([]);
    } finally {
      setLoading(false);
    }
  };
  
  

  // Filter employees based on searchQuery and departmentFilter (case-insensitive)
  const filteredEmployees = employees.filter((emp) => {
    const fullName = `${emp.first_name} ${emp.last_name || ""}`.toLowerCase();
    const matchesName = fullName.includes(searchQuery.toLowerCase());
    const matchesDepartment = departmentFilter
      ? emp.department.toLowerCase() === departmentFilter.toLowerCase()
      : true;
    return matchesName && matchesDepartment;
  });

  const handleToggle = (emp) => {
    const isSelected = selectedEmployees.some((e) => e.employee_id === emp.employee_id);
    if (isSelected) {
      setSelectedEmployees(selectedEmployees.filter((e) => e.employee_id !== emp.employee_id));
    } else {
      setSelectedEmployees([...selectedEmployees, emp]);
    }
  };
  
  

  const handleConfirmSelection = () => {
    onEmployeeSelect(selectedEmployees);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2 }}>
          Select Employees
        </Typography>
        <TextField
          label="Search employees"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          select
          label="Filter by Department"
          variant="outlined"
          fullWidth
          value={departmentFilter}
          onChange={(e) => setDepartmentFilter(e.target.value)}
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="">All Departments</MenuItem>
          {departmentsList.map((dept) => (
            <MenuItem key={dept} value={dept}>
              {dept}
            </MenuItem>
          ))}
        </TextField>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100px">
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
            <List>
            {filteredEmployees.map((emp) => {
  const isSelected = selectedEmployees.some(
    (e) => e.employee_id === emp.employee_id
  );
  return (
    <ListItem
      button
      key={emp.employee_id} // Use employee_id as the key
      onClick={() => handleToggle(emp)}
    >
      <Checkbox checked={isSelected} />
      <ListItemText
        primary={`${emp.first_name} ${emp.last_name || ""}`}
        secondary={`Code: ${emp.engineer_code} - ${emp.department}`}
      />
    </ListItem>
  );
})}


            </List>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmSelection} variant="contained" color="primary">
            Add Selected ({selectedEmployees.length})
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AssignEmployeeDialog;
