import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LaborOnboardingForm = ({ existingData, onCancel, onClose }) => {
  console.log("Existing Data:", existingData);

  const [labor, setFormData] = useState({
    labor_name: "",
    labor_phone: "",
    work_type: "",
    payment_type: "",
    project_id: "",
    contractor_id: "",
    aadhar_file: null,
    pancard_file: null,
    aadhar_file_url: "",
    pancard_file_url: "",
  });

  const [projects, setProjects] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);

  // State for Dialog Box
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("success"); // "success" or "error"

  useEffect(() => {
    if (existingData) {
      setFormData((prev) => ({
        ...prev,
        labor_name: existingData.labor_name || existingData.name || "",
        labor_phone: existingData.labor_phone || existingData.number || "",
        work_type: existingData.work_type || "",
        payment_type: existingData.payment_type || "",
        contractor_id: existingData.contractor_id || "",
        project_id: existingData.project_id || "",
        aadhar_file_url: existingData.aadhar_file_url || "",
        pancard_file_url: existingData.pancard_file_url || "",
        aadhar_file: null,
        pancard_file: null,
      }));
    }
  }, [existingData]);

  useEffect(() => {
    const fetchData = async (url, setState) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setState(data.projects || data.contractors || data.work_types || []);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };

    fetchData("https://test.datso.io/projects", setProjects);
    fetchData("https://test.datso.io/contractors", setContractors);
    fetchData("https://test.datso.io/work-types", setWorkTypes);
  }, []);

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };
  
  const handleFileChange = (fileType, file) => {
    setFormData((prev) => ({
      ...prev,
      [fileType]: file,
    }));
  };
  

  const handleSubmit = async () => {
    if (!labor.labor_name || !labor.work_type || !labor.payment_type) {
      setDialogMessage("Please fill in all required fields.");
      setDialogType("error");
      setDialogOpen(true);
      return;
    }
  
    try {
      const formData = new FormData();
      Object.keys(labor).forEach((key) => {
        if (labor[key]) {
          formData.append(key, labor[key]);
        }
      });
  
      if (labor.aadhar_file) {
        formData.append("aadhar_file", labor.aadhar_file);
      }
      if (labor.pancard_file) {
        formData.append("pancard_file", labor.pancard_file);
      }
  
      const url = existingData
        ? `https://test.datso.io/labors/${existingData.labor_id}`
        : "https://test.datso.io/labors";
      const method = existingData ? "PUT" : "POST";
  
      const response = await fetch(url, { method, body: formData });
  
      if (!response.ok) {
        throw new Error("Failed to save labor details.");
      }
  
      setDialogMessage("Labor details saved successfully.");
      setDialogType("success");
      setDialogOpen(true);
    } catch (error) {
      console.error("Error saving labor:", error);
      setDialogMessage("Error saving labor. Please try again.");
      setDialogType("error");
      setDialogOpen(true);
    }
  };
  
  

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (dialogType === "success") {
      if (onClose) {
        onClose(); // Close the form if onClose prop is provided
      } else if (onCancel) {
        onCancel(); // Fallback to onCancel if onClose is not provided
      }
    }
  };
  

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100%" }}>
      <Card elevation={0} style={{ width: "100%", maxWidth: "800px" }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
          
            color: "#555",
            "&:hover": { color: "#D32F2F" },
          }}
        >
          <CloseIcon />
        </IconButton>
        <CardContent>
          <Box sx={{ textAlign: "center", marginBottom: 3 }}>
            <Typography variant="h4" sx={{ fontWeight: "400", color: "#34495e"}}>
              {existingData ? "Edit Labor" : "Add Labor"}
            </Typography>
            {/* <Typography variant="subtitle1" sx={{ color: "#7f8c8d" }}>
              {existingData ? "Update the labor details" : "Please fill out the form to onboard a laborer."}
            </Typography> */}
          </Box>

          <Grid container spacing={3}>
          
            <Grid item xs={12} sm={6}>
              <TextField
                label="Labor Name"
                value={labor.labor_name}
                onChange={(e) => handleChange("labor_name", e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={labor.labor_phone}
                onChange={(e) => handleChange("labor_phone", e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

         
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Work Type</InputLabel>
                <Select
                  value={labor.work_type || ""}
                  onChange={(e) => handleChange("work_type", e.target.value)}
                  label="Work Type"
                >
                  {workTypes.length > 0 ? (
                    workTypes.map((workType) => (
                      <MenuItem key={workType.work_type_id} value={workType.work_type_name}>
                        {workType.work_type_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Work Types Available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Payment Type</InputLabel>
                <Select value={labor.payment_type} onChange={(e) => handleChange("payment_type", e.target.value)} label="Payment Type">
                  <MenuItem value="Hourly">Hourly</MenuItem>
                  <MenuItem value="Per Square Foot">Per Square Foot</MenuItem>
                  <MenuItem value="Project-based">Project-based</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Contractor</InputLabel>
                <Select value={labor.contractor_id} onChange={(e) => handleChange("contractor_id", e.target.value)} label="Contractor">
                  <MenuItem value="">No Contractor</MenuItem>
                  {contractors.map((contractor) => (
                    <MenuItem key={contractor.contractor_id} value={contractor.contractor_id}>
                      {contractor.contractor_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {["aadhar_file", "pancard_file"].map((fileType, index) => (
  <Grid item xs={12} sm={6} key={index}>
    <Typography>
      {fileType.replace("_", " ").replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())}
    </Typography>
    {labor[`${fileType}_url`] && (
      <Link
        href={labor[`${fileType}_url`]}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: "block", marginTop: 1 }}
      >
        View File
      </Link>
    )}
    <Button
      variant="outlined"
      component="label"
      fullWidth
      sx={{
        marginTop: 1,
        borderColor: "#34495E",
        "&:hover": { backgroundColor: "#34495E", color: "#fff" },
      }}
    >
      Upload File (Optional)
      <input
        type="file"
        accept=".jpg,.jpeg,.png,.pdf"
        hidden
        onChange={(e) => handleFileChange(fileType, e.target.files[0])}
      />
    </Button>
    {labor[fileType] && (
      <Typography sx={{ marginTop: 1, fontSize: "0.875rem", color: "#34495E" }}>
        Selected File: {labor[fileType].name}
      </Typography>
    )}
  </Grid>
))}
            
          </Grid>

          <Box style={{ display: "flex", justifyContent: "center", marginTop: "32px" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ width: "45%" }}>
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Custom Dialog Box */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{dialogType === "success" ? "Success" : "Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LaborOnboardingForm;
