import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  Box,
  Typography,
  CircularProgress,
  Button,
  Tabs,
  Tab,
  Paper,
  Input,TableCell,
  TableHead,
  TableBody,
  TableRow,  Table,Popover,
  

} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskManager from "./TaskManager"; // Task Management Component
import { Chip } from "@mui/material";
import { Grid } from "@mui/material";
// Import icons for files:
import GridOnIcon from '@mui/icons-material/GridOn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { AiFillFileImage, AiFillFileExcel, AiFillFilePdf, AiFillFile } from 'react-icons/ai';


import InventoryManagement from "./InventoryManagement";
import axios from "axios";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import gantt from "dhtmlx-gantt";
import GanttChart from "./GanttChart";
import SchedulePIP from "./SchedulePIP";
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AssignEmployeeDialog from "./AssignEmployeeDialog"; // Import the dialog component
import VisibilityIcon from "@mui/icons-material/Visibility";



const PropertyDetailsDialog = ({ open, property, loading, onClose }) => {
  const [schedule, setSchedule] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const ganttContainer = useRef(null);
  const [showSchedulePiP, setShowSchedulePiP] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [scheduleView, setScheduleView] = useState("gantt"); // "gantt" or "tasks"
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [assignEmployeeDialogOpen, setAssignEmployeeDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
const [dialogMessage, setDialogMessage] = useState("");
const [dialogType, setDialogType] = useState("success"); // "success" or "error"
  // NEW: State for inventory aggregated data
  const [inventoryData, setInventoryData] = useState([]);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [showAllInventory, setShowAllInventory] = useState(false);
  const [files, setFiles] = useState([]);
const [loadingFiles, setLoadingFiles] = useState(false);
const [filesDialogOpen, setFilesDialogOpen] = useState(false);
const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
const [previewImage, setPreviewImage] = useState(null);
const [showFiles, setShowFiles] = useState(false);
const hoverTimeout = useRef(null);
const [anchorEl, setAnchorEl] = useState(null);
const [isPreviewPinned, setIsPreviewPinned] = useState(false);
const [loadingLabors, setLoadingLabors] = useState(false);
const [assignedLabors, setAssignedLabors] = useState([]);

const handlePopoverOpen = (event, file) => {
  // Only show preview for image/pdf types
  if (
    file.file_type.toLowerCase().includes("image") ||
    file.file_type.toLowerCase().includes("pdf")
  ) {
    // Clear any existing timeout (if applicable)
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setAnchorEl(event.currentTarget);
    setPreviewImage(file);
  }
};

const handlePopoverClose = () => {
  // Add a slight delay before closing
  hoverTimeout.current = setTimeout(() => {
    setAnchorEl(null);
    setPreviewImage(null);
  }, 1200);
};


  useEffect(() => {
    if (property && property.propertyid) {
      fetchSchedule();
      fetchEmployees();
      fetchInventoryData();
      fetchFiles();
      fetchLabors();
    }
  }, [property]);

  useEffect(() => {
    if (activeTab === 1 && property?.propertyid) {
      // fetchTasks();
    }
  }, [activeTab, property]);
  const showDialog = (message, type = "success") => {
    setDialogMessage(message);
    setDialogType(type);
    setDialogOpen(true);
};

  // const fetchTasks = async () => {
  //   setLoadingTasks(true);
  //   try {
  //     const response = await axios.get(
  //       `https://test.datso.io/properties/${property.propertyid}/tasks`
  //     );
  //     setTasks(response.data.tasks || []);
  //   } catch (error) {
  //     console.error("Error loading tasks:", error);
  //     setTasks([]);
  //   } finally {
  //     setLoadingTasks(false);
  //   }
  // };

  const fetchFiles = async () => {
    setLoadingFiles(true);
    try {
      const response = await axios.get(
        `https://test.datso.io/file/property/${property.propertyid}`
      );
      // Expected response: { files: [...] }
      setFiles(response.data.files || []);
    } catch (error) {
      console.error("Error fetching files:", error);
      setFiles([]);
    } finally {
      setLoadingFiles(false);
    }
  };
  const displayedFiles = files && files.length > 0 ? files[0].files : [];

  // Function to fetch assigned employees from backend
  const fetchEmployees = async () => {
    setLoadingEmployees(true);
    try {
      const response = await axios.get(
        `https://test.datso.io/properties-employee/${property.propertyid}`
      );
      // Expecting the API returns a JSON object with an "assignments" key.
      setAssignedEmployees(response.data.assignments || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setAssignedEmployees([]);
    } finally {
      setLoadingEmployees(false);
    }
  };

  const fetchLabors = async () => {
    setLoadingLabors(true);
    try {
      const response = await axios.get(
        `https://test.datso.io/properties-labor/${property.propertyid}`
      );
      // Expecting the API returns a JSON object with a "assignments" key.
      setAssignedLabors(response.data || []);
    } catch (error) {
      console.error("Error fetching labors:", error);
      setAssignedLabors([]);
    } finally {
      setLoadingLabors(false);
    }
  };
  


    // NEW: Function to fetch inventory aggregated data based on property_name and project_name.
    const fetchInventoryData = async () => {
      setLoadingInventory(true);
      try {
        // Adjust these property fields if necessary.
        const propName = property.property_name || property.name;
        const projName = property.project_name || property.projectid;
        const response = await axios.get(
          `https://test.datso.io/property-inventory/${propName}/project/${projName}`
        );
        setInventoryData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching inventory data:", error);
        setInventoryData([]);
      } finally {
        setLoadingInventory(false);
      }
    };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadDialogOpen(true);
    }
  };

  const uploadSchedule = async () => {
    if (!selectedFile) {
      showDialog("Schedule uploaded successfully!", "success");      
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile); // Correct field name as per FastAPI

    try {
      const response = await axios.post(
        `https://test.datso.io/schedule/upload/${property.propertyid}`, // Correct API URL
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }, // Required for file upload
        }
      );

      if (response.status === 200) {
        alert("Schedule uploaded successfully!");
        fetchSchedule();
        setUploadDialogOpen(false);
        setSelectedFile(null);
      }
    } catch (error) {
      console.error("Error uploading schedule:", error);

      // Extract response error if available
      if (error.response) {
        console.error("Server response:", error.response.data);
        showDialog(`Upload failed: ${error.response.data.detail || "Unknown error"}`, "error");

      } else {
        alert("Failed to upload schedule.");
      }
    }
  };

  useEffect(() => {
    if (!uploadDialogOpen) {
      fetchSchedule();
    }
  }, [uploadDialogOpen]);

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, "_blank", "noopener,noreferrer");
  };
  
  // File icon mapping and helper function
// 1) Update your helper to get the extension from file.file_name:
const getFileIcon = (file) => {
  // If no file name, fallback to a default icon
  if (!file?.file_name) {
    return <AiFillFile size={30} color="#757575" />;
  }

  // Extract the extension from the file name
  const extension = file.file_name.split('.').pop().toLowerCase();

  // Default icon if nothing matches
  let selectedIcon = { icon: AiFillFile, color: "#757575" };

  // Our mapping
  const fileIconMapping = [
    {
      extensions: ["jpg", "jpeg", "png", "gif", "bmp", "svg"],
      icon: AiFillFileImage,
      color: "#2196F3",
    },
    {
      extensions: ["draw","dwg"],
      icon: AiFillFileImage,
      color: "#9C27B0",
    },
    {
      // Include everything for Excel-like files
      extensions: ["xls", "xlsx", "csv", "ods", "excel", "spreadsheet", "sheet"],
      icon: AiFillFileExcel,
      color: "#4CAF50",
    },
    {
      extensions: ["pdf"],
      icon: AiFillFilePdf,
      color: "#F44336",
    },
  ];

  // Loop through the mapping
  fileIconMapping.forEach(mapping => {
    if (mapping.extensions.includes(extension)) {
      selectedIcon = mapping;
    }
  });

  const IconComponent = selectedIcon.icon;
  return <IconComponent size={30} color={selectedIcon.color} />;
};



  const fetchSchedule = async () => {
    setLoadingSchedule(true);
    try {
      const response = await axios.get(
        `https://test.datso.io/properties/${property.propertyid}/schedule`
      );
      setSchedule(response.data.schedule || []);
    } catch (error) {
      console.error("Unable to fetch schedule:", error);
      setSchedule([]);
    } finally {
      setLoadingSchedule(false);
    }
  };
  const refreshTasks = () => {
    fetchSchedule();  // ✅ Refresh schedule when a task is updated
};
  // Post function for adding multiple employees
  const handleAddSelectedEmployees = async (selectedEmployees) => {
    try {
      // Create payload. For example, if your API endpoint accepts an array of employee codes:
      const payload = {
        property_id: property.propertyid,
        employees: selectedEmployees.map(emp => emp.employee_code)
      };

      const response = await axios.post(
        "https://test.datso.io/properties-employee/bulk", // Adjust endpoint as needed
        payload
      );
      console.log(payload)
      if (response.status === 200) {
        showDialog("Employees added successfully!", "success");
        fetchEmployees();
      }
    } catch (error) {
      console.error("Error adding employees:", error);
      showDialog("Failed to add employees.", "error");
    }
  };
  const handleCreateSchedule = async () => {
    const newSchedule = {
      phasename: "New Phase", // Example phase name
      startdate: new Date().toISOString().split("T")[0], // Today's date
      enddate: new Date(new Date().setDate(new Date().getDate() + 7))
        .toISOString()
        .split("T")[0], // 7 days later
    };

    try {
      const response = await axios.post(
        `https://test.datso.io/properties/${property.propertyid}/schedule`,
        newSchedule
      );

      if (response.status === 200) {
        setSchedule((prevSchedule) => [...prevSchedule, response.data]); // Add new schedule
        fetchSchedule();
        showDialog("Schedule created successfully!", "success");
      }
    } catch (error) {
      console.error("Error creating schedule:", error);
      showDialog("Failed to create schedule.", "error");
    }
  };

  const handleDeleteSchedule = async () => {
    if (
      window.confirm("Are you sure you want to delete the entire schedule?")
    ) {
      try {
        await axios.delete(
          `https://test.datso.io/properties/${property.propertyid}/schedule`
        );
        setSchedule([]); // Clear schedule after deletion
        fetchSchedule();
        showDialog("Schedule deleted successfully.", "success");
      } catch (error) {
        console.error("Error deleting schedule:", error);
        showDialog("Failed to delete schedule.", "error");
      }
    }
  };

  useEffect(() => {
    if (activeTab === 1 && scheduleView === "gantt" && schedule.length > 0) {
      gantt.config.date_format = "%Y-%m-%d";
      gantt.init(ganttContainer.current);
      gantt.clearAll();
      gantt.parse({
        data: schedule.map((item, index) => ({
          id: index + 1,
          text: item.phasename,
          start_date: item.startdate,
          end_date: item.enddate,
        })),
      });
    }
  }, [activeTab, schedule, scheduleView]);

  
  return (
    <Dialog open={open} onClose={onClose} fullWidth marginTop="10px"  maxWidth="100%" height="100%">
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : property ? (
        <Box sx={{ backgroundColor: "#f9fafb", padding: 3 }}>
          {/* Header */}
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "black" }}>
              {property.name} - Details
            </Typography>
            <Button
              onClick={onClose}
              startIcon={<CloseIcon />}
              variant="outlined"
              color="error"
            >
              Close
            </Button>
          </Box>
          <Paper
  elevation={0}
  sx={{
    padding: 4,
    borderRadius: 3,
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: 4, // Bottom spacing
  }}
>
  {/* Title: Basic Information */}
  <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: "2px solid #e0e0e0", paddingBottom: 1, marginBottom: 3 }}>
  {/* Centered Typography */}
  <Grid item xs={10.5} display="flex" justifyContent="center">
    <Typography
      variant="h6"
      sx={{
        fontWeight: 700,
        color: "#1f2937",
        textAlign: "center",
        marginLeft:20
      }}
    >
      Basic Information
    </Typography>
  </Grid>

  {/* Right-Aligned Button */}
  {/* <Grid item xs={1.5} display="flex" justifyContent="flex-end">
    <Button
      variant="contained"
      color="primary"
      sx={{
        textTransform: "none",
        fontWeight: 600,
        borderRadius: "6px",
        padding: "8px 16px",
        
          backgroundColor: "#2A3663",
          "&:hover": { backgroundColor: "#1E2A48" },
        
      }}
      onClick={() => setAssignEmployeeDialogOpen(true)}
    >
      ASSIGN EMPLOYEES
    </Button>
  </Grid> */}
</Grid>


  {/* Property Details */}
  <Grid container spacing={3} sx={{ alignItems: "flex-start", marginBottom: 3 }}>
  
  {/* Left Section: Property Details */}
  <Grid item xs={12} md={12}>
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 2,
        paddingBottom: 3,
      }}
    >
     <Grid container spacing={2}>
  {/* Row 1: Code and Name */}
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={4}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Code:</strong> 
        <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
  {property.propertyid || "N/A"}
</Typography>
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Name:</strong> 
      </Typography>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
  {property.name  || "N/A"}
</Typography>
    </Grid>



    <Grid item xs={4}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Type:</strong> 
      </Typography>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
  {property.type  || "N/A"}
</Typography>
      
    </Grid>
  </Grid>

  {/* Row 2: Remarks and Location */}
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={4}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Remarks:</strong> 
      </Typography>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        {property.remarks || "N/A"}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Dimensions:</strong> 
      </Typography>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
         {property.dimensions || "N/A"}
      </Typography>
    
    </Grid>
    <Grid item xs={4}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Subtype:</strong> 
      </Typography>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
      {property.subtype || "N/A"}
      </Typography>
    </Grid>
  </Grid>

  {/* Row 3: Status */}
  <Grid container item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        <strong>Status:</strong>{" "}
        <Chip
          label={(property.status).toUpperCase()}
          sx={{
            backgroundColor:
              property.status === "Available"
                ? "#c1f5c1"
                : property.status === "Sold"
                ? "#f5c69d"
                : property.status === "Under Construction"
                ? "#ccccff"
                : "#9a9c9a",
            color:
              property.status === "Available"
                ? "#4caf50"
                : property.status === "Sold"
                ? "#f0882e"
                : property.status === "Under Construction"
                ? "#0000cc"
                : "#646664",
            fontWeight: "bold",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        />
      </Typography>
    </Grid>
  </Grid>
</Grid>

    </Box>
  </Grid>

  {/* Right Section: Assigned Employees */}
  <Grid item xs={12} md={6}>
    <Box sx={{ backgroundColor: "#f9fafb", padding: 2, borderRadius: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 700, color: "#1f2937" }}>
          Assigned Employees
        </Typography>
        <Button
      variant="contained"
      color="primary"
      sx={{
        textTransform: "none",
        fontWeight: 600,
        borderRadius: "6px",
        padding: "8px 16px",
        
          backgroundColor: "#2A3663",
          "&:hover": { backgroundColor: "#1E2A48" },
        
      }}
      onClick={() => setAssignEmployeeDialogOpen(true)}
    >
      ASSIGN EMPLOYEES
    </Button>
      </Box>

      <Box sx={{ minHeight: "300px",maxHeight: "300px", overflowY: "auto" }}>
  {loadingEmployees ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="50px">
      <CircularProgress size={20} />
    </Box>
  ) : assignedEmployees.length === 0 ? (
    <Typography sx={{ color: "text.secondary", fontStyle: "italic", fontSize: "0.85rem" }}>
      No employees assigned.
    </Typography>
  ) : (
    assignedEmployees.map((emp) => (
      <Box key={emp.assignment_id} sx={{ p: 1, borderRadius: 1, fontSize: "0.5rem" }}>
        <Typography variant="body2" sx={{ fontWeight: 500, fontSize: "0.9rem" }}>
          {emp.first_name ? `${emp.first_name} - ${emp.department}` : emp.department}
        </Typography>
      </Box>
    ))
  )}
</Box>


    </Box>
  </Grid>

  <Grid item xs={12} md={6}>
  <Box sx={{ backgroundColor: "#f9fafb", padding: 2, borderRadius: 2 }}>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 700, color: "#1f2937" }}>
        Assigned Labors
      </Typography>
    </Box>

    <Box sx={{ minHeight: "300px",maxHeight: "300px", overflowY: "auto" }}>
  {loadingLabors ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="50px">
      <CircularProgress size={20} />
    </Box>
  ) : assignedLabors.length === 0 ? (
    <Typography sx={{ color: "text.secondary", fontStyle: "italic", fontSize: "0.85rem" }}>
      No labors assigned.
    </Typography>
  ) : (
    assignedLabors.map((labor) => (
      <Box key={labor.assignment_id} sx={{ p: 1, borderRadius: 1, fontSize: "0.5rem" }}>
        <Typography variant="body2" sx={{ fontWeight: 500, fontSize: "0.9rem" }}>
          {labor.name} - {labor.work_type}
        </Typography>
      </Box>
    ))
  )}
</Box>

  </Box>
</Grid>


</Grid>

 {/* Files Section */}
<Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 2, color: "#1f2937", textAlign: "center", borderBottom: "2px solid #e0e0e0", paddingBottom: 1 }}>
  Documents
</Typography>
{loadingFiles ? (
  <Box display="flex" justifyContent="center" alignItems="center" height="100px">
    <CircularProgress />
  </Box>
) : displayedFiles.length === 0 ? (
  <Typography sx={{ textAlign: "center", fontStyle: "italic", color: "text.secondary" }}>
    No Documents found.
  </Typography>
) : (
  <>
    <Grid container spacing={2} justifyContent="flex-start">
      {displayedFiles.slice(0, 10).map((file) => {
        if (!file) return null;
        const fileType = (file.file_type || "").toLowerCase();
        // let fileIcon;

        const fileIcon = getFileIcon(file);


        return (
<Grid item xs={1.2} key={file.file_id}>
  {/* 1) Outer container: relative positioning, so icon can be placed absolutely */}
  <Box sx={{ 
    position: "relative", 
    width: 90, 
    height: 100, 
    margin: "auto",
    
  }}
  onClickCapture={() => handleDownload(file.file_url)}

 
  >
    {/* 2) The file card that can scale on hover */}
    <Box
      sx={{
        borderRadius: "8px",
        padding: 1,
        // backgroundColor: "#ffffff",
        textAlign: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        // boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease-in-out",
        // If you do NOT want the card to scale, remove the lines below
        "&:hover": {
          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          transform: "scale(1.05)",
        },
      }}
      onClickCapture={() => handleDownload(file.file_url)}
    >
      {/* The file's main icon (PDF, image, etc.) */}
      {fileIcon}

      {/* File name */}
      <Typography
        sx={{
          fontSize: "10px",
          marginTop: "5px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "80px",
        }}
      >
        {file.file_name}
      </Typography>
    </Box>

    {/* 3) The visibility icon as a sibling, absolutely positioned */}
    {(fileType.includes("image") || fileType.includes("pdf")) && (
      <Box
        className="previewOverlay"
        sx={{
          display: "none", // hidden by default
          position: "absolute",
          top: 5,
          right: 5,
          zIndex: 2,
        }}
      >
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setPreviewImage(file);
            setPreviewDialogOpen(true);
          }}
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Box>
    )}
  </Box>
</Grid>



        
        );
      })}

    </Grid>
    {displayedFiles.length > 10 && (
      <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2}sx={{ width: "100%" }}>
        <Button onClick={() => setFilesDialogOpen(true)} variant="contained" sx={{
            backgroundColor: "#2A3663",
            "&:hover": { backgroundColor: "#1E2A48" },
          }}>
          More Documents
        </Button>
      </Box>
    )}
  </>
)}

{/* Files Dialog for All Files */}
<Dialog 
  open={filesDialogOpen} 
  onClose={() => setFilesDialogOpen(false)} 
  fullWidth 
  maxWidth="xl"
  sx={{ "& .MuiDialog-paper": { width: "95vw", maxHeight: "80vh", position: "relative" } }} /* ✅ Larger Dialog */
>
  <Box sx={{ padding: 3, position: "relative" }}>
    {/* Close Button at top right */}
    <IconButton
      onClick={() => setFilesDialogOpen(false)}
      sx={{
        position: "absolute",
        top: 10,
        right: 10,
        color: "darkgrey.500",
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 2, textAlign: "center" }}>
      All Documents
    </Typography>
    
    {/* ✅ Add Vertical Scroll for Large File Lists */}
    <Box sx={{ maxHeight: "60vh", overflowY: "auto", padding: "10px" }}> 
      <Grid container spacing={2} justifyContent="flex-start">
        {displayedFiles.map((file) => {
          if (!file) return null;
          const fileType = (file.file_type || "").toLowerCase();
        // let fileIcon;

          // if (fileType.includes("image")) {
          //   fileIcon = <InsertPhotoIcon sx={{ fontSize: 30, color: "grey" }} />;
          // } else if (fileType.includes("excel") || fileType.includes("spreadsheet") || fileType.includes("csv")) {
          //   fileIcon = <GridOnIcon sx={{ fontSize: 30, color: "grey" }} />;
          // } else if (fileType.includes("pdf")) {
          //   fileIcon = <PictureAsPdfIcon sx={{ fontSize: 30, color: "grey" }} />;
          // } else {
          //   fileIcon = <InsertDriveFileIcon sx={{ fontSize: 30, color: "grey" }} />;
          // }
          const fileIcon = getFileIcon(file);


          return (
            <Grid item xs={1.2} key={file.file_id}>
            {/* 1) Outer container: relative positioning, so icon can be placed absolutely */}
            <Box sx={{ 
              position: "relative", 
              width: 90, 
              height: 100, 
              margin: "auto",
            }}
            onClickCapture={() => handleDownload(file.file_url)}

            
            
                    >
              {/* 2) The file card that can scale on hover */}
              <Box
  sx={{
    borderRadius: "8px",
    padding: 1,
    backgroundColor: "#ffffff",
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  }}
>
  {/* Icon Click for Preview */}
  <Box
    onClick={(e) => {
      e.stopPropagation();
      setPreviewImage(file);
      setPreviewDialogOpen(true);
    }}
  >
    {fileIcon}
  </Box>

  {/* File Name Click for Download */}
  <Typography
    onClick={(e) => {
      e.stopPropagation();
      handleDownload(file.file_url);
    }}
    sx={{
      fontSize: "10px",
      marginTop: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80px",
      color: "#1976d2",
      textDecoration: "underline",
      cursor: "pointer",
    }}
  >
    {file.file_name}
  </Typography>
</Box>


            </Box>
          </Grid>
          
          
          );
        })}
      </Grid>
    </Box>
  </Box>
</Dialog>





{/* <Typography
  variant="h6"
  sx={{
    fontWeight: 700,
    marginBottom: 2,
    color: "#1f2937",
    textAlign: "center",
    borderBottom: "2px solid #e0e0e0",
    paddingBottom: 1,
  }}
>
  Inventory Details
</Typography>
{loadingInventory ? (
  <Box display="flex" justifyContent="center" alignItems="center" height="100px">
    <CircularProgress />
  </Box>
) : inventoryData.length === 0 ? (
  <Typography sx={{ textAlign: "center", fontStyle: "italic", color: "text.secondary" }}>
    No inventory records found.
  </Typography>
) : (
  <>
    <Table
      sx={{
        border: "1px solid #1976d2",
        borderRadius: "4px",
        overflow: "hidden", // ensures the border radius applies to the table header too
      }}
    >
      <TableHead sx={{ backgroundColor: "#1976d2" }}>
        <TableRow>
          <TableCell sx={{ color: "white", fontWeight: "bold" }}>
            <strong>Item Name</strong>
          </TableCell>
          <TableCell align="right" sx={{ color: "white", fontWeight: "bold" }}>
            <strong>Raised</strong>
          </TableCell>
          <TableCell align="right" sx={{ color: "white", fontWeight: "bold" }}>
            <strong>Issued</strong>
          </TableCell>
          <TableCell align="right" sx={{ color: "white", fontWeight: "bold" }}>
            <strong>Rejected</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(showAllInventory ? inventoryData : inventoryData.slice(0, 4)).map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.item_name}</TableCell>
            <TableCell align="right">{item.raised}</TableCell>
            <TableCell align="right">{item.issued}</TableCell>
            <TableCell align="right">{item.rejected}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {inventoryData.length > 4 && (
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
        <Button onClick={() => setShowAllInventory(!showAllInventory)} variant="contained">
          {showAllInventory ? "Show Less" : "Show All"}
        </Button>
      </Box>
    )}
  </>
)} */}




  {/* Schedule Section */}
  <Typography
    variant="h6"
    sx={{
      fontWeight: 700,
      marginBottom: 3,
      color: "#1f2937",
      textAlign: "center",
      borderBottom: "2px solid #e0e0e0",
      paddingBottom: 1,
    }}
  >
    Schedule
  </Typography>

  {/* Toggle Schedule View */}
  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
  
  {/* Left Section: Delete Entire Schedule Button */}
  {scheduleView === "gantt" && schedule.length > 0 && (
    <Button variant="contained" color="error" onClick={handleDeleteSchedule}>
      Delete Schedule
    </Button>
  )}
{schedule.length === 0 && (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, mb: 2 }}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => setShowSchedulePiP(true)} // Open SchedulePIP Dialog
      sx={{ textTransform: "none", fontWeight: 600, borderRadius: "6px", padding: "8px 16px" }}
    >
      Create Schedule
    </Button>

    {/* <Button
      variant="contained"
      color="secondary"
      onClick={() => setUploadDialogOpen(true)} // Open Upload Dialog
      sx={{ textTransform: "none", fontWeight: 600, borderRadius: "6px", padding: "8px 16px" }}
    >
      Upload Schedule
    </Button> */}
  </Box>
)}


</Box>


  {/* Schedule Content */}
         {scheduleView === "gantt" ? (
              <Box
                sx={{
                  width: "75%", // Expands width to 90% of the available space
                  maxWidth: "1400px", // Ensures it doesn't become too wide
                  marginX: "auto", // Centers it horizontally
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
<GanttChart schedule={schedule} refreshTasks={refreshTasks} />              </Box>

  ) : (
    <Paper
    sx={{
      padding: 3,
      borderRadius: 2,
      backgroundColor: "white",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      maxWidth: "90%", // Reduce task manager width
      marginX: "auto", // Center the Task Manager
    }}>
      {/* Schedule Actions (Conditional) */}


{loadingTasks ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <CircularProgress />
        </Box>
      ) 
      : 
      (
        <TaskManager propertyId={property.propertyid} tasks={tasks} />
      )
      }
    </Paper>
  )}

  {/* Inventory Management */}
  {/* <Paper
    sx={{
      padding: 0,
      borderRadius: 0,
      backgroundColor: "transparent",
    }}
  >
    {loadingTasks ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
        <CircularProgress />
      </Box>
    ) : (
      <InventoryManagement taskId={property.propertyid} projectId={property.projectid} propertyId={property.propertyid} />
    )}
  </Paper> */}
</Paper>

        </Box>
      ) : (
        <Box padding={4}>
          <Typography variant="body1">Property not found.</Typography>
        </Box>
      )}

      {showSchedulePiP && (
        <SchedulePIP
        open={showSchedulePiP}
        onClose={() => {
          console.log("Closing SchedulePIP");
          setShowSchedulePiP(false);
          fetchSchedule(); // Refresh the schedule after closing
        }}
        property={property}
      />
      
      )}

      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <Box
          sx={{
            padding: 3,
            textAlign: "center",
            position: "relative",
            backgroundColor: "white",
            borderRadius: "14px",
          }}
        >
          {/* Dialog Title */}
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: 2, color: "#374151" }}
          >
            Upload Schedule File
          </Typography>

          {/* Close Button (Top-Right) */}
          <Button
            onClick={() => setUploadDialogOpen(false)}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              minWidth: "32px",
              height: "32px",
              borderRadius: "50%",
              backgroundColor: "#f5f5f5",
              color: "red",
              "&:hover": { backgroundColor: "#e0e0e0" },
            }}
          >
            <CloseIcon fontSize="small" />
          </Button>

          {/* File Input */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              marginTop: 3,
            }}
          >
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".csv, .xlsx, .json"
              style={{ display: "none" }}
              id="upload-input"
            />
            <label htmlFor="upload-input">
              <Button
                variant="contained"
                component="span"
                sx={{ textTransform: "none", padding: "10px 20px" }}
              >
                Choose File
              </Button>
            </label>

            {/* Show Selected File Name with Remove Option */}
            {selectedFile && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  border: "1px solid #ccc",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#374151" }}
                >
                  {selectedFile.name}
                </Typography>
                <Button
                  variant="text"
                  color="error"
                  size="small"
                  onClick={() => setSelectedFile(null)}
                  sx={{
                    minWidth: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#ffebee",
                    "&:hover": { backgroundColor: "#ffcdd2" },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </Button>
              </Box>
            )}

            {/* Upload Button */}
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 2, padding: "10px 20px", textTransform: "none" }}
              onClick={uploadSchedule}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Integrate the AssignEmployeeDialog */}
      <AssignEmployeeDialog
        open={assignEmployeeDialogOpen}
        onClose={() => setAssignEmployeeDialogOpen(false)}
        onEmployeeSelect={(selectedEmps) => {
          // Call the post function to add multiple employees
          handleAddSelectedEmployees(selectedEmps);
          setAssignEmployeeDialogOpen(false);
        }}
      />

{/* <Dialog
  open={filesDialogOpen}
  onClose={() => setFilesDialogOpen(false)}
  fullWidth
  maxWidth="md"
>
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 2, textAlign: "center" }}>
      All Files
    </Typography>
    <Grid container spacing={2}>
  {files.slice(0, 10).map((file) => {
    // Skip if file is undefined
    if (!file) return null;

    // Ensure file_type is a string
    const fileType = file.file_type ?? "";

    let fileContent;
    if (fileType.startsWith("image")) {
      fileContent = (
        <img
          src={file.file_url}
          alt={file.file_name}
          style={{ maxWidth: "100%", maxHeight: "100px", objectFit: "cover" }}
        />
      );
    } else if (
      fileType.includes("excel") ||
      fileType.includes("spreadsheet") ||
      fileType.includes("csv")
    ) {
      fileContent = (
        <Typography variant="caption">
          Excel: {file.file_name}
        </Typography>
      );
    } else if (fileType.includes("pdf")) {
      fileContent = (
        <Typography variant="caption">
          PDF: {file.file_name}
        </Typography>
      );
    } else {
      fileContent = (
        <Typography variant="caption">
          {file.file_name}
        </Typography>
      );
    }
    return (
      <Grid item xs={2.4} key={file.file_id}>
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            padding: 1,
            textAlign: "center",
          }}
        >
          {fileContent}
        </Box>
      </Grid>
    );
  })}
</Grid>


    <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
      <Button onClick={() => setFilesDialogOpen(false)} variant="contained">
        Close
      </Button>
    </Box>
  </Box>
</Dialog> */}

<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
    <Box sx={{ padding: 3, textAlign: "center" }}>
        <Typography variant="h6" sx={{ color: dialogType === "success" ? "green" : "red" }}>
            {dialogMessage}
        </Typography>
        <Button 
            onClick={() => setDialogOpen(false)} 
            variant="contained" 
            sx={{ marginTop: 2 }}
        >
            OK
        </Button>
    </Box>
</Dialog>
{/* NEW: Image Preview Dialog */}
{/* <Dialog
  open={previewDialogOpen}
  onClose={() => {
    setPreviewDialogOpen(false);
    setIsPreviewPinned(false); // Reset pin state on close
    setPreviewImage(null);
  }}
  maxWidth="md"
  fullWidth
>
  <Box sx={{ padding: 3, textAlign: "center", position: "relative" }}>
    <IconButton
      onClick={() => {
        setPreviewDialogOpen(false);
        setIsPreviewPinned(false);
        setPreviewImage(null);
      }}
      sx={{
        position: "absolute",
        top: 10,
        right: 10,
        color: "darkgrey.500",
      }}
    >
      <CloseIcon />
    </IconButton>
    {previewImage && (
      <>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          {previewImage.file_name}
        </Typography>
        {previewImage.file_type.toLowerCase().includes("pdf") ? (
          <iframe
            src={previewImage.file_url}
            title={previewImage.file_name}
            style={{ width: "100%", height: "80vh", border: "none" }}
          />
        ) : (
          <img
            src={previewImage.file_url}
            alt={previewImage.file_name}
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        )}
      </>
    )}
  </Box>
</Dialog> */}





    </Dialog>
    
    
    
  );
};

export default PropertyDetailsDialog;