import React, { useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskManager from "./TaskManager"; // Import TaskManager
import { Pending } from "@mui/icons-material";

// Define status-based colors globally to maintain consistency
const statusColors = {
  Completed: "#ace1af",    // pastel green
  Hold: "#C0C0C0",         // pastel teal-blue
  Pending: "#fff5ba",      // pastel amber yellow
  "In Progress": "#a3d1ff",// pastel blue
  Delayed: "#ffb3ba",      // pastel red
};

const statusTextColor = {
  Completed: "#013220",    // dark text for readability on pastel green
  HOLD: "black",         // dark text for visibility
  Pending: "black",      // dark text works well on light yellow
  "In Progress": "black",// dark text for contrast on pastel blue
  Delayed: "black",      // dark text for clarity on pastel red
};

const statusBorderColor = {
  Completed: "#ACE1AF",    // slightly darker pastel green
  Hold: "#C0C0C0",         // darker pastel teal-blue
  Pending: "#e6e19d",      // darker pastel amber
  "In Progress": "#7bb0e6",// darker pastel blue
  Delayed: "#e6999d",      // darker pastel red
};


const localizer = momentLocalizer(moment);

const CalendarView = ({ tasks, propertyId,refreshTasks }) => {
  const [viewMode, setViewMode] = useState(Views.MONTH);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskManagerOpen, setTaskManagerOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All"); // Task status filter

  // Convert tasks into Big Calendar format
  const eventList = tasks.map((task) => ({
    id: task.scheduleid,
    title: task.phasename,
    start: new Date(task.startdate),
    end: new Date(task.enddate),
    status: task.status,
  }));

  // Open TaskManager with a specific task
  const openTaskManager = (task) => {
    const formattedTask = {
      scheduleid: task.id || task.scheduleid,
      phasename: task.title || task.phasename,
      startdate: (task.start || task.startdate).toISOString().split("T")[0],
      enddate: (task.end || task.enddate).toISOString().split("T")[0],
      status: task.status,
      remarks: task.remarks || null,
    };

    setSelectedTask(formattedTask);
    setTaskManagerOpen(true);
  };

  // Filter tasks based on status selection
  const filteredTasks =
    statusFilter === "All"
      ? tasks
      : tasks.filter((task) => task.status === statusFilter);

  return (
    <Box sx={{ p: 3, backgroundColor: "white", borderRadius: 2 }}>
      <Calendar
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        views={{ month: true, week: true, day: true }}
        view={viewMode}
        onView={(view) => setViewMode(view)}
        style={{ height: "600px", borderRadius: "10px" }}
        components={{ event: CustomEventTooltip }}
        onDoubleClickEvent={openTaskManager} // Open TaskManager on double-click
      />

      {/* Task Manager Dialog with Task List on Left */}
      <Dialog
        open={taskManagerOpen}
        onClose={() => setTaskManagerOpen(false)}
        fullWidth
        maxWidth="lg"
      >
<DialogTitle sx={{ display: "flex", alignItems: "center" }}>
  <IconButton
    onClick={() => setTaskManagerOpen(false)}
    color="error"
    sx={{ ml: 'auto' }} // pushes the icon to the right
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>


        <DialogContent>
          <Grid container spacing={3}>
            {/* Left Panel: Task List + Filter */}
            <Grid item xs={3}>
              <Paper sx={{ p: 2, height: "1500px", overflowY: "auto", borderRadius: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  All Tasks
                </Typography>

                {/* Status Filter */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Status Filter</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    label="Status Filter"
                  >
                    <MenuItem value="All">All</MenuItem>
                    {Object.keys(statusColors).map((status) => (
                      <MenuItem key={status} value={status}>
                        {status.replace(/_/g, " ")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Task List */}
                <List>
                  {filteredTasks.map((task) => {
                    const bgColor = statusColors[task.status] || "#ffffff";
                    const textColor = statusTextColor[task.status] || "#000";
                    const borderColor = statusBorderColor[task.status] || "#ddd";

                    return (
                      <ListItem
                        key={task.scheduleid}
                        button
                        onClick={() => setSelectedTask(task)} // Clicking updates TaskManager
                        sx={{
                          mb: 1,
                          border: `2px solid ${borderColor}`,
                          borderRadius: 1,
                          p: 1,
                          backgroundColor: task.scheduleid === selectedTask?.scheduleid ? "#f0f8ff" : bgColor,
                          cursor: "pointer",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography sx={{ color: textColor, fontWeight: "bold" }}>
                              {task.phasename}
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ color: textColor }}>
                              {task.status.replace(/_/g, " ")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>

            {/* Right Panel: TaskManager */}
            <Grid item xs={9}>
              {selectedTask &&                <TaskManager
                    propertyId={propertyId}
                    initialTask={selectedTask}
                    refreshTasks={refreshTasks} // ✅ Pass refresh function
                />
            }
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

// Custom Tooltip for Calendar Events
const CustomEventTooltip = ({ event }) => {
  const bgColor = statusColors[event.status] || "inherit";
  const textColor = statusTextColor[event.status] || "#000";

  return (
    <Tooltip title={`${event.title} | ${event.status.replace(/_/g, " ")}`} arrow>
      <Typography
        variant="body2"
        sx={{
          cursor: "pointer",
          backgroundColor: bgColor,
          padding: "2px 5px",
          borderRadius: "4px",
          color: textColor,
        }}
      >
        {event.title}
      </Typography>
    </Tooltip>
  );
};

export default CalendarView;
