import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const InventoryPage = ({ propertyId, onClose }) => {
  const [uploading, setUploading] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default: ascending
  const [showChart, setShowChart] = useState(false); // Toggle chart view

  const fetchInventory = async () => {
    setLoadingInventory(true);
    try {
      const res = await fetch(`https://test.datso.io/inventory/property/${propertyId}`);
      
      if (!res.ok) throw new Error("Failed to load inventory");
  
      const data = await res.json();
  
      console.log("Fetched inventory data:", data); // Debugging log
  
      if (!data || !data.inventory || data.inventory.length === 0) {
        setInventory([]); 
        setFilteredInventory([]); 
        return;
      }
  
      setInventory(data.inventory);
      setFilteredInventory(data.inventory);  // ✅ Ensure state is updated correctly
    } catch (err) {
      console.error("Error fetching inventory:", err);
      setSnackbar({ open: true, message: err.message, severity: "error" });
    } finally {
      setLoadingInventory(false);
    }
  };

  const handleSortChange = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  
    const sortedData = [...filteredInventory].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? 1 : -1;
      if (a[field] > b[field]) return isAsc ? -1 : 1;
      return 0;
    });
  
    setFilteredInventory(sortedData);
  };
  
  

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    console.log('Selected file:', file.name, file.size, 'bytes for property:', propertyId);

    if (!/\.(xls|xlsx)$/i.test(file.name)) {
      setSnackbar({ open: true, message: 'Only Excel files (.xls, .xlsx) are allowed.', severity: 'error' });
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('propertyId', propertyId);

    try {
      console.log('Uploading to:', 'https://test.datso.io/inventory/property/upload');
      const response = await fetch('https://test.datso.io/inventory/property/upload', {
        method: 'POST',
        body: formData,
      });
      console.log('Response status:', response.status, response.statusText);
      const text = await response.text();
      console.log('Response body:', text);

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status} ${response.statusText}`);
      }

      setSnackbar({ open: true, message: 'Inventory uploaded successfully!', severity: 'success' });
      await fetchInventory();
    } catch (err) {
      console.error('Upload error:', err);
      setSnackbar({ open: true, message: err.message, severity: 'error' });
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchInventory();
    }
  }, [propertyId]);
  
const [searchQuery, setSearchQuery] = useState('');  // ✅ Ensure state is defined

const handleSearchChange = (event) => {
  const query = event.target.value.toLowerCase().trim();
  setSearchQuery(query);

  if (!query) {
    setFilteredInventory(inventory); // Reset if search is empty
    return;
  }

  const filteredData = inventory.filter((item) =>
    ["item", "unit", "category", "limit_quantity", "used_quantity"].some((key) =>
      item[key]?.toString().toLowerCase().includes(query)
    )
  );

  setFilteredInventory(filteredData);
};


  return (
    <Box sx={{ position: 'relative', p: 4, backgroundColor: 'white', minHeight: '100vh' }}>
      <Button
  variant="outlined"
  onClick={onClose}
  sx={{
    position: 'absolute',
    top: 16,
    right: 16,
    borderColor: "red",
    color: "red",
    
    
  }}
>
<CloseIcon sx={{ color: "red" }} />
  Close
</Button>

      {/* Header Section */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2,mt:6 }}>
  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#2C3E50" }}>
    Inventory for {propertyId}
  </Typography>

  {/* <Button
            variant="contained"
            onClick={() => setShowChart(!showChart)}
            sx={{
              backgroundColor: "#2A3663",
              "&:hover": { backgroundColor: "#1E2A48" },
            }}
          >
            {showChart ? 'Hide Chart' : 'Show Chart'}
          </Button> */}

  <Box sx={{ display: 'flex', gap: 2 }}>
    <TextField
      placeholder="Search Item, Unit, Category..."
      variant="outlined"
      value={searchQuery}
      onChange={handleSearchChange}
      sx={{
        width: "350px",
        backgroundColor: "#ffffff",
        borderRadius: "25px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "25px",
          height: "36px",
          padding: "0",
          "& input": {
            padding: "8px 12px",
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon sx={{ color: "#2C3E50" }} />
          </InputAdornment>
        ),
      }}
    />

    <Button
      variant="contained"
      component="label"
      startIcon={<UploadFileIcon />}
      disabled={uploading}
      sx={{
        backgroundColor: "#2A3663",
        "&:hover": { backgroundColor: "#1E2A48" },
      }}
    >
      {uploading ? (
        <>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          Uploading...
        </>
      ) : 'Upload Inventory'}
      <input type="file" accept=".xls,.xlsx" hidden onChange={handleFileChange} />
    </Button>
  </Box>
</Box>


      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {/* Render Bar Chart if toggled */}
      {showChart && filteredInventory.length > 0 && (
        <Box sx={{ width: '100%', height: 300, mt: 2 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={filteredInventory}>
              <XAxis dataKey="item" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="item" fill="#8884d8" name="item" />

              <Bar dataKey="limit_quantity" fill="#8884d8" name="Limit Quantity" />
              <Bar dataKey="used_quantity" fill="#82ca9d" name="Used Quantity" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {loadingInventory ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : filteredInventory.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 4, textAlign: 'center' }}>No items found.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
          <TableHead>
  <TableRow sx={{ backgroundColor: "#2A3663" }}>
    <TableCell
      sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => handleSortChange("item")}
    >
      Item {sortField === "item" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
    </TableCell>
    <TableCell
      sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => handleSortChange("unit")}
    >
      Unit {sortField === "unit" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
    </TableCell>
    <TableCell
      sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => handleSortChange("category")}
    >
      Category {sortField === "category" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
    </TableCell>
    <TableCell
      sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => handleSortChange("limit_quantity")}
    >
      Limit Quantity {sortField === "limit_quantity" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
    </TableCell>
    <TableCell
      sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
      onClick={() => handleSortChange("used_quantity")}
    >
      Used Quantity {sortField === "used_quantity" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
    </TableCell>
  </TableRow>
</TableHead>
            <TableBody>
            {filteredInventory.map(row => (
                <TableRow key={row.inventory_id}>
                  {/* <TableCell>{row.inventory_id}</TableCell> */}
                  <TableCell>{row.item}</TableCell>
                  <TableCell>{row.unit}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell >{row.limit_quantity}</TableCell>
                  <TableCell >{row.used_quantity}</TableCell>
                  {/* <TableCell>{new Date(row.created_at).toLocaleString()}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default InventoryPage;