import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashBoardPage({ setFormType }) {
  /** ✅ STATE MANAGEMENT */
  const [bills, setBills] = useState([]); // Stores latest "Ready for Review" invoices
  const [statusCounts, setStatusCounts] = useState({}); // Stores invoice counts for Pie Chart
  const [roles, setRoles] = useState([]); // Stores user roles from Permit.io
  const [loading, setLoading] = useState(true); // ✅ Controls loading state

  /** ✅ GET CACHED ROLES INSTANTLY & FETCH UPDATED ROLES */
  useEffect(() => {
    const cachedRoles = JSON.parse(localStorage.getItem("roles")) || [];
    setRoles(cachedRoles); // ✅ Load tiles instantly

    const fetchRoles = async () => {
      try {
        const email = localStorage.getItem("email");
        if (!email) return;

        const response = await fetch(`https://test.datso.io/roles?email=${email}`);
        if (!response.ok) throw new Error("Failed to fetch roles");
        const data = await response.json();

        localStorage.setItem("roles", JSON.stringify(data.role));
        setRoles(data.role);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  /** ✅ FETCH INVOICE DATA */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [billsRes, statusRes] = await Promise.all([
          fetch("https://test.datso.io/latest-ready-for-review-invoices"),
          fetch("https://test.datso.io/invoice-status-count"),
        ]);

        if (!billsRes.ok || !statusRes.ok) throw new Error("Failed to fetch data");

        const billsData = await billsRes.json();
        const statusData = await statusRes.json();

        setBills(billsData.latest_ready_for_review_invoices || []);
        setStatusCounts(statusData.bill_status_counts || {});
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false); // ✅ Stop loading once all data is fetched
      }
    };

    fetchData();
  }, []);

  /** ✅ MAP STATUS COLORS TO PIE CHART */
  const statusColors = {
    READY_FOR_REVIEW: "#FFCC99", // Orange
    REVIEWED: "#CCCCFF", // Blue
    PROCESSED: "#CCFFCC", // Green
    REJECTED: "#FFAD99", // Red
  };

  /** ✅ PIE CHART CONFIGURATION */
  const chartData = useMemo(() => ({
    labels: Object.keys(statusCounts),
    datasets: [
      {
        data: Object.values(statusCounts),
        backgroundColor: Object.keys(statusCounts).map(
          (status) => statusColors[status] || "#D3D3D3"
        ),
        hoverBackgroundColor: Object.keys(statusCounts).map(
          (status) => statusColors[status] || "#A9A9A9"
        ),
        borderWidth: 1,
      },
    ],
  }), [statusCounts]);

  /** ✅ ROLE-BASED CARDS LOGIC */
  const tabPermissions = {
    admin: ["properties", "invoices", "inventory", "onboarding", "manpower"],
    Stock_Management_Team: ["inventory", "invoices"],
    Finance_Team: ["onboarding", "invoices"],
    Backend_Team: ["properties", "invoices"],
  };

  const allCards = {
    properties: {
      label: "Projects",
      img: "building.png",
      color: "#FFEEF3",
      action: () => setFormType("Create New Land Deal"),
    },
    invoices: {
      label: "Invoices",
      img: "invoice.png",
      color: "#E6F7FF",
      action: () => setFormType("View Bills"),
    },
    inventory: {
      label: "Inventory",
      img: "inventory.png",
      color: "#E6FFF2",
      action: () => setFormType("View Inventory"),
    },
    onboarding: {
      label: "OnBoarding",
      img: "onboarding.png",
      color: "#FFF7E6",
      action: () => setFormType("View OnBoarding"),
    },
    manpower: {
      label: "ManPower",
      img: "manpower.png",
      color: "#FFF7E6",
      action: () => setFormType("View ManPower"),
    },
  };

  const userCards = useMemo(() => {
    const allowedCards = new Set();
    roles.forEach((role) => {
      const roleTabs = tabPermissions[role] || [];
      roleTabs.forEach((tab) => allowedCards.add(allCards[tab]));
    });
    return Array.from(allowedCards);
  }, [roles]);


  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: "0%",
        borderRadius: "15px",
        backgroundColor: "#F4F6F9",
        padding: 4,
      }}
    >
      {/* ✅ ROLE-BASED CARDS SECTION */}
      <Grid container spacing={0} justifyContent="center" mb={5}>
        {userCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <Card
              onClick={card.action}
              sx={{
                cursor: "pointer",
                width:"200px",
                borderRadius: "12px",
                background: "white",
                transition: "transform 0.2s",
                "&:hover": { transform: "scale(1.05)" },
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <CardMedia
                  component="img"
                  image={`https://avenuenxt.s3.ap-southeast-1.amazonaws.com/${card.img}`}
                  sx={{
                    height: "70px",
                    width: "70px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ mt: 2, fontWeight: "bold", color: "#2A3663" }}
                >
                  {card.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* ✅ INVOICE LIST */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: "12px", background: "white" }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", color: "#2A3663" }}
              >
                Bills List
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {bills.map((bill) => (
                <Card
                  key={bill.invoice_id}
                  sx={{
                    mb: 2,
                    padding: 2,
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Invoice ID: {bill.avenue_created_invoice_id}
                  </Typography>
                  <Typography variant="body2">
                    Status: {bill.bill_status}
                  </Typography>
                </Card>
              ))}
            </CardContent>
          </Card>
        </Grid>

        {/* ✅ PIE CHART */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              borderRadius: "12px",
              textAlign: "center",
              background: "white",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", color: "#2A3663" }}
              >
                Invoice Status Analysis
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  height: "350px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%", maxWidth: "300px", height: "100%" }}>
                  <Pie data={chartData} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}








// // This is working code but loading time is more
// import React, { useState, useEffect, useMemo } from "react";
// import {
//   Box,
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   CardMedia,
//   Divider,
// } from "@mui/material";
// import { Pie } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// ChartJS.register(ArcElement, Tooltip, Legend);

// export default function DashBoardPage({ setFormType }) {
//   /** ✅ STATE MANAGEMENT */
//   const [bills, setBills] = useState([]); // Stores latest "Ready for Review" invoices
//   const [statusCounts, setStatusCounts] = useState({}); // Stores invoice counts for Pie Chart
//   const [roles, setRoles] = useState([]); // Stores user roles from Permit.io

//   /** ✅ FETCH USER ROLES FROM PERMIT.IO */
//   const fetchRoles = async () => {
//     try {
//       const email = localStorage.getItem("email");
//       if (!email) {
//         console.error("User email not found in localStorage.");
//         return;
//       }

//       const response = await fetch(
//         `https://test.datso.io/roles?email=${email}`
//       );
//       if (!response.ok) {
//         throw new Error(`Failed to fetch roles: ${response.statusText}`);
//       }

//       const data = await response.json();
//       localStorage.setItem("roles", JSON.stringify(data.role));
//       setRoles(data.role);
//     } catch (error) {
//       console.error("Error fetching roles:", error);
//     }
//   };

//   /** ✅ FETCH LATEST READY-FOR-REVIEW INVOICES */
//   const fetchBills = async () => {
//     try {
//       const response = await fetch(
//         `https://test.datso.io/latest-ready-for-review-invoices`
//       );
//       if (!response.ok) throw new Error("Failed to fetch invoices");
//       const result = await response.json();
//       setBills(result.latest_ready_for_review_invoices || []);
//     } catch (err) {
//       console.error("Error fetching bills:", err);
//       setBills([]);
//     }
//   };

//   /** ✅ FETCH INVOICE STATUS COUNTS FOR PIE CHART */
//   const fetchInvoiceStatusCounts = async () => {
//     try {
//       const response = await fetch(
//         `https://test.datso.io/invoice-status-count`
//       );
//       if (!response.ok) throw new Error("Failed to fetch status counts");
//       const result = await response.json();
//       setStatusCounts(result.bill_status_counts || {});
//     } catch (err) {
//       console.error("Error fetching status counts:", err);
//     }
//   };

//   /** ✅ FETCH DATA ON PAGE LOAD */
//   useEffect(() => {
//     fetchRoles();
//     fetchBills();
//     fetchInvoiceStatusCounts();
//   }, []);

//   /** ✅ MAP STATUS COLORS TO PIE CHART */
//   const statusColors = {
//     READY_FOR_REVIEW: "#FFCC99", // Orange
//     REVIEWED: "#CCCCFF", // Blue
//     PROCESSED: "#CCFFCC", // Green
//     REJECTED: "#FFAD99", // Red
//   };

//   /** ✅ PIE CHART CONFIGURATION */
//   const chartData = useMemo(() => {
//     const labels = Object.keys(statusCounts);
//     const data = Object.values(statusCounts);

//     return {
//       labels,
//       datasets: [
//         {
//           data,
//           backgroundColor: labels.map(
//             (status) => statusColors[status] || "#D3D3D3"
//           ),
//           hoverBackgroundColor: labels.map(
//             (status) => statusColors[status] || "#A9A9A9"
//           ),
//           borderWidth: 1,
//         },
//       ],
//     };
//   }, [statusCounts]);

//   /** ✅ ROLE-BASED CARDS LOGIC */
//   const tabPermissions = {
//     admin: ["properties", "invoices", "inventory", "onboarding", "manpower"],
//     Stock_Management_Team: ["inventory", "invoices"],
//     Finance_Team: ["onboarding", "invoices"],
//     Backend_Team: ["properties", "invoices"],
//   };

//   const allCards = {
//     properties: {
//       label: "Projects",
//       img: "building.png",
//       color: "#FFEEF3",
//       action: () => setFormType("Create New Land Deal"),
//     },
//     invoices: {
//       label: "Invoices",
//       img: "invoice.png",
//       color: "#E6F7FF",
//       action: () => setFormType("View Bills"),
//     },
//     inventory: {
//       label: "Inventory",
//       img: "inventory.png",
//       color: "#E6FFF2",
//       action: () => setFormType("View Inventory"),
//     },
//     onboarding: {
//       label: "OnBoarding",
//       img: "onboarding.png",
//       color: "#FFF7E6",
//       action: () => setFormType("View OnBoarding"),
//     },
//     manpower: {
//       label: "ManPower",
//       img: "manpower.png",
//       color: "#FFF7E6",
//       action: () => setFormType("View ManPower"),
//     },
//   };

//   const userCards = useMemo(() => {
//     const allowedCards = new Set();
//     roles.forEach((role) => {
//       const roleTabs = tabPermissions[role] || [];
//       roleTabs.forEach((tab) => allowedCards.add(allCards[tab]));
//     });
//     return Array.from(allowedCards);
//   }, [roles]);

//   return (
//     <Box
//       sx={{
//         flexGrow: 1,
//         margin: "1%",
//         borderRadius: "15px",
//         backgroundColor: "#F4F6F9",
//         padding: 4,
//       }}
//     >
//       {/* ✅ ROLE-BASED CARDS SECTION */}
//       <Grid container spacing={0} justifyContent="center" mb={5}>
//         {userCards.map((card, index) => (
//           <Grid item xs={12} sm={6} md={2} key={index}>
//             <Card
//               onClick={card.action}
//               sx={{
//                 cursor: "pointer",
//                 width:"200px",
//                 borderRadius: "12px",
//                 background: "white",
//                 transition: "transform 0.2s",
//                 "&:hover": { transform: "scale(1.05)" },
//               }}
//             >
//               <CardContent sx={{ textAlign: "center" }}>
//                 <CardMedia
//                   component="img"
//                   image={`https://avenuenxt.s3.ap-southeast-1.amazonaws.com/${card.img}`}
//                   sx={{
//                     height: "70px",
//                     width: "70px",
//                     margin: "auto",
//                     objectFit: "contain",
//                   }}
//                 />
//                 <Typography
//                   variant="h6"
//                   sx={{ mt: 2, fontWeight: "bold", color: "#2A3663" }}
//                 >
//                   {card.label}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       {/* ✅ INVOICE LIST */}
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={6}>
//           <Card sx={{ borderRadius: "12px", background: "white" }}>
//             <CardContent>
//               <Typography
//                 variant="h6"
//                 sx={{ mb: 2, fontWeight: "bold", color: "#2A3663" }}
//               >
//                 Bills List
//               </Typography>
//               <Divider sx={{ mb: 2 }} />
//               {bills.map((bill) => (
//                 <Card
//                   key={bill.invoice_id}
//                   sx={{
//                     mb: 2,
//                     padding: 2,
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <Typography variant="body1" sx={{ fontWeight: "bold" }}>
//                     Invoice ID: {bill.avenue_created_invoice_id}
//                   </Typography>
//                   <Typography variant="body2">
//                     Status: {bill.bill_status}
//                   </Typography>
//                 </Card>
//               ))}
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* ✅ PIE CHART */}
//         <Grid item xs={12} md={6}>
//           <Card
//             sx={{
//               borderRadius: "12px",
//               textAlign: "center",
//               background: "white",
//             }}
//           >
//             <CardContent>
//               <Typography
//                 variant="h6"
//                 sx={{ mb: 2, fontWeight: "bold", color: "#2A3663" }}
//               >
//                 Invoice Status Analysis
//               </Typography>
//               <Divider sx={{ mb: 2 }} />
//               <Box
//                 sx={{
//                   height: "350px",
//                   display: "flex",
//                   justifyContent: "center",
//                 }}
//               >
//                 <Box sx={{ width: "100%", maxWidth: "300px", height: "100%" }}>
//                   <Pie data={chartData} />
//                 </Box>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

//=====================================================================================================================================================================================================

// // This has only readyforreview page redirection...
// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import {
//   Box,
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   CardMedia,
//   Divider,
// } from '@mui/material';
// import { Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import ReadyForReview from './ReadyForReviewForm'; // ✅ Import the form component

// ChartJS.register(ArcElement, Tooltip, Legend);

// export default function DashBoardPage({ setFormType }) {
//   /** ✅ STATE MANAGEMENT */
//   const [bills, setBills] = useState([]); // Stores oldest "Ready for Review" invoices
//   const [statusCounts, setStatusCounts] = useState({}); // Stores invoice counts for Pie Chart
//   const [selectedInvoice, setSelectedInvoice] = useState(null); // Stores selected invoice to show form

//   /** ✅ FETCH OLDEST READY-FOR-REVIEW INVOICES */
//   const fetchBills = async () => {
//     try {
//       const response = await fetch(`https://test.datso.io/latest-ready-for-review-invoices`);
//       if (!response.ok) throw new Error('Failed to fetch invoices');
//       const result = await response.json();
//       setBills(result.latest_ready_for_review_invoices || []);
//     } catch (err) {
//       console.error('Error fetching bills:', err);
//       setBills([]);
//     }
//   };

//   /** ✅ FETCH INVOICE STATUS COUNTS FOR PIE CHART */
//   const fetchInvoiceStatusCounts = async () => {
//     try {
//       const response = await fetch(`https://test.datso.io/invoice-status-count`);
//       if (!response.ok) throw new Error('Failed to fetch status counts');
//       const result = await response.json();
//       setStatusCounts(result.bill_status_counts || {});
//     } catch (err) {
//       console.error('Error fetching status counts:', err);
//     }
//   };

//   /** ✅ FETCH DATA ON PAGE LOAD */
//   useEffect(() => {
//     fetchBills();
//     fetchInvoiceStatusCounts();
//   }, []);

//   /** ✅ PIE CHART CONFIGURATION */
//   const chartData = useMemo(
//     () => ({
//       labels: Object.keys(statusCounts),
//       datasets: [
//         {
//           data: Object.values(statusCounts),
//           backgroundColor: ['#FFCC99', '#CCCCFF', '#CCFFCC', '#FFAD99'],
//         },
//       ],
//     }),
//     [statusCounts]
//   );

//   /** ✅ FUNCTION TO OPEN THE INVOICE FORM */
//   const handleInvoiceClick = (invoice) => {
//     setSelectedInvoice(invoice);
//   };

//   /** ✅ FUNCTION TO CLOSE THE FORM */
//   const handleCloseForm = () => {
//     setSelectedInvoice(null);
//   };

//   return (
//     <Box sx={{ flexGrow: 1, margin: '2%', borderRadius: '15px', backgroundColor: '#F4F6F9', padding: 4 }}>
//       {/* ✅ INVOICE LIST */}
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={6}>
//           <Card sx={{ borderRadius: '12px', background: 'white' }}>
//             <CardContent>
//               <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#2A3663' }}>
//                 Oldest Ready for Review Invoices
//               </Typography>
//               <Divider sx={{ mb: 2 }} />
//               {bills.map((bill) => (
//                 <Card
//                   key={bill.invoice_id}
//                   sx={{ mb: 2, padding: 2, borderRadius: '8px', cursor: 'pointer' }}
//                   onClick={() => handleInvoiceClick(bill)} // ✅ Open invoice form inside page
//                 >
//                   <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
//                     Invoice ID: {bill.invoice_id}
//                   </Typography>
//                   <Typography variant="body2">Status: {bill.bill_status}</Typography>
//                 </Card>
//               ))}
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* ✅ PIE CHART */}
//         <Grid item xs={12} md={6}>
//           <Card sx={{ borderRadius: '12px', textAlign: 'center', background: 'white' }}>
//             <CardContent>
//               <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#2A3663' }}>
//                 Invoice Status Analysis
//               </Typography>
//               <Divider sx={{ mb: 2 }} />
//               <Box sx={{ height: '350px', display: 'flex', justifyContent: 'center' }}>
//                 <Box sx={{ width: '100%', maxWidth: '300px', height: '100%' }}>
//                   <Pie data={chartData} />
//                 </Box>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>

//       {/* ✅ READY FOR REVIEW FORM (Opens inside the page) */}
//       {selectedInvoice && (
//         <Box
//           sx={{
//             position: 'fixed',
//             top: '10%',
//             left: '50%',
//             transform: 'translate(-50%, 0)',
//             width: '80%',
//             height: '80%',
//             overflowY: 'auto',
//             backgroundColor: '#fff',
//             boxShadow: 3,
//             padding: 3,
//             zIndex: 1300,
//           }}
//         >
//           <ReadyForReview invoiceData={selectedInvoice} onClose={handleCloseForm} />
//         </Box>
//       )}
//     </Box>
//   );
// }