import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  DialogActions
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel"; // Reject Action
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // Raise Action
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Issue Action
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber"; // ✅ Warning Icon
import { FormControlLabel, Switch } from "@mui/material"; // ✅ Toggle switch components
import ChatUpdates from "./ChatUpdates"; // ✅ Import the ChatUpdates component

const InventoryRequestDialog = ({ open, handleClose, selectedRequest ,refreshRequests}) => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseStock, setWarehouseStock] = useState({});
  const [requestUpdates, setRequestUpdates] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [expandedDate, setExpandedDate] = useState(null);
  const [showChat, setShowChat] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessageDialog, setErrorMessageDialog] = useState("");



  console.log(selectedRequest)


  useEffect(() => {
    if (selectedRequest) {
      fetchInventoryData();
    }
  }, [selectedRequest]);

  const fetchInventoryData = async () => {
    setLoading(true);
    setError("");
    setRowData([]);
    
    try {
      const itemNameEncoded = encodeURIComponent(selectedRequest.item_name);
      const response = await axios.get(`https://test.datso.io/single/inventory/${itemNameEncoded}`);
    
      let inventoryData = response.data.inventory;
    
      // If no inventory is returned, create a default row for raising inventory
      if (!inventoryData || inventoryData.length === 0) {
        // Optional: set an error message if needed, or just create a default row.
        // setError(`No inventory data found for '${selectedRequest.item_name}'.`);
        // Instead of error, create a row that allows raising inventory directly:
        setRowData([
          {
            item_name: selectedRequest.item_name,
            available_quantity: 0,
            warehouse: "New Item, Not in WareHouse",
            editableRequestedAmount: selectedRequest.requested_quantity,
            status: "requested",
            action: "raise",
          },
        ]);
        // Also update warehouses state with the new default option.
        setWarehouses(["New Item, Not in WareHouse"]);
        setWarehouseStock({ "New Item, Not in WareHouse": 0 });
        return;
      }
    
      // ✅ Create a mapping of warehouse to available stock
      const warehouseStockMap = {};
      inventoryData.forEach(item => {
        warehouseStockMap[item.warehouse] = item.available_quantity;
      });
    
      // ✅ Store warehouses and their stock in state
      setWarehouses(Object.keys(warehouseStockMap));
      setWarehouseStock(warehouseStockMap);
    
      // ✅ Initialize rowData with the first warehouse
      setRowData([
        {
          item_name: selectedRequest.item_name,
          available_quantity: inventoryData[0]?.available_quantity || 0,
          warehouse: inventoryData[0]?.warehouse || "",
          editableRequestedAmount: selectedRequest.requested_quantity,
          status: "requested",
          action:
            selectedRequest.requested_quantity > (inventoryData[0]?.available_quantity || 0)
              ? "raise"
              : "issue",
        },
      ]);
    } catch (error) {
      // If an error occurs (for example, 404), you could also opt to set the row data
      // with the raise option instead of showing an error:
      setRowData([
        {
          item_name: selectedRequest.item_name,
          available_quantity: 0,
          warehouse: "New Item, Not in WareHouse",
          editableRequestedAmount: selectedRequest.requested_quantity,
          status: "requested",
          action: "raise",
        },
      ]);
      setWarehouses(["New Item, Not in WareHouse"]);
      setWarehouseStock({ "New Item, Not in WareHouse": 0 });
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  const fetchRequestUpdates = async () => {
    try {
      const itemNameEncoded = encodeURIComponent(selectedRequest.item_name);
      const response = await axios.get(`https://test.datso.io/request-item/${itemNameEncoded}`);
  
      if (response.data.requests) {
        setRequestUpdates(response.data.requests);
      } else {
        setRequestUpdates([]);
      }
    } catch (error) {
      console.error("Failed to fetch request updates:", error);
      setRequestUpdates([]);
    }
  };
  
  // Call this after fetching inventory
  useEffect(() => {
    if (selectedRequest) {
      fetchInventoryData();
      fetchRequestUpdates(); // ✅ Fetch updates
    }
  }, [selectedRequest]);
  
  
  const handleFieldChange = (index, field, value) => {
    setRowData((prevRows) => {
      const newRows = [...prevRows];
      const row = { ...newRows[index] };
  
      if (field === "warehouse") {
        row.warehouse = value;
        row.available_quantity = warehouseStock[value] || 0;
      } else if (field === "editableRequestedAmount") {
        row.editableRequestedAmount = Math.max(0, Number(value));
        // Removed condition check so the action remains as chosen by the user
      } else {
        row[field] = value;
      }
  
      newRows[index] = row;
      return newRows;
    });
  };
  
  
  
  
  
  const handleProcessAllRows = async () => {
    if (rowData.length === 0) {
      setErrorMessageDialog("No rows to process.");
      setErrorDialogOpen(true);
      return;
    }
  
    for (const row of rowData) {
      if (row.editableRequestedAmount <= 0) {
        setErrorMessageDialog("Quantity must be greater than zero.");
        setErrorDialogOpen(true);
        continue; // Skip this row and move to the next
      }
  
      let actionType = row.action === "request" ? "requested" : row.action;
      let apiUrl = "";
      let payload = {
        request_id: selectedRequest.request_id,
        engineer_id: selectedRequest.engineer_id ?? "UNKNOWN",
        invoice_id: selectedRequest.invoice_id ?? 0,
        item_name: selectedRequest.item_name ?? "Unknown Item",
        requested_quantity: selectedRequest.requested_quantity ?? 0,
        status: row.status ?? "requested",
        warehouse: row.warehouse,
        performed_by: "Stock Manager",
        project_name: selectedRequest.project_name ?? "Unknown Project",
        property_name: selectedRequest.property_name ?? "Unknown Property",
        p_req_id: selectedRequest.p_req_id ?? selectedRequest.request_id,
        deli_date: selectedRequest.deli_date ?? new Date().toISOString().slice(0, 19) + "Z",
      };
  
      switch (actionType) {
        case "issue":
          apiUrl = "https://test.datso.io/issue-stock";
          payload.issued_quantity = row.editableRequestedAmount;
          payload.status = "issued";
          break;
  
        case "raise":
          apiUrl = "https://test.datso.io/raise-stock";
          payload.requested_quantity = row.editableRequestedAmount;
          payload.status = "raised";
          break;
  
        case "reject":
          apiUrl = "https://test.datso.io/reject-stock";
          payload.rejected_quantity = row.editableRequestedAmount;
          payload.rejection_reason = "Not needed or insufficient stock";
          payload.status = "rejected";
          break;
  
        default:
          console.error("Invalid action type:", actionType);
          continue;
      }
  
      try {
        // ✅ Send Stock Update Request First
        const response = await axios.post(apiUrl, payload);
        console.log(`✅ API Response for ${row.item_name}:`, response.data);
  
        // ✅ Now Send WhatsApp Update **After Status Change**
        await axios.post(`https://test.datso.io/send-whatsapp-update/`, null, {
          params: { request_id: selectedRequest.request_id },
        });
  
        console.log(`📩 WhatsApp notification sent for ${row.item_name}`);
  
      } catch (error) {
        console.error(`❌ API Error for ${row.item_name}:`, JSON.stringify(error.response?.data, null, 2));
      }
    }
  
    setSuccessMessage("Processing completed for all rows.");
    setSuccessDialogOpen(true);
    await fetchInventoryData();
    await fetchRequestUpdates();
    refreshRequests();
    setTimeout(() => {
      setSuccessDialogOpen(false);
    }, 3000);
  };
  
  
  
  const handleActionChange = (index, value) => {
    setRowData((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], action: value };
      return newRows;
    });
  };
  
  
  

  const handleDeleteRow = (index) => {
    setRowData((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    setRowData((prevRows) => [
      ...prevRows,
      {
        item_name: selectedRequest.item_name,
        available_quantity: "",
        editableRequestedAmount: selectedRequest.requested_quantity,
        status: "requested",
        action: "issue", // default action
      },
    ]);
  };


const handleAction = async (index) => {
  const row = rowData[index];

  if (row.editableRequestedAmount <= 0) {
    alert("Quantity must be greater than zero.");
    return;
  }

  let apiUrl = "";
  let payload = {
    request_id: selectedRequest.request_id,
    item_name: row.item_name,
    warehouse: selectedRequest.warehouse,
    performed_by: "Stock Manager",
    engineer_id: selectedRequest.engineer_id ?? "UNKNOWN",  // ✅ Ensure engineer_id is passed
    invoice_id: selectedRequest.invoice_id ?? 0,  // ✅ Ensure invoice_id is passed
    project_name: selectedRequest.project_name ?? "Unknown Project",  // ✅ Ensure project_name is passed
    property_name: selectedRequest.property_name ?? "Unknown Property",  // ✅ Ensure property_name is passed
    p_req_id: selectedRequest.p_req_id ?? selectedRequest.request_id,  // ✅ Ensure p_req_id is passed
    deli_date: selectedRequest.deli_date ?? new Date().toISOString().slice(0, 19) + "Z",  // ✅ Ensure delivery date is passed
  };

  let actionType = row.action === "request" ? "requested" : row.action;

  switch (actionType) {
    case "issue":
      apiUrl = "https://test.datso.io/issue-stock";
      payload.issued_quantity = row.editableRequestedAmount;
      payload.status = "issued";  // ✅ Ensure status is passed
      payload.requested_quantity = selectedRequest.requested_quantity ?? 0;  // ✅ Ensure requested_quantity is passed
      break;

    case "raise":
      apiUrl = "https://test.datso.io/raise-stock";
      payload.requested_quantity = row.editableRequestedAmount;
      payload.status = "raised";
      break;

    case "reject":
      apiUrl = "https://test.datso.io/reject-stock";
      payload.rejected_quantity = row.editableRequestedAmount;
      payload.rejection_reason = "Not needed or insufficient stock";
      payload.status = "rejected";
      break;

    default:
      return;
  }

  // ✅ Debugging Log
  console.log("Action Type:", actionType);
  console.log("API URL:", apiUrl);
  console.log("Payload:", JSON.stringify(payload, null, 2));

  try {
    const response = await axios.post("apiUrl", payload);
    console.log("API Response:", response.data);
    setSuccessMessage(`${actionType.charAt(0).toUpperCase() + actionType.slice(1)} action successful`);
    setTimeout(() => {
          setSuccessDialogOpen(false);
          //handleClose();
        }, 3000);

    handleClose();
  } catch (error) {
    console.error("API Error:", JSON.stringify(error.response?.data, null, 2));
      setErrorMessageDialog(`Failed to ${actionType} stock.`);
      setErrorDialogOpen(true);
      setTimeout(() => {
        setErrorDialogOpen(false);
      }, 3000);
  }
};

const handleAccordionChange = (date) => {
    setExpandedDate((prev) => (prev === date ? null : date));
  };
// ✅ Helper function to group by date
const groupByDate = (requests) => {
    return requests
      .sort((a, b) => new Date(b.deli_date) - new Date(a.deli_date)) // ✅ Sort descending (newest first)
      .reduce((acc, request) => {
        const date = new Date(request.deli_date).toDateString(); // Format date
        if (!acc[date]) acc[date] = [];
        acc[date].push(request);
        return acc;
      }, {});
  };
  
  
  // ✅ Prepare grouped data
  const groupedRequests = groupByDate(
    requestUpdates.filter((update) => selectedStatus === "all" || update.status === selectedStatus)
  );
  const isFormValid = () => {
    return rowData.every(row => 
      row.warehouse && 
      row.editableRequestedAmount > 0 && 
      row.action
    );
  };
  
  
  

  return (
    <Dialog
  open={open}
  onClose={handleClose}
  maxWidth="100%" // Use "xl" to make the dialog larger
  fullWidth // Ensure it takes full width
  sx={{
    "& .MuiDialog-paper": {
      width: "100%", // Increase width to 90% of screen width
      height: "90vh", // Increase height to 85% of screen height
      maxWidth: "none", // Remove any restrictions on max width
      borderRadius: "12px", // Make it look modern
      
    },
  }}
>
<DialogTitle
  sx={{
    color: "Black",
    fontWeight: "bold",
    background: "White",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  }}
>
  Request Details

  {/* ✅ Wrap Buttons in a Container for Proper Alignment */}
  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
    <Button
      variant="contained"
      onClick={() => setShowChat(!showChat)}
      sx={{
        backgroundColor: "#2A3663",
        color: "white",
        "&:hover": { backgroundColor: "#1B1F3B" },
      }}
    >
      {showChat ? "Hide Updates" : "Show Updates"}
    </Button>

    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{ color: "Red", transition: "0.3s", '&:hover': { color: "#ff4d4d" } }}
    >
      <CloseIcon />
    </IconButton>
  </Box>
</DialogTitle>


      <DialogContent sx={{ padding: "24px", backgroundColor: "#f4f6f9" }}>
        {selectedRequest && (
          <>
            {/* ✅ Request Details (Before Table) */}
            <Box sx={{ marginBottom: 2, background: "#fff", padding: 2, borderRadius: 2, boxShadow: 1 }}>
              <Typography fontWeight="bold" color="#142850">Item: {selectedRequest.item_name}</Typography>
              <Typography fontWeight="bold" color="#142850">Warehouse: {selectedRequest.warehouse}</Typography>
              {/* <Typography fontWeight="bold" color="#142850">Project: {selectedRequest.project_name}</Typography> */}
              <Typography fontWeight="bold" color="#142850">Requested By: {selectedRequest.engineer_name}</Typography>

              <Typography fontWeight="bold" color="#142850">Property: {selectedRequest.property_name}</Typography>
              <Typography fontWeight="bold" color="#142850">Requested Quantity: {selectedRequest.requested_quantity}</Typography>

              <Typography fontWeight="bold" color="#142850"sx={{textTransform:"uppercase"}}>Status: {selectedRequest.status}</Typography>

            </Box>

            {/* ✅ Inventory Details Table */}
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2, color: "#142850" }}>
  Inventory -{" "}
  <Typography component="span" fontWeight="bold" color="#142850" sx={{ textTransform: "uppercase" }}>
    {selectedRequest.status}
  </Typography>
</Typography>

            {loading ? (
              <Typography>Loading inventory data...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : rowData.length > 0 ? (
              <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 2, maxHeight: 300 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: "#2A3663" }}>
                    <TableRow>
                      <TableCell sx={{ color: "#fff" }}><strong>Item Name</strong></TableCell>
                      <TableCell sx={{ color: "#fff" }}><strong>Stock Available</strong></TableCell>
                      <TableCell sx={{ color: "#fff" }}><strong>Warehouse</strong></TableCell>

                      <TableCell sx={{ color: "#fff" }}><strong>Requested Amount</strong></TableCell>
                      <TableCell sx={{ color: "#fff" }}><strong>Actions</strong></TableCell>
                      <TableCell sx={{ color: "#fff" }}><strong></strong></TableCell>
                      <TableCell sx={{ color: "#fff" }}><strong></strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                {rowData.map((row, index) => (
                    <TableRow key={index}>
                <TableCell>{row.item_name}</TableCell>

                {/* ✅ Available Stock Updates When Warehouse Changes */}
                <TableCell>{row.available_quantity}</TableCell>
                    <TableCell>
                <Select
                    value={row.warehouse || ""}
                    onChange={(e) => handleFieldChange(index, "warehouse", e.target.value)}
                    sx={{ minWidth: "150px" }}
                >
                    {warehouses.map((wh) => (
                    <MenuItem key={wh} value={wh}>
                        {wh}
                    </MenuItem>
                    ))}
                </Select>
                </TableCell>


                <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
  <TextField
    value={row.editableRequestedAmount}
    onChange={(e) => handleFieldChange(index, "editableRequestedAmount", e.target.value)}
    type="number"
    size="small"
    sx={{
      width: "80px",
      textAlign: "center",
      "& input": { textAlign: "center" }, // ✅ Align input text
    }}
  />
</TableCell>



<TableCell>
<Select
  value={row.action || ""}
  onChange={(e) => handleActionChange(index, e.target.value)}
  sx={{ minWidth: "120px" }}
>
  <MenuItem value="issue">Issue</MenuItem>
  <MenuItem value="raise">Raise</MenuItem>
  <MenuItem value="reject">Reject</MenuItem>
</Select>




</TableCell>


      <TableCell>
        {/* <IconButton onClick={() => handleAction(index)} color="success">
          <CheckCircleIcon />
        </IconButton> */}
        <IconButton onClick={() => handleDeleteRow(index)} color="error">
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

                </Table>
              </TableContainer>

              
            ) : (
              <Typography>No inventory data found.</Typography>
            )}

            <Box display="flex" justifyContent="flex-start" mt={2}>
            <Box display="flex" justifyContent="flex-start" mt={2}>
  <IconButton onClick={handleAddRow} color="primary">
    <AddIcon />
  </IconButton>
</Box>



            </Box>



            <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 1 }}>
            <Box display="flex" justifyContent="center" mt={2}>
            <Button
  variant="contained"
  color="success"
  startIcon={<CheckCircleIcon />}
  onClick={handleProcessAllRows}
  disabled={!isFormValid()} // 🔥 Disable button until all fields are valid
>
  Process
</Button>

</Box>

</Box>
 
{/* ✅ Chat Window Below the Table */}
{showChat && (
  <Box sx={{
    width: "100%", 
    marginTop: 2, 
    transition: "0.3s ease-in-out",
    display: "flex",
    justifyContent: "center",
  }}>
<ChatUpdates requestUpdates={requestUpdates} selectedRequest={selectedRequest} />
</Box>
)}


      



 {/* ✅ Snackbar Notification */}
 <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* ✅ Success Dialog */}
      <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
        <DialogTitle>Processing Completed</DialogTitle>
        <DialogContent>
          <Typography>{successMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


          </>
          
        )}
      </DialogContent>


      {/* Error Dialog */}
<Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
  <DialogTitle>Error</DialogTitle>
  <DialogContent>
    <Typography>{errorMessageDialog}</Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setErrorDialogOpen(false)} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>

    </Dialog>
  );
};

export default InventoryRequestDialog;
