import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";

const AddInventoryModal = ({ materialDetails, onClose, invoiceData }) => {
  const [selectedItems, setSelectedItems] = useState(
    materialDetails.map((item) => ({
      ...item,
      isSelected: true, // By default, all items are selected
      receivedQuantity: item.quantity, // Default to full quantity
      location: "", // New field for location
      warehouse: "", // New field for warehouse
    }))
  );
  const userEmail = localStorage.getItem("email");
  // Dialog State
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success"); // success | error | warning

  const WarningTitle = styled(DialogTitle)(({ theme }) => ({
    color: "brown", // Set title color to brown
    fontWeight: "bold",
  }));

  const WarningContent = styled(DialogContent)(({ theme }) => ({
    color: "brown", // Set message color to brown
  }));

  const handleSubmit = async () => {
    const itemsToAdd = selectedItems.filter((item) => item.isSelected);

    // Validate inputs
    if (itemsToAdd.some((item) => !item.location || !item.warehouse || !item.receivedQuantity)) {
      showDialog("Please fill in all fields (location, warehouse, received quantity) for selected items.", "error");
      return;
    }

    // Prepare payload for the backend
    const payload = {
      items: itemsToAdd.map((item) => ({
        item_name: item.description,
        count_change: Number(item.receivedQuantity),
        location: item.location,
        warehouse: item.warehouse,
        avenue_created_invoice_id: invoiceData.avenue_created_invoice_id,
        email: userEmail,
      })),
    };

    //console.log("Payload before sending:", JSON.stringify(payload, null, 2));

    try {
      const response = await fetch("https://test.datso.io/add-inventory", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log("API Response:", result);

      if (!response.ok) {
        showDialog(`Failed to update items. Error: ${result.message || response.statusText}`, "error");
        return;
      }

      const warnings = result.details.filter((msg) => msg.includes("not found"));
      const successMessages = result.details.filter((msg) => msg.includes("updated successfully"));

      if (warnings.length > 0) {
        showDialog(
          `Warning: Some items were not found in the inventory:\n${warnings.join("\n")}`,
          "warning"
        );
      } else if (successMessages.length > 0) {
        showDialog("Successfully updated selected items.", "success");
      }
    } catch (error) {
      console.error("Error updating inventory:", error);
      showDialog(`Failed to update inventory. Error: ${error.message}`, "error");
    }
  };

  const handleCheckboxChange = (index) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleQuantityChange = (index, value) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, receivedQuantity: value } : item
      )
    );
  };

  const handleLocationChange = (index, value) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, location: value } : item
      )
    );
  };

  const handleWarehouseChange = (index, value) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, warehouse: value } : item
      )
    );
  };

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity); // success | error | warning
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (dialogSeverity !== "warning") {
      onClose();
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "20px", fontWeight: "bold" }}>
        Add to Inventory
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Item Details</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Received Quantity</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Warehouse</TableCell>
              <TableCell>Rate Per Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={item.isSelected}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    value={item.receivedQuantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="Location"
                    value={item.location}
                    onChange={(e) => handleLocationChange(index, e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="Warehouse"
                    value={item.warehouse}
                    onChange={(e) => handleWarehouseChange(index, e.target.value)}
                  />
                </TableCell>
                <TableCell>{item.unit_price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button onClick={onClose} sx={{ marginRight: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add to Inventory
        </Button>
      </Box>

      {/* Dialog to show alert message */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        {dialogSeverity === "warning" ? (
          <>
            <WarningTitle>Warning</WarningTitle>
            <WarningContent>{dialogMessage}</WarningContent>
          </>
        ) : (
          <>
            <DialogTitle>{dialogSeverity === "success" ? "Success" : "Error"}</DialogTitle>
            <DialogContent>{dialogMessage}</DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddInventoryModal;
